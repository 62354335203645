import React, { useContext, useState, useEffect } from 'react'; 
import { GlobalContext } from "../../../global-context";
import { makeStyles, Paper, Grid, Typography, Icon, InputAdornment, Tooltip, TextField  } from '@material-ui/core';

import Loading from '../../utils/Loading';

const capFirst = (str) => { if (str) return str.charAt(0).toUpperCase() + str.slice(1); }
const log = false;

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2)
    },
    lane: {
      width: '100%',
    },
    section: {
      position: 'relative',
      width: '100%',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    descriptionTxt1: {
      marginBottom: theme.spacing(0.25),
      lineHeight: 1,
      fontSize: '21px',
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
      },
    },
    descriptionTxt2: {
      lineHeight: 1.2,
      fontSize: '18px',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },
    headerTxtL: {
      verticalAlign: 'top',
      lineHeight: 1,
      fontSize: '20px',
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },
    headerTxtR: {
      verticalAlign: 'top',
      textAlign: 'right',
      lineHeight: 1,
      fontSize: '18px',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },
    subheaderTxt: {
      verticalAlign: 'top',
      lineHeight: 1,
      fontSize: '16px',
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: '13px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
    },
    flex: {
      display: 'flex',
      flexWrap: 'nowrap',
      verticalAlign: 'top',
      width: '100%',
    },
    flexLine: {
      width: '100%',
      height: '1px',
      marginTop: '5.5px',
      marginBottom: '5.5px',
      background: '#eee',
    },
    flexTxtL: {
      verticalAlign: 'top',
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary,
      lineHeight: 1,
      fontSize: '14px',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: '11px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
      },
    },
    flexTxtR: {
      verticalAlign: 'top',
      marginLeft: theme.spacing(1),
      lineHeight: 1,
      fontSize: '14px',
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: '11px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
      },
    },
    smallBreak: {
      width: '100%',
      height: theme.spacing(1),
    },
    break: {
      width: '100%',
      height: theme.spacing(2),
    },
    bigBreak: {
      width: '100%',
      height: theme.spacing(3),
    },
    loading: {
      marginTop: '20rem',
      // marginBottom: '-20rem'
    },
    lineBreak: {
      width: '100%',
      height: '1px',
      background: '#ddd',
    },
    noWrap: {
      textAlign: 'center'
    },  
    noResults: {
      color: theme.palette.text.secondary,
      marginTop: '10px'
    }
  }));

 
export default function CalculatorOutput(props) {
    const ctx = useContext(GlobalContext);
    const cls = useStyles();

    const [ loading, setLoading ] = useState(true);

    const [ payRates, setPayRates ] = useState(["0.00", "0.00"]);
    const [ duration, setDuration ] = useState(0);
    const [ commission, setCommission ] = useState(0);
    const [ milageBand, setMilageBand ] = useState("");

    const [ totalBasePay, setTotalBasePay ] = useState(0);
    const [ totalBasePrice, setTotalBasePrice ] = useState(0)
    const [ totalBaseRate, setTotalBaseRate ] = useState(0);
    const [ baseCost, setBaseCost ] = useState(0);
    const [ totalBaseCost, setTotalBaseCost ] = useState(0);
    const [ totalBaseProfit, setTotalBaseProfit ] = useState(0);

    const [ totalStrandedPay, setTotalStrandedPay ] = useState(0);
    const [ totalStrandedPrice, setTotalStrandedPrice ] = useState(0);
    const [ totalStrandedRate, setTotalStrandedRate ] = useState (0);
    const [ strandedCost, setStrandedCost ] = useState(0);
    const [ totalStrandedCost, setTotalStrandedCost ] = useState(0);
    const [ totalStrandedProfit, setTotalStrandedProfit ] = useState(0);
    
    const { lane, customer, pickup, delivery, rateRules, payRateGroups } = props;

    useEffect(() => {
      let timer = setTimeout(() => setLoading(false), 900);

      if (lane) {
        setDuration(Number(lane.duration_sec) / 60 || (0));
        setCommission(Number((1.55 + (0.024 * Number(lane.dealer_base_price)))) || (0))
        setTotalBasePay(Number(lane.driver_base_pay) - Number(lane.driver_base_pay_discount) || (0));
        setTotalStrandedPay(Number((((Number(lane.driver_base_pay) - Number(lane.driver_base_pay_discount)) + Number(lane.driver_return_pay)) - Number(lane.driver_return_pay_discount))) || (0));  
        setTotalBasePrice(Number(lane.dealer_base_price) - Number(lane.dealer_base_discount) + Number(lane.tolls) || (0));
        setTotalStrandedPrice(Number(lane.dealer_stranded_price) - Number(lane.dealer_stranded_discount) + Number(lane.tolls) || (0));
        setTotalBaseRate(Number(Number(lane.dealer_base_rate) - Number(lane.dealer_base_discount)) || (0));
        setTotalStrandedRate(Number((Number(lane.dealer_stranded_rate) - Number(lane.dealer_stranded_discount))) || (0));
        setMilageBand(getBand(Number(lane.distance_miles)));
        setPayRates(getPayRates(Number(lane.distance_miles)));
        setBaseCost(Number(totalBasePay) + Number(lane.insurance_cost) + Number(commission));
        setStrandedCost(Number(totalStrandedPay) + Number(lane.insurance_cost) + Number(commission) + Number(lane.estimated_rideshare_return_cost));
        setTotalBaseCost(Number(baseCost + Number(lane.dealer_base_discount) + Number(lane.tolls)));
        setTotalStrandedCost(Number(strandedCost + Number(lane.dealer_stranded_discount) + Number(lane.tolls)));
        setTotalBaseProfit(() => {
          if (lane.dealer_base_rate_type === `flat`) return (Number(lane.dealer_base_price) - baseCost - Number(lane.dealer_base_discount) - Number(lane.tolls));
          else if (lane.dealer_base_rate_type === `per`) return (Number(lane.dealer_base_rate) * Number(lane.distance_miles) - baseCost - Number(lane.dealer_base_discount) - Number(lane.tolls));
          else return 0;
        });
        setTotalStrandedProfit(() => {
          if (lane.dealer_stranded_rate_type === `flat`) return (Number(lane.dealer_stranded_price) - strandedCost - Number(lane.dealer_stranded_discount) - Number(lane.tolls));
          else if (lane.dealer_stranded_rate_type === `per`) return (Number(lane.dealer_stranded_rate) * Number(lane.distance_miles) - strandedCost - Number(lane.dealer_stranded_discount) - Number(lane.tolls));
          else return 0;
        });
      }

      return () => {
        clearTimeout(timer);
        setLoading(true);
      }
    }, [props]);
  
    const getBand = (laneDistance) => {
        const currentRule = rateRules.find(rule => rule.distance_start < Number(lane.distance_miles) && Number(lane.distance_miles) < rule.distance_end);
        if (currentRule) return `${currentRule.distance_start}-\u2060${currentRule.distance_end}\xa0Miles`;
        else return `Unknown\xa0Band`;
    }

    const getPayRates = (laneDistance) => {
      var group = null;
      let customerGroup = rateRules.find(group => group.customer_id === customer);
      //Get customer group if exists
      if (customerGroup) {
        log && console.log(`customergroup`)
        group = customerGroup
      } else {
        var regionGroup = rateRules.find(group => group.region_id === pickup.region_id)
        //Get region group if exists
        if (regionGroup) {
          log && console.log(`regiongroup`)
          group = regionGroup
        } else {
          var defaultGroup = rateRules.find(group => group.name === `Company Defaults`)
          //Set pay group as default if no more specific pay group exists
          if (defaultGroup) {
            log && console.log(`defaultgroup`)
            group = defaultGroup
          }
        }
      }
      //Find the specific payraterules that match the lane
      if (group && payRateGroups) {
        log && console.log(`PAYRATE GROUP:`, group)
        const currentRule = payRateGroups.find(rule => rule.payraterules);
  
        if (currentRule) {
          const payRates = currentRule.payraterules;
          const rateRules = payRates.find(rule => rule.distance_start < Number(lane.distance_miles) && Number(lane.distance_miles) < rule.distance_end);
  
          if (rateRules) {
            log && console.log(`Rate Rules: $${Number(rateRules.per_minute_rate)}`, `$${Number(rateRules.per_mile_rate)}`);
            return [`${Number(rateRules.per_minute_rate).toFixed(2)}`, `${Number(rateRules.per_mile_rate).toFixed(2)}`];
          } else {
            return [`0.00`, `0.00`];
        }
  
        } else {
            return [`0.00`, `0.00`];
        }
      } else { return [`0.00`, `0.00`] }
    }


    if (loading) {
      return <div className={cls.loading}><Loading/></div>
    } else if (lane) {
      return(
          <div className={cls.container}>
            {/* Route Info Section */}
            <div className={cls.section}>
                <Grid container spacing={2}>
  
                  <Grid item xs={6}>
                    <Typography className={cls.headerTxtL}>Route Information</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip placement="top" title="Time / Distance">
                      <Typography className={cls.headerTxtR}>{`${ duration.toFixed(1) }\xa0min / ${ Number(lane.distance_miles).toFixed(1) || (0)}\xa0mi`}</Typography>
                    </Tooltip>
                  </Grid>
  
                  <div className={cls.smallBreak} />
  
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Total&nbsp;Time</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>{ duration.toFixed(1) }&nbsp;min</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Drive&nbsp;Time</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>{ duration.toFixed(1) }&nbsp;min</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Distance</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>{ Number(lane.distance_miles).toFixed(1) }&nbsp;mi</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Average&nbsp;Speed</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>{ Number(lane.average_drive_speed_mph).toFixed(1) || (0) }&nbsp;mph</Typography>
                    </div>
                  </Grid>
  
                </Grid>
              </div>
  
              <div className={cls.lineBreak} />
  
              {/* Driver Pay Section */}
              <div className={cls.section}>
                <Grid container spacing={2}>
  
                  <Grid item xs={6}>
                    <Typography className={cls.headerTxtL}>Driver Pay</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip placement="top" title="Total Base Pay / Total Stranded Pay">
                      <Typography className={cls.headerTxtR}>{`$${totalBasePay.toFixed(2)} / $${totalStrandedPay.toFixed(2)}`}</Typography>
                    </Tooltip>
                  </Grid>
  
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Drive&nbsp;Discount</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>$&nbsp;{ Number(lane.driver_base_pay_discount).toFixed(2) || (0) }</Typography>
                    </div>
                  </Grid> 
  
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Return&nbsp;Discount</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>$&nbsp;{ Number(lane.driver_return_pay_discount).toFixed(2) || (0) }</Typography>
                    </div>
                  </Grid> 
  
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Time&nbsp;Pay</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>$&nbsp;{ Number(lane.driver_time_pay).toFixed(2) || (0) }</Typography>
                    </div>
                  </Grid> 
  
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Standard&nbsp;Pay/Min</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>$&nbsp;{ payRates[0] }</Typography>
                    </div>
                  </Grid> 
  
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Standard&nbsp;Pay/Mile</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>$&nbsp;{ payRates[1] }</Typography>
                    </div>
                  </Grid> 
  
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Distance&nbsp;Pay</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>$&nbsp;{ Number(lane.driver_drive_pay).toFixed(2) || (0) }</Typography>
                    </div>
                  </Grid> 
  
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Return&nbsp;Pay</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>$&nbsp;{ Number(lane.driver_return_pay).toFixed(2) || (0) }</Typography>
                    </div>
                  </Grid> 
  
                  <div className={cls.smallBreak} />
  
                  <Grid item xs={12}>
                    <Typography className={cls.subheaderTxt}>Totals</Typography>
                  </Grid>
  
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Total&nbsp;Base&nbsp;Pay</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ totalBasePay.toFixed(2) }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Total&nbsp;Stranded&nbsp;Pay</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ totalStrandedPay.toFixed(2) }</Typography>
                    </div>
                  </Grid>
  
                </Grid>
              </div>
  
              <div className={cls.lineBreak} />
  
              {/* Customer Pay Section */}
              <div className={cls.section}>
  
                <Grid container spacing={2}>
  
                  <Grid item xs={6}>
                    <Typography className={cls.headerTxtL}>Customer Price</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip placement="top" title="Total Base Price / Total Stranded Price">
                      <Typography className={cls.headerTxtR}>{`$${totalBasePrice.toFixed(2)} / $${totalStrandedPrice.toFixed(2)}`}</Typography>
                    </Tooltip>
                  </Grid>
  
                  <div className={cls.smallBreak} />
  
                  {/* Base Rates */}
                  <Grid item xs={12}>
                    <Typography className={cls.subheaderTxt}>Base</Typography>
                  </Grid>
  
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Rate&nbsp;Type</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>{ capFirst(lane.dealer_base_rate_type) || "N/A" }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Milage&nbsp;Band</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>{ milageBand }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Total&nbsp;Base&nbsp;Rate</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ totalBaseRate.toFixed(2) }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Base&nbsp;Discount</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ Number(lane.dealer_base_discount).toFixed(2) || (0) }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Total&nbsp;Base&nbsp;Price</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ totalBasePrice.toFixed(2) }</Typography>
                    </div>
                  </Grid>
  
                  <div className={cls.smallBreak} />
  
                  {/* Stranded Rates */}
                  <Grid item xs={12}>
                    <Typography className={cls.subheaderTxt}>Stranded</Typography>
                  </Grid>
  
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Rate&nbsp;Type</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>{ capFirst(lane.dealer_stranded_rate_type) || "N/A" }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Milage&nbsp;Band</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>{milageBand}</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Total&nbsp;Stranded&nbsp;Rate</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ totalStrandedRate.toFixed(2) }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Stranded&nbsp;Discount</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ Number(lane.dealer_stranded_discount).toFixed(2) || (0) }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Total&nbsp;Stranded&nbsp;Price</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${totalStrandedPrice.toFixed(2) }</Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
  
              <div className={cls.lineBreak} />
  
              {/* Profit/Cost Section */}
              <div className= { cls.section }>
                <Grid container spacing={ 2 }>
                  <Grid item xs={6}>
                    <Typography className={cls.headerTxtL}>Profit & Cost</Typography>
                  </Grid>
                  <Grid item xs= { 6 }>
                    <Tooltip placement="top" title="Total Base Profit / Total Stranded Profit">
                      <Typography className={ cls.headerTxtR }>{`$${ totalBaseProfit.toFixed(2)  } / $${ totalStrandedProfit.toFixed(2)  }`}</Typography>
                    </Tooltip>
                  </Grid>
                  
                  <div className={cls.smallBreak} />
  
                  <Grid item xs={12}>
                    <Typography className={cls.subheaderTxt}>Factors</Typography>
                  </Grid>
  
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Insurance&nbsp;Cost/Mile</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ Number(lane.insurance_cost_per_mile).toFixed(2) || (0) }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Total&nbsp;Insurance&nbsp;Cost</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ Number(lane.insurance_cost).toFixed(2) || (0) }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Commission</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ commission.toFixed(2)  }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Return&nbsp;Ride&nbsp;Estimate</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ Number(lane.estimated_rideshare_return_cost).toFixed(2) || (0)  }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Total&nbsp;Base&nbsp;Cost</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ totalBaseCost.toFixed(2) }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Total&nbsp;Stranded&nbsp;Cost</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ totalStrandedCost.toFixed(2) }</Typography>
                    </div>
                  </Grid>
  
                  <div className={cls.smallBreak} />
  
                  <Grid item xs={12}>
                    <Typography className={cls.subheaderTxt}>Totals</Typography>
                  </Grid>
                  
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Total&nbsp;Base&nbsp;Profit</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ totalBaseProfit.toFixed(2)  }</Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.flex}>
                      <Typography className={cls.flexTxtL}>Total&nbsp;Stranded&nbsp;Profit</Typography>
                      <div className={cls.flexLine} />
                      <Typography className={cls.flexTxtR}>${ totalStrandedProfit.toFixed(2)  }</Typography>
                    </div>
                  </Grid>
  
                </Grid>
              </div>
          </div>
    )} else {
      return(
        <Grid item md={12} xs={12}>
          <div className={cls.noWrap}>
              <Typography className={cls.noResults}>unable to calculate move - try modifying your search</Typography>
          </div>
        </Grid>
      )
    }
};