import React, { Component } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { GlobalContext } from "../../global-context";
import Timeline from './plans/Timeline';
import moment from 'moment'
import fragments from "../utils/graphQL/fragments";

const log = false

const styles = theme => ({
  offset: {
    paddingTop: theme.spacing(7),
    paddingLeft: theme.spacing(3),
  },
})

const GET_PLANS_AND_UPMOVES = (type = 'query') => (
  gql`
  ${type} get_plans_moves_regions($start: date!, $end: date!, $selected_regions: [bigint!]) {
    plans(
      where: {
        plan_date: {
          _gte: $start,
          _lte: $end
        }
      },
      order_by: {
        createdat: asc,
        region_id: desc
      }
    ) {
      ...Plan
    }

  moves(
    order_by: {
          id: desc
        },
        where: {
          _and: {
            active: {
              _eq: 1
            },
            plan_id: {
              _is_null: true
            },
            lane_id: {
              _is_null: false
            },
            move_type: {
              _eq: "drive"
            },
            _or: [
              {
                cancel_status: {
                  _nin: ["canceled", "started", "delivered"]
                }
              },
              {
                cancel_status: {
                  _is_null: true
                }
              }
            ]
          }
        }
      ) {
        ...Move
      }

    regions(where: {id: {_in: $selected_regions}}) {
      ...Region
    }
    }
    ${fragments.plan}
    ${fragments.move}
    ${fragments.region}
  `
)


const GET_PLANS = (type = 'query') => (
  gql`
  ${type} get_plans($start: date!, $end: date!) {
    plans(
      where: {
        plan_date: {
          _gte: $start,
          _lte: $end
        }
      },
      order_by: {
        createdat: asc,
        region_id: desc
      }
      ) {
      ...Plan
      }
    }
    ${fragments.plan}
  `
)

const GET_REGIONS = (type = 'query') => (
  gql`
  ${type} get_regions($selected_regions: [bigint!]) {
    regions(where: {id: {_in: $selected_regions}}) {
      ...Region
      }
    }
    ${fragments.region}
  `
)


const GET_UNPLANNED_MOVES = (type = 'query') => (
  gql`
  ${type} get_unplanned_moves {
    moves(
      order_by: {
            customer_id: desc,
            id: desc
          },
          where: {
            _and: {
              active: {
                _eq: 1
              },
              plan_id: {
                _is_null: true
              },
              lane_id: {
                _is_null: false
              },
              move_type: {
                _eq: "drive"
              }
              _or: [
                {
                  cancel_status: {
                    _nin: ["canceled", "started", "delivered"]
                  }
                },
                {
                  cancel_status: {
                    _is_null: true
                  }
                }
              ]
            }
          }
    ) {
        ...Move
    }
  }
  ${fragments.move}
`)

class Plans extends Component {

  constructor(props) {
    super(props);
    this.state = {
      timelineDate: moment()
    }
  }

  componentWillMount = () => {
    let timelineDate = null
    const timelineDateSaveDate = moment(localStorage.getItem('timelineDateSaveDate') || moment())

    try {
      //Use the current date if the locally stored date is aged (greater than 12 hours difference from now)
      const timelineDateAgeHours = Math.abs(Number(moment.duration(moment().diff(timelineDateSaveDate)).asHours())).toFixed(1)
      if (log) console.log(`Timeline date in local storage is ${timelineDateAgeHours} hours old.`)
      if (timelineDateAgeHours < 12) {
        timelineDate = moment(localStorage.getItem('timelineDate') || moment())
        if (!timelineDate.isValid()) {
          timelineDate = moment()
        }
        if (log) console.log('Loading timeline date from local storage', timelineDate.format('LLL'))
      } else {
        timelineDate = moment()
        if (log) console.log('Loading timeline date using current date', timelineDate.format('LLL'))
      }
    } catch (error) {
      timelineDate = moment()
      if (log) console.log('Error loading timeline date using current date', timelineDate.format('LLL'))
    }

    this.setState({
      timelineDate: timelineDate
    })
  }

  changeTimelineDate = (date) => {
    if (log) console.log(`Changing timeline date to ${date.format('LLL')}`)
    this.setState({
      timelineDate: date
    })
    localStorage.setItem('timelineDate', date)
    localStorage.setItem('timelineDateSaveDate', moment())
  }

  render() {
    const { classes } = this.props;
    const selected_regions = this.context.userProfile["https://hasura.io/jwt/claims"] ? JSON.parse(this.context.userProfile["https://hasura.io/jwt/claims"]["x-hasura-selected-regions"].replace("{", "[").replace("}", "]")) : null;
    return (
      <div className={classes.offset}>
        <div style={{ position: 'relative' }}>
          { this.context.userProfile["https://hasura.io/jwt/claims"] && this.context.userIsAuthenticated() && (
            <Query query={GET_PLANS_AND_UPMOVES('query')} variables={{ start: this.state.timelineDate.format('YYYY-MM-DD'), end: this.state.timelineDate.format('YYYY-MM-DD'), selected_regions: selected_regions }}
              // onError={err => {console.error(err);this.context.handleNotifications(true, "error", "Query failed to retrieve plans data")}}
              >
              {({ subscribeToMore, ...result }) => {
                if (result.loading) return "Loading...";
                if (result.error) return `Error! ${result.error.message}`;

                return (<>
                  <div className={classes.offset} />
                  <Timeline
                    // Pass a subscribeToMore function to the child component as a prop that will be called on componentDidMount
                    //  This will initialize the subscription to keep the cache up to date with current data 
                    subscribeToMorePlans={() =>
                      subscribeToMore({
                        document: GET_PLANS('subscription'),
                        variables: { start: this.state.timelineDate.format('YYYY-MM-DD'), end: this.state.timelineDate.format('YYYY-MM-DD') },
                        updateQuery: (prev, { subscriptionData }) => {
                          if (log) console.log("subscribeToMorePlans:", subscriptionData, "prev:", prev);
                          if (!subscriptionData.data) return prev;
                          return { plans: subscriptionData.data.plans }
                        }
                      })
                    }
                    subscribeToMoreUnplannedMoves={() =>
                      subscribeToMore({
                        document: GET_UNPLANNED_MOVES('subscription'),
                        updateQuery: (prev, { subscriptionData }) => {
                          if (log) console.log("subscribeToMoreUnplannedMoves:", subscriptionData, "prev:", prev);
                          if (!subscriptionData.data) return prev;
                          return { moves: subscriptionData.data.moves }
                        }
                      })
                    }
                    subscribeToMoreRegions={() =>
                      subscribeToMore({
                        document: GET_REGIONS('subscription'),
                        variables: { selected_regions: selected_regions },
                        updateQuery: (prev, { subscriptionData }) => {
                          if (log) console.log("subscribeToMoreRegions:", subscriptionData, "prev:", prev);
                          if (!subscriptionData.data) return prev;
                          return { regions: subscriptionData.data.regions }
                        }
                      })
                    }
                    timelineDate={this.state.timelineDate}
                    changeTimelineDate={this.changeTimelineDate}
                    plans={result.data.plans}
                    unplannedMoves={result.data.moves}
                    regions={result.data.regions}
                    />
                </>);
              }}
            </Query>)
          }
        </div>
      </div>
    )
  }
}

Plans.contextType = GlobalContext;

Plans.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Plans);