// import helpers from "./helpers"
// import { withApollo } from 'react-apollo';
import gql from "graphql-tag";
import fragments from "./graphQL/fragments";

let log = false  //Enable for verbose console logging

const MoveHelper = {

    /**
     * Assign a move to a plan by ids for each
     * 
     * @param  {Object} client - The ApolloClient object
     * @param  {int} move_id - The id of the move to modify
     * @param  {int} plan_id - The id of the plan to assign the move to
     * @param  {int} sequence - The order of the moves on the plan
     * @return  {bool} - Boolean indicating success or failure
     */
    assignPlan: async function(client, move_id, plan_id, sequence) {
        const UPDATE_PLAN = gql`
        mutation assignPlan($move_id: bigint!, $plan_id: bigint!, $sequence: bigint!, $increment: bigint!) {
          updateMoves01: update_moves(
            where: {
              plan_id: {
                _eq: $plan_id
              }, 
              sequence: {
                _gte: $sequence
              }
            }, 
            _inc: {
              sequence: $increment
            },
            _set: {
              updatedat: "now()"
            }
          ) {
            returning {
              id
              sequence
            }
  				}
          updateMoves02: update_moves(
            where: {
              id: {
                _eq: $move_id
              }
            },
            _set: {
              plan_id: $plan_id,
              sequence: $sequence,
              updatedat: "now()"
            }
          ) {
            affected_rows
          }
        }
        `

        return await client.mutate({
          mutation: UPDATE_PLAN,
          variables: {
            move_id: move_id,
            plan_id: plan_id,
            sequence: sequence,
            increment: 1
          }
        })
    },
    insertMoves: async function(client, moves) {
      if (!Array.isArray(moves)) {
        if (log) console.error('MoveHelper.insertMoves moves param is not at array')
        return null
      }
      if (!moves.length > 0) {
        if (log) console.error('MoveHelper.insertMoves moves param array is empty')
        return null
      }
      //const cols = Object.keys(moves[0]).map(key => key);
      const UPDATE_TIMELINE_VALUES = gql`
      mutation insertMoves($moves: [moves_insert_input!]!) { 
        insert_moves (
          objects: $moves
        ) {
          affected_rows
          returning {
            ...Move
          }
        }
      }
      ${fragments.move}
      `
      const res = await client.mutate({
        mutation: UPDATE_TIMELINE_VALUES,
        variables: {
          moves: moves
        }
      });
      return res;
    },
    updateMultipleOld: async function(client, moves) {
      if (!Array.isArray(moves)) {
        if (log) console.error('MoveHelper.updateMultiple moves param is not at array')
        return null
      }
      if (!moves.length > 0) {
        if (log) console.error('MoveHelper.updateMultiple moves param array is empty')
        return null
      }
      const cols = Object.keys(moves[0]).map(key => key);
      const UPDATE_TIMELINE_VALUES = gql`
      mutation updateMultipleMoves($moves: [moves_insert_input!]!, $cols: [moves_update_column!]!) { 
        insert_moves (
          objects: $moves,
          on_conflict: {
            constraint: moves_pkey,
            update_columns: $cols
          }
        ) {
          affected_rows
          returning {
            ...Move
          }
        }
      }
      ${fragments.move}
      `
      return await client.mutate({
        mutation: UPDATE_TIMELINE_VALUES,
        variables: {
          moves: moves, 
          cols: cols
        }
      })
    },
    updateMultiple: async function(client, moves) {
      if (!Array.isArray(moves)) {
        if (log) console.error('MoveHelper.updateMultiple moves param is not at array')
        return null
      }
      if (!moves.length > 0) {
        if (log) console.error('MoveHelper.updateMultiple moves param array is empty')
        return null
      }
      let mutationQueries = []
      let mutationQueryParams = []
      let mutationVariables = {}
      for (let i = 0; i < moves.length; i++) {
        const move = moves[i]
        const mutationNum = i.toString().padStart(3, '0')
        mutationQueryParams.push(`${(i==0)?',':''} $idList_${mutationNum}: [bigint!]!, $change_${mutationNum}: moves_set_input!`)
        mutationQueries.push(`
            update_moves_${mutationNum}: update_moves(_set: $change_${mutationNum}, where: {id: {_in: $idList_${mutationNum}}}) {
              affected_rows
              returning {
                ...Move
              }
            }`)
        mutationVariables[`idList_${mutationNum}`] = [move.id]
        mutationVariables[`change_${mutationNum}`] = move
      }
      const queryStr = `
        mutation updateMovesByID(${mutationQueryParams.join(", ")}) {
          ${mutationQueries.join("\n")}
        }`

      if (log) console.log('MoveHelper.updateMultiple: Generated a multiple update mutation')
      if (log) console.log(queryStr)
      if (log) console.log(`${JSON.stringify(mutationVariables, null, 4)}`)

      const QUERY = gql`${queryStr}

      ${fragments.move}`

      const response = await client.mutate({
        mutation: QUERY,
        variables: mutationVariables
      })

      let mergedResponse = {
        data: {
          insert_moves: {
            affected_rows: 0,
            returning: []
          }
        }
      }

      Object.keys(response.data).map(queryResponseKey => {
        mergedResponse.data.insert_moves.affected_rows += response.data[queryResponseKey].affected_rows
        mergedResponse.data.insert_moves.returning.push(response.data[queryResponseKey].returning)
      })

      if (log) console.log(JSON.stringify(mergedResponse, null, 4))

      return mergedResponse
    },
    updateMovesByIDs: async function(client, idList, change) {
      if (Array.isArray(change)) {
        if (log) console.error('MoveHelper.updateSingle change param should not be an array')
        return null
      }
      if (!Array.isArray(idList)) {
        if (log) console.error('MoveHelper.updateSingle idList param must be an array of move ids to update')
        return null
      }
      const UPDATE_MOVES_BY_IDS = gql`
      mutation updateMovesByIDs($idList: [bigint!]!, $change: moves_set_input!) {
        update_moves(_set: $change, where: {id: {_in: $idList}}) {
          affected_rows
          returning {
            ...Move
          }
        }
      }
      ${fragments.move}
      `
      return await client.mutate({
        mutation: UPDATE_MOVES_BY_IDS,
        variables: {
          idList: idList, 
          change: change
        }
      })
    },
    incrementSequence: async function(client, plan_id, sequence, increment=1) {
      const INCREMENT_SEQUENCE = gql`
      mutation incrementSequence($plan_id: bigint!, $sequence: bigint!, $increment: bigint!) {
        update_moves(
          where: {
            plan_id: {
              _eq: $plan_id
            }, 
            sequence: {
              _gte: $sequence
            }
          }, 
          _inc: {
            sequence: $increment
          },
          _set: {
            updatedat: "now()"
          }
        ) {
          returning {
            id
            sequence
          }
        }
      }
      `
      return await client.mutate({
        mutation: INCREMENT_SEQUENCE,
        variables: {
          plan_id: plan_id, 
          sequence: sequence,
          increment: increment
        }
      })
    },
    unplanMove: async function(client, move) {
      const UNPLAN_MOVE = gql`
      mutation unplan_move($move_id: bigint!, $ready_by: timestamptz!) {
        unplanAndDeactivateChildRideMove: update_moves(
          where: {parent_move: {id: {_eq: $move_id}}, move_type: {_eq: "ride"}},
          _set: {
            active:0,
            plan_id:null,
            updatedat:"now()"
          }) {
          affected_rows
        }
        updateUnplannedDriveWhenChildIsRide: update_moves(
          where: {id: {_eq: $move_id}, _or: [{moveByReturnRideId: {move_type: {_eq: "ride"}}}, {parent_move: {move_type: {_eq: "ride"}}}]}, 
          _set: {
            return_ride_id: null, 
            lyft_flag: 0, 
            lyft_trigger_id: null, 
            updatedat: "now()"}
        ) {
          affected_rows
        }
        updateUnplannedMove: update_moves(
          where: {id: {_eq: $move_id}}, 
          _set: {
            status: null, 
            plan_id: null, 
            pickup_time: $ready_by, 
            delivery_time: null,  
            updatedat: "now()", 
            pinnable: false}
        ) {
          affected_rows
        }
      }
      `
      return await client.mutate({
        mutation: UNPLAN_MOVE,
        variables: {
          move_id: move.id,
          ready_by: move.ready_by
        }
      })
    },
    deleteReturnMove: async function(client, move_id) {
      const DELETE_RETURN_MOVE = gql`
      mutation deleteReturnMove($move_id: bigint!) {
        update1: update_moves(
          where: {
            id: {
              _eq: $move_id
            }
          },
          _set: {
            active: 0,
            updatedat: "now()"
          }
        ) {
          affected_rows
        }
        update2: update_moves(
          where: {
            return_ride_id: {
              _eq: $move_id
            }
          },
          _set: {
            lyft_flag: 0,
            return_ride_id: null,
            updatedat: "now()"
          }
        ) {
          affected_rows
        }
      }
      `
      return await client.mutate({
        mutation: DELETE_RETURN_MOVE,
        variables: {
          move_id: move_id
        }
      })
    },
    deleteUnplannedMove: async function(client, move_id) {
      const DELETE_UNPLANNED_MOVE = gql`
      mutation deleteUnplannedMove($move_id: bigint!) {
        update1: update_moves(
          where: {
            id: {
              _eq: $move_id
            }
          },
          _set: {
            active: 0,
            updatedat: "now()"
          }
        ) {
          affected_rows
        }
      }
      `
      return await client.mutate({
        mutation: DELETE_UNPLANNED_MOVE,
        variables: {
          move_id: move_id
        }
      })
    },
    getMove: async function(client, id) {
      const MY_QUERY = gql`
        query get_move($id: bigint!) {
          moves(where: {id: {_eq: $id}}) {
            ...Move
          }
        }
        ${fragments.move}      
      `;

      const res = await client.query({
        query: MY_QUERY,
        variables: {id: id}
      })

      return res.data.moves[0]
    },
    getSuggestedRoundTripMoves: async function(client, lane_id, includeManual, excluded_move_ids=[]) {
      console.log(`Querying for suggested round trips using: `, lane_id, includeManual, excluded_move_ids)
      const MY_QUERY = gql`
        query get_move($lane_id: bigint!, $excluded_move_ids: [bigint!]!) {
          moves(order_by: { manual_flag: desc_nulls_last, priority: asc_nulls_last, createdat: asc},
            where: {
              _and: {
                active: {
                  _eq: 1
                },
                plan_id: {
                  _is_null: true
                },
                lane_id: {
                  _eq: $lane_id
                }, 
                move_type: {
                  _eq: "drive"
                },
                id: {
                  _nin: $excluded_move_ids
                },
                ${
                  (!includeManual) ?
                  `_or: [
                    {
                      manual_flag: {
                        _is_null: true
                      }
                    },
                    {
                      manual_flag: {
                        _eq: false
                      }
                    }
                  ]`:
                  ''
                }
              }
            }
          ) {
            ...Move
            }
          }
        ${fragments.move}    
      `;

      const res = await client.query({
        query: MY_QUERY,
        variables: {lane_id: lane_id, excluded_move_ids: excluded_move_ids},
        fetchPolicy: "network-only"
      })

      return res.data.moves
    },

    /**
     * Set or unset a 'drive-lyft relationship'. Sets the lyft_flag field of the drive move and the lyft_trigger_id field of the lyft move.
     * 
     * @param  {Object} client - The ApolloClient object
     * @param  {int} flagBool- Smallint . if 1, function will set flag and triggers. If 0, will unset flag and triggers
     * @param  {int} drive_id - The id of the drive to set as the lyft trigger
     * @param  {int} lyft_id - The id of the lyft ride whose lyft_trigger_id will be set
     * 
     */
    updateLyftPair: async function(client, flagBool, drive_id, lyft_id) {
      const UPDATE_LYFT_PAIR = gql`
      mutation update_lyft_pair($drive_id: bigint!, $lyft_id: bigint! $flag_bool: smallint! $trigger_id: bigint!) {
        update_lyft_flag: update_moves(
          where: {
            id: {
              _eq: $drive_id
            }
          },
          _set: {
            lyft_flag: $flag_bool,
            updatedat: "now()"
          }
        ) {
          affected_rows
        }
        update_lyft_trigger_id: update_moves(
          where: {
            id: {
              _eq: $lyft_id
            }
          },
          _set: {
            lyft_trigger_id: $trigger_id,
            updatedat: "now()"
          }
        ) {
          affected_rows
        }
      }
      `
      return await client.mutate({
        mutation: UPDATE_LYFT_PAIR,
        variables: {
          flag_bool: flagBool,
          drive_id: drive_id,
          lyft_id: lyft_id,
          trigger_id: (flagBool ? drive_id : null)
        }
      })
    },
    //template for generating new moves
    buildNewMoveObject : (overrides={}) => {
      if (log) console.log('MoveHelper.buildNewMoveObject')
      
      let newMove = {
          active: 1,
          plan_id: null,
          pickup_time: '',
          delivery_time: '',
          pickup_stop_id: null,
          delivery_stop_id: null,
          sequence: 0,
          customer_id: 0,
          tookan_relationship_id: '',
          class: 'stranded',
          lane_id: 0,
          vehicle_year: null,
          vehicle_make: null,
          vehicle_model: null,
          vehicle_color: null,
          vehicle_stock: null,
          vehicle_vin: null,
          vehicle_odometer: null,
          vehicle_image: null,
          move_details: null,
          auto_assign: 0,
          lyft_flag: 0,
          move_type: 'drive',  
          ride_type: null,  
          rate_class_override: 0,
          createdAt: '',
          updatedAt: '',
          lane: null,
          suggested: false,
          new: true,
          lyft_trigger_id: null,
          return_ride_id: null,
          grouped_return_rides: [],
          return_ride: {}
      }

      if (log) console.log('MoveHelper.buildNewMoveObject: Applying overrides: ', overrides)

      //Apply any overridden properties
      newMove = Object.assign(newMove, overrides)

      if (log) console.log('MoveHelper: Built new move object', newMove)

      return newMove
  }
};

export default MoveHelper;