import React, { useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../../global-context';
import axios from 'axios';
import {
  makeStyles,  
  Grid,
  Typography, Tooltip,
  InputBase,
  FormGroup,
} from '@material-ui/core';

import { DropzoneArea } from 'material-ui-dropzone';

import Divide from '../../reusable/Divide'

const log = false;
const timestamp = new Date().getTime();

export default function CustomerDetailsBranding(props) {
    const ctx = useContext(GlobalContext);
    const cls = useStyles();

    var { config } = props.customer;
    var { editMode, customer, inMemoryCustomer, handleInMemoryCustomer } = props;

    const [ hexCheck, setHex ] = useState({
      primary_color: false,
      secondary_color: false
    });

    const [ obj, setObj ] = useState({
      name: config && config.branding && config.branding.name ? config.branding.name : null,
      primary_color: config && config.branding && config.branding.name ? config.branding.primary_color : null,
      secondary_color: config && config.branding && config.branding.secondary_color ? config.branding.secondary_color : null,
      logo_url: config && config.branding && config.branding.logo_url ? config.branding.logo_url : null,
    });

    const refreshImage = () => {
      var timestamp = new Date().getTime();
      var el = document.getElementById('logo');
      var queryString = '?=' + timestamp;
      el.src = obj.logo_url + queryString;
    };

    useEffect(() => { 
      handleInMemoryCustomer({ config: { name: 'branding', value: obj } });
    }, [obj])

    const handleChange = event => {
      if (event.target.name === 'primary_color' || event.target.name === 'secondary_color') {
        if (event.target.value.length > 7 || event.target.value[0] !== '#') setHex({...hexCheck, [event.target.name]: true})
        else { 
          setHex({...hexCheck, [event.target.name]: false})
          setObj({...obj, [event.target.name]: event.target.value}) 
        }
      } else setObj({...obj, [event.target.name]: event.target.value}) 
    };

    const handleLogoUpload = async files => {
      log && console.log('Handling logo upload: ', files)

      for (var i = 0; i < files.length; i++) {
        var file = files[0];
        var reader = await new FileReader();
        reader.addEventListener('loadend', async e => {
          log && console.log("File", file)
          try {
            const response = await axios({
              url: '/.netlify/functions/requestUploadUrl',
              method: 'POST',
              data: {
                prevLogo: config && config.branding && config.branding.logo_url ? config.branding.logo_url : null,
                file: {
                  name: file.name,
                  path: file.path,
                  size: file.size,
                  type: file.type
                },
                fileName: customer.id,
                fileType: file.type,
                bucketName: 'hopdrive-branding-logos'
              },
              headers: {
                'content-type': 'application/json',
              },
            });

            const { uploadURL } = response.data;
            log && console.log('Received an upload URL: ', uploadURL);
            console.log('file object: ', file)
            log && console.log(`About to upload size:${file.size} type:${file.type} to ${uploadURL}`);
            const uploadResponse = await axios.put(uploadURL, file, { headers: { 'Content-Type': 'image/jpeg' } });
            log && console.log('Response from S3 logo upload: ', uploadResponse);

            if (uploadResponse.status >= 200 && uploadResponse.status < 400) {
              log && console.log(`Successful logo upload. Updating customer config with URL...`)
              let logoUrl = '';
              try {
                logoUrl = uploadURL.split('?')[0];
                log && console.log(`Logo URL parsed from the S3 upload request URL: ${logoUrl}`);
                setObj({...obj, logo_url: logoUrl})
                refreshImage()
              } catch (error) {
                console.error(error);
              }
            } else {
              log && console.log(`Failed to upload the logo`);
              ctx.handleNotifications(true, `error`, `Failed to upload the logo`);
            }
          } catch (error) {
            console.error(`Unknown error getting an upload url: ${error.toString()}`);
            ctx.handleNotifications(true, `error`, `Failed to upload the logo`);
          }
        });
        reader.readAsArrayBuffer(file);
      }
      refreshImage()
      return false;
    };


    if (customer.branded || inMemoryCustomer.branded) return (<>
      <Divide spacer tip="Configure white-lableing be displayed via the Consumer Portal.">
        White Labeling
      </Divide>

      <div className={cls.main}>
          {ctx.width === `sm` || ctx.width === `xs` ? <div className={cls.bigBreak} /> : null}
          <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                  <div className={ cls.paper } style={!editMode ? { maxHeight: '10rem' } : { minHeight: '20rem' }}>
                      <FormGroup>
                        { !editMode ? 
                          <div className={ cls.whitelabeling }>
                            {/* STATIC BRANDING CONFIGURATIONS */}
                            <Grid container className={ cls.labeled }>
                                <Grid item sm={12} className={cls.listItem}>
                                  <Typography className={cls.keyTxt}>Brand&nbsp;Name:&nbsp;&nbsp;</Typography>
                                  <Typography className={cls.valTxt}>{ config && config.branding && config.branding.name ? config.branding.name : null }</Typography>
                                </Grid>

                                <Grid item sm={12} className={cls.listItem}>
                                  <Typography className={cls.keyTxt}>Primary&nbsp;Color:&nbsp;&nbsp;</Typography>
                                  <Typography className={cls.valTxt}>{ config && config.branding && config.branding.primary_color ? config.branding.primary_color : null }</Typography>
                                  <Typography className={cls.swatch} style={{ backgroundColor: obj.primary_color }}>&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                                </Grid>

                                <Grid item sm={12} className={cls.listItem}>
                                  <Typography className={cls.keyTxt}>Secondary&nbsp;Color:&nbsp;&nbsp;</Typography>
                                  <Typography className={cls.valTxt}>{ config && config.branding && config.branding.secondary_color ? config.branding.secondary_color : null }</Typography>
                                  <Typography className={cls.swatch} style={{ backgroundColor: obj.secondary_color }}>&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                                </Grid>
                            </Grid> 

                            <Grid container className={cls.logoContainer}>
                              <Grid item sm={6} className={cls.listItem}>
                                <img 
                                  className={ cls.logo } 
                                  alt={`branding logo`} 
                                  onError={(e) => { e.target.onerror = null; e.target.src = `https://hopdrive-branding-logos.s3.amazonaws.com/no-image-found.png` } }
                                  src={ config && config.branding && config.branding.logo_url ? config.branding.logo_url : `https://hopdrive-branding-logos.s3.amazonaws.com/no-image-found.png` } 
                                />
                              </Grid>
                            </Grid>
                          </div> 
                          : 
                          <div className={ cls.whitelabelingEdit }>
                            {/* EDITABLE BRANDING CONFIGURATIONS */}
                            <Grid container className={ cls.labeled }>
                                <Grid item sm={12}>
                                  <Typography className={cls.sub}>
                                    Allow customers to display their own unique branding to consumers. White-labeling is currently 
                                    displayed via the concierge portal and related consumer facing sms&nbsp;messaging.
                                  </Typography>
                                </Grid>

                                <Grid item sm={12} className={cls.listItem}>
                                  <Typography className={cls.keyTxt}>Brand&nbsp;Name:&nbsp;&nbsp;</Typography>
                                  <InputBase 
                                    className={cls.valTxt}
                                    defaultValue = { config && config.branding && config.branding.name ? config.branding.name : null }
                                    name='name'
                                    onChange={ handleChange }
                                    inputProps={{ className: cls.nakedValInput }}
                                  />                                
                                </Grid>

                                <Grid item sm={12} className={cls.listItem}>
                                  <Typography className={cls.keyTxt}>Primary&nbsp;Color:&nbsp;&nbsp;</Typography>
                                  <Tooltip title="Hex-Code Required (e.g. #f44232)">
                                    <InputBase 
                                      className={cls.valTxt}
                                      defaultValue = { config && config.branding && config.branding.primary_color ? config.branding.primary_color : null }
                                      name='primary_color'
                                      onChange={ handleChange }
                                      inputProps={{ className: cls.nakedValInput }}
                                    /> 
                                  </Tooltip>  
                                  <Typography className={cls.swatch} style={{ backgroundColor: obj.primary_color }}>&nbsp;&nbsp;&nbsp;&nbsp;</Typography>                                
                                </Grid>
                                { hexCheck.primary_color ?  <Grid item sm={12}><Typography className={cls.hex}>invalid color: must be hex formatted e.g. #f44232</Typography></Grid> : null }                                                   
                                

                                <Grid item sm={12} className={cls.listItem}>
                                  <Typography className={cls.keyTxt}>Secondary&nbsp;Color:&nbsp;&nbsp;</Typography>
                                  <Tooltip title="Hex-Code Required (e.g. #f44232)">
                                    <InputBase 
                                      className={cls.valTxt}
                                      defaultValue = { config && config.branding && config.branding.secondary_color ? config.branding.secondary_color : null }
                                      name='secondary_color'
                                      onChange={ handleChange }
                                      inputProps={{ className: cls.nakedValInput }}
                                    />  
                                  </Tooltip> 
                                  <Typography className={cls.swatch} style={{ backgroundColor: obj.secondary_color }}>&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                                </Grid>
                                { hexCheck.secondary_color ?  <Grid item sm={12}><Typography className={cls.hex}>invalid color: must be hex formatted e.g. #f44232</Typography></Grid> : null }                                                   
                            </Grid> 

                            <Grid container spacing={2} className={cls.logoContainerEdit} style={{maxHeight: '10rem'}}>
                              <Grid item xs={12} className={cls.listItem}>                     
                                <Typography className={cls.valTxt} style={{color: ctx.theme.palette.error.main }}>max file size 0.5 MB</Typography>  
                              </Grid>
                              <Grid item xs={6} >
                                <img 
                                  id='logo' 
                                  src={ obj.logo_url ? `${obj.logo_url}?t=${timestamp}`: null } 
                                  className={ cls.logo } 
                                  alt='branding logo'
                                  onError={(e) => { e.target.onerror = null; e.target.src = `https://hopdrive-branding-logos.s3.amazonaws.com/no-image-found.png` } }
                                />
                              </Grid>
                              <Grid item xs={6} >
                                <DropzoneArea
                                  useChipsForPreview={true}
                                  acceptedFiles={['image/*']}
                                  dropzoneText={'Drag and drop customer logo here or click to upload.'}
                                  maxFileSize={500000}
                                  onChange={handleLogoUpload}
                                  filesLimit={1}
                                />
                              </Grid>
                            </Grid>
                          </div> 
                        }
                      </FormGroup>
                  </div>
              </Grid>
          </Grid>
      </div>
    </> )
    else return null
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  labeled: {
    display: 'block',
    minHeight: '15rem',
    overflow: 'auto',
    marginTop: '1rem'
  },
  hex: {
    display: 'block',
    color: theme.palette.error.main,
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    margin: '0.4em',
  },
  container: {
    display: 'block',
  },
  checkbox: {
    display: 'block',
    marginBottom: '0.5rem',
    "&:checked": {
      backgroundColor: 'red'
    }
  },
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  sub: {
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    marginBottom: '1rem',
    marginLeft: '0.1rem'
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
    height: 'auto',
    // overflow: 'auto'
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    margin: '0.4em',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedValTxt: {
    width: '100%',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedForm: {
    width: '100%',
    minHeight: '17px',
    maxHeight: '17px',
    textAlign: 'right',
  },
  nakedSelect: {
    fontSize: 14,
    fontWeight: 400,
    minHeight: '17px',
    maxHeight: '17px',
    padding: 0,
    background: '#00000008',
    textAlign: 'right',
  },
  nakedValInput: {
    width: '100%',
    minHeight: '17px',
    maxHeight: '17px',
    padding: 0,
    background: '#00000008',
    textAlign: 'right',
  },
  switch: {
    width: '100%',
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  logoContainer: {
    alignItem: 'center',
    justifyContent: 'center',
  },
  logoContainerEdit: {
    alignItem: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-5rem',
    }
  },
  logo: {
    maxWidth: '50%',
    maxHeight: '50%',
    marginLeft: '5rem',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '40%',
    },
  },
  whitelabeling: {
    overflow: 'scroll',
    display: 'flex',
  },
  whitelabelingEdit: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
      display: 'block',
    },
  },
  swatch: {
    height: '1.2rem',
    width: '1.5rem',
    marginLeft: '0.5rem',
    borderRadius: '20%'
  }
}));