import React, { Component, Fragment } from "react";
import { Draggable } from 'react-beautiful-dnd';
import { GlobalContext } from "../../../global-context";
import { Typography, Box, Grid, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLyft, faUber } from '@fortawesome/free-brands-svg-icons'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { faThumbtack } from '@fortawesome/free-solid-svg-icons'
import ScheduleIcon from '@material-ui/icons/Schedule'
import AssignmentIcon from '@material-ui/icons/Assignment'
import ManualIcon from '@material-ui/icons/NatureTwoTone'
import PriorityIcon from '@material-ui/icons/NewReleases'
import MoreIcon from '@material-ui/icons/More'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto'
import Zoom from '@material-ui/core/Zoom'
import moment from "moment";
import { ContextMenuTrigger } from "react-contextmenu";
import StatusIcon from './StatusIcon';
import ChipInput from 'material-ui-chip-input'
// import MoveHelper from "../../utils/MoveHelper"

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    grow: {
        flexGrow: 1,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        border: '1px',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    infoTooltip: {
        minWidth: 550,
        maxWidth: 650
    },
})

// let log = true  //Enable for verbose console logging

function collect(props) {
    return {
        move: props.move,
        plan: props.plan,
        suggested: props.suggested,
        onItemClick: props.onItemClick
    }
}

class MoveDraggable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pinnable: false,
            rightMargin: 6,
            topBottomMargin: 10,
            keyPressed: 0,
        }
    }

    componentWillMount = () => {
        this.setState({
            pickupLocationName: (this.props.move.lane != null) ? this.props.move.lane.pickup.name : '',
            deliveryLocationName: (this.props.move.lane != null) ? this.props.move.lane.delivery.name : ''
        })
    }

    showMoveDetails = () => {
        if ((this.context.selectedMove) && (this.context.selectedMove.id === this.props.move.id)) {
            //this.context.selectMoveForViewEdit(null)  //unselect
            console.log('Clicked showMoveDetails() in unselect mode')
        } else {
            //this.context.selectMoveForViewEdit(this.props.move)
            console.log('Clicked showMoveDetails() in select mode')
        }
    }

    createMoveGroup = (rideMove) => {
        const plannedMovesArr = this.props.allPlannedMoves
        const ridesInGroup =
            plannedMovesArr
                .filter(m => (m.lyft_trigger_id !== null) && (m.lyft_trigger_id === rideMove.lyft_trigger_id))
                .map(m => m.id)
        const drivesInGroup =
            plannedMovesArr
                .filter(m => ridesInGroup.includes(m.return_ride_id))
                .map(m => m.id)
        const fullGroup = ridesInGroup.concat(drivesInGroup)
        return fullGroup
    }

    isInSelectedGroup = () => {
        if (this.props.move.move_type === 'ride' && this.props.move.lyft_trigger_id) {
            return this.createMoveGroup(this.props.move).includes(this.props.selectedMove.id)
        } else if (this.props.move.move_type === 'drive' && this.props.move.return_ride_id) {
            let rideMove = this.props.plan.moves.find(m => m.id === this.props.move.return_ride_id)
            return this.createMoveGroup(rideMove).includes(this.props.selectedMove.id)
        }
    }

    getMoveClasses = (isDragging) => {
        let c = ''

        //If the move is a suggested move that has not yet been accepted then
        // it looks different and it also cannot be dragged around
        //c += (this.props.move.suggested) ? ' suggested' : ' draggable'

        //Set class for while it's being moved
        //c += (isDragging) ? ' dragging' : ''

        // set selected/un-selected classes if there is a selectedMove
        // c += ((this.context.selectedMove) && (this.context.selectedMove.id !== this.props.move.id)) ? ' not-selected' : ''
        // c += ((this.context.selectedMove) && (this.context.selectedMove.id === this.props.move.id)) ? ' selected' : ''
        // c += ((this.context.selectedMove) && (this.isInSelectedGroup())) ? ' selected' : ''

        if (!this.props.selectedMove) {
            c += ''
        } else if (this.props.selectedMove.id === this.props.move.id) {
            c += ' selected'
        } else if ((this.props.move.return_ride_id || this.props.move.lyft_trigger_id) && this.isInSelectedGroup()) { //this is running waay to often
            c += ' selected'
        } else if (this.props.selectedMove.id !== this.props.move.id) {
            c += ' not-selected'
        }

        //Set move color
        c += (this.props.move.move_type === 'drive') ? ' movetype-drive' : ''
        c += (this.props.move.move_type === 'ride' && this.props.move.ride_type === 'lyft') ? ' movetype-ride-lyft' : ''
        c += (this.props.move.move_type === 'ride' && this.props.move.ride_type === 'uber') ? ' movetype-ride-uber' : ''
        c += (this.props.move.move_type === 'ride' && this.props.move.ride_type === 'split') ? ' movetype-ride-split' : ''
        c += (this.props.move.move_type === 'ride' && this.props.move.ride_type === 'sat') ? ' movetype-ride-sat' : ''
        c += (this.props.move.cancel_status) ? ' movetype-canceled' : ''

        return c;
    }



    moveWidth = () => {
        if (this.props.move.move_type === 'drive') {
            //console.log(`   move-${this.props.move.id} calculating width using: ${this.props.move.box_specs.pickup.px} + ${this.props.move.box_specs.drive.px} + ${this.props.move.box_specs.driveExtra.px} + ${this.props.move.box_specs.delivery.px} - ${this.state.rightMargin}`)
            return this.props.move.box_specs.pickup.px +
                this.props.move.box_specs.drive.px +
                this.props.move.box_specs.driveExtra.px +
                this.props.move.box_specs.delivery.px -
                this.state.rightMargin
        } else {
            return this.props.move.box_specs.pickup.px +
                this.props.move.box_specs.drive.px +
                this.props.move.box_specs.driveExtra.px +
                this.props.move.box_specs.delivery.px -
                this.state.rightMargin
        }
    }

    isDragDisabled = (move) => {

        //const diffMin = Number(moment.duration(move.box_specs.pickup.times.start.diff(moment())).asMinutes())
        //console.log(`move #${move.id} isDragDisabled: ${diffMin}`)

        //Check if the move starts in the past
        if (move.box_specs.pickup.times.start < moment()) {
            //This move is in the past so do not let it be dragged
            //console.log(`   move-${move.id} - prevent dragging because the move is in the past: ${move.box_specs.pickup.times.start.format('h:mm a')}`)
            return true
        }

        if (move.suggested) {
            return true
        }

        return false
    }

    getBufferDebugTooltip = (move, width) => (
        <Box display="flex" flexDirection="column">
            <Grid container direction="row" justify="space-between" alignItems="center"><Box>Buffer</Box><Box>{move.box_specs.buffer.px} px</Box></Grid>
            <Grid container direction="row" justify="space-between" alignItems="center"><Box>Buffer</Box><Box>{move.box_specs.buffer.min.toFixed(0)} mins</Box></Grid>
            <Grid container direction="row" justify="space-between" alignItems="center"><Box>Start Time </Box><Box>{moment(move.box_specs.buffer.times.start).format('M/D h:mm A')}</Box></Grid>
            <Grid container direction="row" justify="space-between" alignItems="center"><Box>End Time </Box><Box>{moment(move.box_specs.buffer.times.end).format('M/D h:mm A')}</Box></Grid>
        </Box>
    )

    handleRightClick = (e, data, target) => {
        console.log('MoveDraggable.handleRightClick()')
    }

    render() {
        const { classes, move, plan, index, timelineScale, planHeight } = this.props
        const { rightMargin, topBottomMargin } = this.state

        return (
            <Box display="flex" flexDirection="row" p={0} m={0} id={`buffered-move-${move.id}`}>
                <Tooltip enterDelay={3000} TransitionComponent={Zoom} disableHoverListener={true} title={this.getBufferDebugTooltip(move, this.moveWidth())} placement="top">
                    <div id="left-time-buffer" style={{ border: "0px dashed red", height: planHeight - (topBottomMargin * 2), width: move.box_specs.buffer.px, display: 'inline-block' }} />
                </Tooltip>
                <ContextMenuTrigger
                    id={`move-draggable-context-menu`}
                    move={move}
                    plan={plan}
                    holdToDisplay={1000}
                    suggested={move.suggested}
                    //onItemClick={this.handleRightClick}
                    collect={collect}
                    disableIfShiftIsPressed={true}
                >
                    <Draggable

                        draggableId={`draggable-move-${move.id}`}
                        isDragDisabled={this.isDragDisabled(move)}
                        value={move.id}
                        key={move.id}
                        index={index}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={provided.draggableProps.style}
                                key={move.plan_id + '.' + move.id}
                                data-toggle="collapse"
                                // onClick={this.showMoveDetails}
                                onClick={() => { this.props.handleSelectMove(move) }}
                                value={move.id}
                                aria-expanded="false"
                                aria-controls={"move-detail-" + move.id}
                                id={`draggable-move-${move.id}`}
                            >
                                <Box style={{ backgroundColor: 'red !important' }} display="flex" flexDirection="row" height={planHeight - (topBottomMargin * 2)} p={0} m={0} mr={`${rightMargin}px`} className={`move  ${(snapshot.isDragging) ? ' dragging' : ''}  `}>

                                    <Tooltip title={`Pickup ran ${move.box_specs.pickupExtra.min.toFixed(0)} minutes behind`} placement="top">
                                        <Box width={`${move.box_specs.pickupExtra.px}px`} id={`late-p-${move.id}`} className="movetype-late late curvedLeftEdges" display={(move.box_specs.pickupExtra.px > 0) ? 'inline' : 'none'} />
                                    </Tooltip>
                                    <BodyLayout classes={classes} move={move} width={this.moveWidth()} snapshot={snapshot} getMoveClasses={this.getMoveClasses} isDragDisabled={this.isDragDisabled} timelineScale={timelineScale} useDebugMoveTooltip={this.props.useDebugMoveTooltip} />
                                    <Tooltip title={`Delivery ran ${move.box_specs.deliveryExtra.min.toFixed(0)} minutes behind`} placement="top">
                                        <Box width={`${move.box_specs.deliveryExtra.px}px`} id={`late-d-${move.id}`} className="movetype-late late curvedRightEdges" display={(move.box_specs.deliveryExtra.px > 0) ? 'inline' : 'none'} />
                                    </Tooltip>
                                </Box>
                            </div>
                        )}
                    </Draggable>
                </ContextMenuTrigger>
            </Box>
        )
    }
}

class BodyLayout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            keyPressed: 0,
            useDebugMoveTooltip: false
        }
    }

    componentDidMount() {
        //window.addEventListener('keydown', this.handleKeydownEvent, true)
    }

    componentWillUnmount() {
        //window.removeEventListener('keydown', this.handleKeydownEvent)
    }

    handleKeydownEvent = (event) => {
        //console.log(`keypress event: ${event.keyCode}`)
        this.setState({
            keyPressed: event.keyCode
        })
    }

    getToolTipText = (move, width) => (
        <Grid container spacing={2}>
            {this.props.useDebugMoveTooltip &&
                <Fragment>
                    <Grid item xs={4}>

                        <Grid container direction="row" justify="space-between" alignItems="center"><Box><strong>Move</strong></Box><Box>{move.id}</Box><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Customer Ref #</Box><Box>{move.reference_num}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>{move.lane.description} ({move.lane.id})</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Status</Box><Box>{move.status}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Stock</Box><Box>{move.vehicle_stock}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Plan</Box><Box>{move.plan_id}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Class</Box><Box>{move.class}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Type</Box><Box>{move.move_type}{move.move_type === 'ride' ? ` - ${move.ride_type}` : ''}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Updated</Box><Box>{moment(move.updatedat).format('M/D h:mm A')}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Suggested</Box><Box>{move.suggested ? 'true' : 'false'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Changes</Box><Box>{move.changed ? 'unsaved' : 'saved'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Pinnable</Box><Box>{move.pinnable ? 'true' : 'false'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Cancel Status</Box><Box>{move.cancel_status}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Class Override</Box><Box>{move.rate_class_override ? 'true' : 'false'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Lyft Trigger Id</Box><Box>{move.lyft_trigger_id}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Return Ride Id</Box><Box>{move.return_ride_id}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Tookan Relationship Id</Box><Box>{move.tookan_relationship_id ? move.tookan_relationship_id : "No Tookan Relationship Id"}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Previous Driver</Box><Box>{move.previous_driver_name ? move.previous_driver_name : "None"}</Box></Grid>

                    </Grid>
                    <Grid item xs={4}>

                        <Grid container direction="row" justify="space-between" alignItems="center"><Box><strong>Timeline</strong></Box><Box>{move.sequence}</Box><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Start</Box><Box>{move.startSource}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Placement</Box><Box>{move.box_specs.buffer.min.toFixed(0)} min : {move.box_specs.buffer.times.start.format('h:mm')}-{move.box_specs.buffer.times.end.format('h:mm a')}</Box></Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center"><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Pickup</Box><Box>{move.box_specs.pickup.min.toFixed(0)} min : {move.box_specs.pickup.times.start.format('h:mm')}-{move.box_specs.pickup.times.end.format('h:mm a')}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Pickup Extra</Box><Box>{move.box_specs.pickupExtra.min.toFixed(0)} min : {move.box_specs.pickupExtra.times.start.format('h:mm')}-{move.box_specs.pickupExtra.times.end.format('h:mm a')}</Box></Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center"><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Drive</Box><Box>{move.box_specs.drive.min.toFixed(0)} min : {move.box_specs.drive.times.start.format('h:mm')}-{move.box_specs.drive.times.end.format('h:mm a')}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Drive Extra</Box><Box>{move.box_specs.driveExtra.min.toFixed(0)} min : {move.box_specs.driveExtra.times.start.format('h:mm')}-{move.box_specs.driveExtra.times.end.format('h:mm a')}</Box></Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center"><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Delivery</Box><Box>{move.box_specs.delivery.min.toFixed(0)} min : {move.box_specs.delivery.times.start.format('h:mm')}-{move.box_specs.delivery.times.end.format('h:mm a')}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Delivery Extra</Box><Box>{move.box_specs.deliveryExtra.min.toFixed(0)} min : {move.box_specs.deliveryExtra.times.start.format('h:mm')}-{move.box_specs.deliveryExtra.times.end.format('h:mm a')}</Box></Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center"><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Totals</Box><Box>{((move.box_specs.pickup.min + move.box_specs.pickupExtra.min + move.box_specs.drive.min + move.box_specs.driveExtra.min + move.box_specs.delivery.min + move.box_specs.deliveryExtra.min).toFixed(0) * this.props.timelineScale) - 6} px, {move.box_specs.pickupExtra.px + width + move.box_specs.deliveryExtra.px} px {(move.box_specs.pickup.min + move.box_specs.pickupExtra.min + move.box_specs.drive.min + move.box_specs.driveExtra.min + move.box_specs.delivery.min + move.box_specs.deliveryExtra.min).toFixed(0)} min(s)</Box></Grid>

                    </Grid>
                    <Grid item xs={4}>

                        <Grid container direction="row" justify="space-between" alignItems="center"><strong>Times</strong><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Ready By</Box><Box>{move.ready_by ? moment(move.ready_by).format('h:mm a') : 'null'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Deliver By</Box><Box>{move.deliver_by ? moment(move.deliver_by).format('h:mm a') : 'null'}</Box></Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center"><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Planned Pickup</Box><Box>{move.pickup_time ? moment(move.pickup_time).format('h:mm a') : 'null'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Planned Delivery</Box><Box>{move.delivery_time ? moment(move.delivery_time).format('h:mm a') : 'null'}</Box></Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center"><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Pickup Started</Box><Box>{move.pickup_started ? moment(move.pickup_started).format('h:mm a') : 'null'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Pickup Arrived</Box><Box>{move.pickup_arrived ? moment(move.pickup_arrived).format('h:mm a') : 'null'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Pickup Successful</Box><Box>{move.pickup_successful ? moment(move.pickup_successful).format('h:mm a') : 'null'}</Box></Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center"><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Delivery Started</Box><Box>{move.delivery_started ? moment(move.delivery_started).format('h:mm a') : 'null'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Delivery Arrived</Box><Box>{move.delivery_arrived ? moment(move.delivery_arrived).format('h:mm a') : 'null'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Delivery Successful</Box><Box>{move.delivery_successful ? moment(move.delivery_successful).format('h:mm a') : 'null'}</Box></Grid>

                    </Grid>
                    <Grid item xs={12}>
                        {
                            move.box_specs.driveExtra.px > 0 &&
                            <Fragment>
                                <hr width="100%" style={{ opacity: 0.5 }} />
                                Drive ran {move.box_specs.driveExtra.min.toFixed(0)} minutes behind
                        </Fragment>
                        }
                    </Grid>
                </Fragment>
            }
            {!this.props.useDebugMoveTooltip &&
                <Fragment>
                    <Grid item xs={6}>

                        <Grid container direction="row" justify="space-between" alignItems="center"><Box><strong>Move</strong></Box><Box>{move.id}</Box><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Customer Ref #</Box><Box>{move.reference_num}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>{move.lane.description} ({move.lane.id})</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Status</Box><Box>{move.status}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Stock</Box><Box>{move.vehicle_stock}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Vehicle</Box><Box>{`${move.vehicle_color} ${move.vehicle_year} ${move.vehicle_make} ${move.vehicle_model}`}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Transmission</Box><Box>{move.manual_flag ? 'Manual' : 'Automatic'}</Box></Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center"><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Chargeable to Customer</Box><Box>{move.chargeable ? 'true' : 'false'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Payable to Driver</Box><Box>{move.payable ? 'true' : 'false'}</Box></Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center"><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Plan</Box><Box>{move.plan_id}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Class</Box><Box>{move.class} {move.rate_class_override ? '(overridden)' : ''}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Type</Box><Box>{move.move_type}{move.move_type === 'ride' ? ` - ${move.ride_type}` : ''}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Cancel</Box><Box>{move.cancel_status}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Suggested</Box><Box>{move.suggested ? 'true' : 'false'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Pinnable</Box><Box>{move.pinnable ? 'true' : 'false'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Lyft Trigger Id</Box><Box>{move.lyft_trigger_id}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Return Ride Id</Box><Box>{move.return_ride_id}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Tookan Relationship Id</Box><Box>{move.tookan_relationship_id ? move.tookan_relationship_id : "No Tookan Relationship Id"}</Box></Grid>

                    </Grid>
                    <Grid item xs={6}>

                        <Grid container direction="row" justify="space-between" alignItems="center"><strong>Times</strong><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Ready By</Box><Box>{move.ready_by ? moment(move.ready_by).format('h:mm a') : 'null'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Deliver By</Box><Box>{move.deliver_by ? moment(move.deliver_by).format('h:mm a') : 'null'}</Box></Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center"><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Planned Pickup</Box><Box>{move.pickup_time ? moment(move.pickup_time).format('h:mm a') : 'null'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Planned Delivery</Box><Box>{move.delivery_time ? moment(move.delivery_time).format('h:mm a') : 'null'}</Box></Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center"><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Pickup Started</Box><Box>{move.pickup_started ? moment(move.pickup_started).format('h:mm a') : 'null'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Pickup Arrived</Box><Box>{move.pickup_arrived ? moment(move.pickup_arrived).format('h:mm a') : 'null'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Pickup Successful</Box><Box>{move.pickup_successful ? moment(move.pickup_successful).format('h:mm a') : 'null'}</Box></Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center"><hr width="100%" style={{ opacity: 0.5 }} /></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Delivery Started</Box><Box>{move.delivery_started ? moment(move.delivery_started).format('h:mm a') : 'null'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Delivery Arrived</Box><Box>{move.delivery_arrived ? moment(move.delivery_arrived).format('h:mm a') : 'null'}</Box></Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center"><Box>Delivery Successful</Box><Box>{move.delivery_successful ? moment(move.delivery_successful).format('h:mm a') : 'null'}</Box></Grid>

                    </Grid>
                </Fragment>
            }
        </Grid>
    )

    getToolTipTags = (move) => (
        <Grid container spacing={2}>
            <Grid item xs={12}>

                <ChipInput
                    value={move.tags.split(",")}
                    disabled={true}
                />

            </Grid>
        </Grid>
    )


    render() {
        const { move, width, snapshot, getMoveClasses, isDragDisabled, classes } = this.props
        const attemptType = move.ride_type ? move.ride_type : move && move.lyftride && move.lyftride.activeAttempt && move.lyftride.activeAttempt.ride_type ? move.lyftride.activeAttempt.ride_type : null;
        const icon = attemptType === 'uber' ? faUber : attemptType === 'lyft' ? faLyft : null;

        return (
            <Tooltip enterDelay={1200} title={this.getToolTipText(move, width)} placement="bottom" classes={{ tooltip: classes.infoTooltip }}>
                <Box width={`${width}px`} id={`drive-time-${move.id}`} pt={move.move_type === "drive" ? 0 : 2} pb={1} pl={1} pr={1} className={`${move.box_specs.pickupExtra.px > 0 ? ' ' : 'curvedLeftEdges'} ${move.box_specs.deliveryExtra.px > 0 ? ' ' : 'curvedRightEdges'} ${getMoveClasses(snapshot.isDragging)} ${move.suggested ? ' suggested' : isDragDisabled(move) ? ' ' : ' draggable'}  `}>

                    {/* https://stackoverflow.com/questions/31000885/align-an-element-to-bottom-with-flexbox */}

                    <Box id={`move-details-flex-box-${move.id}`} display="flex" justifyContent="space-between" flexDirection="column" style={{ height: "100%" }}>
                        {move.move_type === "drive" &&
                            <Grid container item direction="row" xs={12} p={0} m={0} style={{ height: "4px" }}>
                                <Tooltip title={`Pickup ${move.box_specs.pickup.min} mins`} placement="bottom"><div style={{ height: '4px', width: `${move.box_specs.pickup.px - 8}px`, backgroundColor: 'DarkTurquoise' }} /></Tooltip>     {/* Planned Pickup Inspection Time */}
                                <Tooltip title={`Drive ${move.box_specs.drive.min} mins`} placement="bottom"><div style={{ height: '4px', width: `${move.box_specs.drive.px}px`, backgroundColor: 'Aquamarine' }} /></Tooltip>        {/* Planned Drive Time */}
                                <Tooltip title={`Drive Extra ${move.box_specs.driveExtra.min} mins`} placement="bottom"><div style={{ height: '4px', width: `${move.box_specs.driveExtra.px}px`, backgroundColor: 'PowderBlue' }} /></Tooltip>        {/* Extra Drive Time */}
                                <Tooltip title={`Delivery ${move.box_specs.delivery.min} mins`} placement="bottom"><div style={{ height: '4px', width: `${move.box_specs.delivery.px - 8 - 6}px`, backgroundColor: 'DeepSkyBlue' }} /></Tooltip>       {/* Planned Delivery Inspection Time */}
                            </Grid>}
                        <Grid container item direction="row" xs={12} >
                            <Grid item xs={1} style={{ transform: "rotate(315deg)", position: "absolute", top: "-7px", left: "-2px" }}>
                                {move.pinnable === true &&
                                    <Tooltip title={`Move is Pinned`} placement="bottom">
                                        <Typography variant="caption" noWrap={true}>
                                            <FontAwesomeIcon icon={faThumbtack} style={{ color: 'LimeGreen' }} />
                                        </Typography>
                                    </Tooltip>}
                            </Grid>
                            <Grid item xs={10} style={{ marginTop: "-4px" }}>
                                <Tooltip title={`Lane: ${move.lane.description}`} placement="bottom"><Typography variant="caption">{move.lane.description}</Typography></Tooltip>
                            </Grid>
                            <Grid item xs={1} style={{ transform: "scale(0.65)", position: "absolute", top: "-10px", right: "1px", zIndex: 1 }}  >
                                {move.move_type === 'drive' && move.status !== 'dispatched' &&
                                    <StatusIcon
                                        moveStatus={move.status}
                                        cancelStatus={move.cancel_status}
                                        isSynced={(move.synced_with_tookan === move.updatedat)}
                                    />}
                            </Grid>
                        </Grid>
                        <Box width={"100%"} mb={"-8px"} >
                            <Grid container item direction="row" xs={12}>
                                <Grid item xs={4}>
                                    <Tooltip title={`Stock Number`} placement="bottom">
                                        <Typography variant="caption" noWrap={true}>
                                            { move.move_type === 'ride' ?
                                             <FontAwesomeIcon icon={icon} style={{ transform: "scale(1.5)", margin: "2px" }} />    
                                            : move.vehicle_stock}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: "center" }}>
                                    <Tooltip title={`Move ID`} placement="bottom">
                                        <Typography variant="caption" noWrap={true}>{move.id}</Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: "right" }}>
                                    {/* drive icons */}
                                    { move.lyft_flag === 1 && <Tooltip title={`Lyft Trigger`} placement="bottom">
                                        <Fragment><FontAwesomeIcon icon={icon} inverse mask={faComment} transform='shrink-3' style={{ transform: "scale(1.25)", margin: "2px" }} /></Fragment>
                                    </Tooltip> }
                                    {move.priority && (move.priority <= 5) &&
                                        <Tooltip title={`Priority Level: ${move.priority}`} placement="bottom">
                                            <PriorityIcon style={{ transform: "scale(0.65)", margin: "-4px" }} />
                                        </Tooltip>
                                    }
                                    {move.consumer_type && move.consumer_type === 'customer' &&
                                        // Previously looking at 'consumer_pickup' field but that is currently always set on the 2nd move in a C+L sequence.
                                        // This will set the tag based on the actual vehicle type specified by the dealer.
                                        <Tooltip title={`Consumer Vehicle - ${move.consumer_name || `Unknown`} @ ${move.consumer_phone || `N/A`}`} placement="left">
                                            <Typography style={{ verticalAlign: 'top', display: 'inline-block', maxWidth: 'min-content', margin: '0 4px', lineHeight: 1.195, fontSize: '15px', fontWeight: '900' }}>C</Typography>
                                        </Tooltip>
                                    }
                                    {(move.tags && move.tags.includes(`loaner`) || move.consumer_type && move.consumer_type === 'loaner') ?
                                        <Tooltip title={`Loaner Vehicle`} placement="left">
                                            <Typography style={{ verticalAlign: 'top', display: 'inline-block', maxWidth: 'min-content', margin: '0 4px', lineHeight: 1.195, fontSize: '15px', fontWeight: '900' }}>L</Typography>
                                        </Tooltip> : null
                                    }
                                    {move.move_details &&
                                        <Tooltip title={move.move_details} placement="bottom">
                                            <AssignmentIcon style={{ transform: "scale(0.65)", margin: "-4px" }} />
                                        </Tooltip>
                                    }
                                    {move.deliver_by &&
                                        <Tooltip title={`Delivery by ${moment(move.deliver_by).format('h:mm a')}`} placement="bottom">
                                            <ScheduleIcon style={{ transform: "scale(0.65)", margin: "-4px" }} />
                                        </Tooltip>
                                    }
                                    {move.manual_flag &&
                                        <Tooltip title={`Manual transmission`} placement="bottom">
                                            <ManualIcon style={{ transform: "scale(0.65)", margin: "-4px" }} />
                                        </Tooltip>
                                    }
                                    {move.vehicle_image &&
                                        <Tooltip title={<img src={`${move.vehicle_image}`} alt="" />} placement="bottom">
                                            <InsertPhotoIcon style={{ transform: "scale(0.65)", margin: "-4px" }} />
                                        </Tooltip>
                                    }
                                    {/* ride icons */}
                                    {move.lyft_trigger_id &&
                                        <Tooltip title={`Lyft trigger is move ${move.lyft_trigger_id}`} placement="bottom">
                                            <CheckCircleIcon style={{ transform: "scale(0.65)", margin: "-4px" }} />
                                        </Tooltip>
                                    }
                                    {move.tags &&
                                        <Tooltip title={this.getToolTipTags(move)} placement="bottom">
                                            <MoreIcon style={{ transform: "scale(0.65)", margin: "-4px" }} />
                                        </Tooltip>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </Box >

                </Box >
            </Tooltip >
        )
    }
}

MoveDraggable.contextType = GlobalContext

export default withStyles(styles)(MoveDraggable)