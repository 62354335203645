import React from 'react';
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Icon,
  LinearProgress,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import fragments from '../../utils/graphQL/fragments';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import PayPeriodDetailTableHeader from './PayPeriodDetailTableHeader';
import Printable from '../../reusable/Printable';
import { ExportToCsv } from 'export-to-csv';

export default function PayPeriodDetailTable({ payPeriod }) {
  const cls = useStyles();

  const generateCSV = appayments => {
    const createCsvRow = appayment => {
      var {
        move= {},
        accessorial={},
        type: apType = '',
        status: apStatus = '',
        notes: apNotes = '',
        amount: apAmount = 0
      } = appayment || {};
      appayment = { move: move ? move : {}, accessorial: accessorial ? accessorial : {}, apType: apType ? apType : '', apNotes: apNotes ? apNotes : '', apAmount: apAmount ? apAmount : 0 }
      var {
        code: apCode = '',
        notes: accessorialNotes = ''
      } = accessorial || {};
      accessorial = { apCode: apCode ? apCode : '', accessorialNotes: accessorialNotes ? accessorialNotes : '' };
      var {
        id: moveId = 0,
        lane = {},
        pickup_time= '',
        move_type= '',
        driver_name= ''
      } = move || {};
      move = { moveId: moveId ? moveId : '', lane: lane ? lane : {}, pickup_time: pickup_time ? pickup_time : '', move_type: move_type ? move_type : '',  driver_name: driver_name ? driver_name : '' };
      var {
        description: laneDescription = '',
        distance_miles= ''
      } = lane || {};
      lane = { laneDescription: laneDescription ? laneDescription : '', distance_miles: distance_miles ? distance_miles : '' };

      return {
        MOVE_ID: moveId,
        DRIVER_NAME: driver_name,
        PICKUP_TIME: moment(pickup_time).format('MM/DD/YYYY HH:mm'),
        TYPE:
          apType === 'move pay'
            ? `${apType} (${move_type})`
            : apType === 'accessorial'
            ? `${apType} (${apCode})`
            : apNotes,
        DESCRIPTION:
          apType === 'move pay'
            ? laneDescription
            : apType === 'accessorial'
            ? accessorialNotes
            : apNotes,
        DISTANCE: apType === 'move pay' ? distance_miles + ' mi' : null,
        STATUS: apStatus,
        AMOUNT: apAmount.toFixed(2),
      };
    };
    var csvRows = appayments.map(appayment => createCsvRow(appayment));
    csvRows = csvRows.filter(entry => Number(entry.AMOUNT) > 0); // filter out 0 dollar ap items

    var {
      driver_id = 0,
      driver_name = '',
      move_count = 0,
      pay_period = '',
      pickup_time_end = '',
      pickup_time_start = '',
      region = {},
      status = '',
      total_pay_amount = 0,
    } = payPeriod || {};
    payPeriod = { driver_id: driver_id ? driver_id : 0, driver_name: driver_name ? driver_name : '', move_count: move_count ? move_count : 0, pay_period: pay_period ? pay_period : '', pickup_time_end: pickup_time_end ? pickup_time_end : '', pickup_time_start: pickup_time_start ? pickup_time_start : '', region: region ? region : {}, status: status ? status : '', total_pay_amount: total_pay_amount ? total_pay_amount : 0 }
    var {
      name: regionName = ''
    } = region || {};
    region = { regionName: regionName ? regionName : '' };

    const csvOptions = {
      filename: `${driver_id ? driver_name.replace(/ /g, '_') : `All`}_Driver_Pay_from_${moment(
        pickup_time_start
      ).format('MM/DD/YYYY HH:mm')}_to_${moment(pickup_time_end).format('MM/DD/YYYY HH:mm')}`,
      showTitle: true,
      title: `${driver_id ? driver_name : `All`} Driver Pay from ${moment(
        pickup_time_start
      ).format('MM/DD/YYYY HH:mm')} to ${moment(pickup_time_end).format('MM/DD/YYYY HH:mm')}`,
      useKeysAsHeaders: true,
    };

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  if (false) { //!payPeriod.driver_id
    return (
      <div className={cls.notFound}>
        <Typography className={cls.notFoundTxt}>DRIVER ID IS UNDEFINED ON {payPeriod.move_count} MOVES FOR THIS PAY PERIOD</Typography>
      </div>
    )
  }

  const GET_APPAYMENTS = gql`
    query get_payments_for_driver_pay_detail_table($driverId: bigint, $start: timestamptz, $end: timestamptz) {
      appayments(
        where: {
          move: {
            active: { _eq: 1 }
            pickup_time: { _gte: $start, _lte: $end }
            _or: [{ cancel_status: { _neq: "canceled" } }, { cancel_status: { _is_null: true } }]
          }
          active: { _eq: 1 }
          ${payPeriod.driver_id ? 'driver_id: { _eq: $driverId }' : 'driver_id: {_is_null: true}'}
        }
        order_by: { move: { pickup_time: desc } }
      ) {
        ...APPayment
        move {
          ...Move
        }
        accessorial {
          ...Accessorials
        }
      }
    }
    ${fragments.move}
    ${fragments.appayment}
    ${fragments.accessorials}
  `;

  return (
    <Query
      query={GET_APPAYMENTS}
      variables={payPeriod.driver_id ? { driverId: payPeriod.driver_id, start: payPeriod.pickup_time_start, end: payPeriod.pickup_time_end } : { start: payPeriod.pickup_time_start, end: payPeriod.pickup_time_end }}
    >
      {({ loading, error, data, refetch }) => {
        if (loading) {
          return <LinearProgress />;
        }
        if (error) {
          console.log(
            `Failed to retrieve appayments for driver pay priod ${payPeriod.driver_id}-${payPeriod.pay_period}:`,
            error
          );
          return (
            <div className={cls.notFound}>
              <Typography className={cls.notFoundTxt}>ERROR FINDING AP RECORDS</Typography>
            </div>
          );
        }
        return (
          <Box style={{ backgroundColor: 'whiteSmoke' }}>
            {data && data.appayments && data.appayments.length == 0 && (
              <>
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>No records found</Typography>
                </div>
              </>
            )}
            {data && data.appayments && data.appayments.length > 0 && (
              <>
                <Button
                  size='small'
                  style={{ margin: '6px' }}
                  variant='outlined'
                  color='secondary'
                  startIcon={<Icon fontSize='small'>insert_chart</Icon>}
                  onClick={() => {
                    generateCSV(data.appayments);
                  }}
                >
                  Generate CSV
                </Button>
                <Printable landscape>
                  <Box padding={2} style={{ display: 'inline-block' }}>
                    <PayPeriodDetailTableHeader appayments={data.appayments} payPeriod={payPeriod} hideLogoLine />
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align='left'>Move</TableCell>
                            <TableCell align='left'>Driver</TableCell>
                            <TableCell align='left'>Pickup Date</TableCell>
                            <TableCell align='left'>Pickup Time</TableCell>
                            <TableCell align='left'>Type</TableCell>
                            <TableCell align='left'>Description</TableCell>
                            <TableCell align='left'>Distance</TableCell>
                            <TableCell align='right'>Status</TableCell>
                            <TableCell align='right'>Total Pay</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.appayments.map(appayment => {
                            var {
                              id: apId = 0,
                              move = {},
                              accessorial = {},
                              type: apType = '',
                              status: apStatus = '',
                              notes: apNotes = '',
                              amount: apAmount = 0
                            } = appayment || {};
                            // prevents nulls from creeping through
                            appayment = { apId: apId ? apId : 0, move: move ? move : {}, accessorial: accessorial ? accessorial : {}, apType: apType ? apType : '',
                              apStatus: apStatus ? apStatus : '', apNotes: apNotes ? apNotes : '', apAmount: apAmount ? apAmount : 0
                            };
                            var {
                              code: apCode = '',
                              notes: accessorialNotes = ''
                            } = accessorial || {};
                            accessorial = { apCode: apCode ? apCode : '', accessorialNotes: accessorialNotes ? accessorialNotes : '' };
                            var {
                              id: moveId = 0,
                              lane= {},
                              pickup_time= '',
                              move_type= '',
                              driver_name= ''
                            } = move || {};
                            move = { moveId: moveId ? moveId : 0, lane: lane ? lane : {}, pickup_time: pickup_time ? pickup_time : '',
                              move_type: move_type ? move_type : '', driver_name: driver_name ? driver_name : '' 
                            };
                            var {
                              description: laneDescription = '',
                              distance_miles= ''
                            } = lane || {};
                            lane = { laneDescription: laneDescription ? laneDescription : '', distance_miles: distance_miles ? distance_miles : '' };

                            if (apAmount > 0) return ( // hide 0 dollar ap items
                              <React.Fragment key={`appayment-${apId}-${moveId}-row`}>
                                <TableRow key={`table-row-${apId}`}>
                                  <TableCell align='left'>{moveId}</TableCell>
                                  <TableCell align='left'>{driver_name}</TableCell>
                                  <TableCell align='left'>
                                    {moment(pickup_time).format('MM/DD/YYYY')}
                                  </TableCell>
                                  <TableCell align='left'>
                                    {moment(pickup_time).format('LT')}
                                  </TableCell>
                                  <TableCell align='left'>
                                    <Chip
                                      label={
                                        apType === 'move pay' 
                                          ? move_type
                                          : apType === 'accessorial'
                                          ? `adjustment`
                                          : apNotes
                                      }
                                      variant={apType === 'move pay' ? 'default' : 'outlined'}
                                      icon={
                                        <Icon fontSize='small'>
                                          {apType === 'move pay' && move_type
                                            ? move_type === 'drive'
                                              ? 'drive_eta'
                                              : 'local_taxi'
                                            : 'monetization_on'}
                                        </Icon>
                                      }
                                      size='small'
                                      color={
                                        apType === 'move pay' && move_type === 'drive'
                                          ? 'primary'
                                          : 'secondary'
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align='left'>
                                    {apType === 'move pay'
                                      ? laneDescription
                                      : apType === 'accessorial' && apCode && accessorialNotes
                                      ? `(${apCode}) ${accessorialNotes}`
                                      : apNotes}
                                  </TableCell>
                                  <TableCell align='right'>
                                    {apType === 'move pay'
                                    ? distance_miles + ' mi' : null}
                                  </TableCell>
                                  <TableCell align='right'>
                                    <Chip
                                      variant={apStatus === 'paid' ? 'default' : 'outlined'}
                                      icon={
                                        <Icon fontSize='small'>
                                          {apStatus === 'paid' ? 'done' : 'monetization_on'}
                                        </Icon>
                                      }
                                      size='small'
                                      color={apStatus === 'unpaid' ? 'primary' : 'secondary'}
                                      label={apStatus}
                                    />
                                  </TableCell>
                                  <TableCell align='right'>${apAmount ? apAmount.toFixed(2) : ''}</TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Printable>
              </>
            )}
          </Box>
        );
      }}
    </Query>
  );
}

const useStyles = makeStyles(theme => ({
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));
