import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { GlobalContext } from '../../global-context';
import { toast } from 'react-toastify';

import { makeStyles, Container, Grid, Typography, Button } from '@material-ui/core';
import Divide from '../reusable/Divide';

import gql from 'graphql-tag';
import fragments from '../utils/graphQL/fragments';

import Loading from '../utils/Loading';
import TypeForm from './movePlanner/TypeForm';
import LaneForm from './movePlanner/LaneForm';
import CustomerSelect from '../reusable/CustomerSelect';
import LocationSelect from './movePlanner/LocationSelect';
import DatetimeSelect from './movePlanner/DatetimeSelect';
import ConsumerInfoForm from './movePlanner/ConsumerInfoForm';
import NotesInput from './movePlanner/NotesInput';
import SequenceButtonGroup from './movePlanner/SequenceButtonGroup';
import VehicleForm from './movePlanner/VehicleForm';
import ConsumerLocButtonGroup from './movePlanner/ConsumerLocButtonGroup';
import DealerContactInput from './movePlanner/DealerContactInput';

const log = false;

const getStartTime = () => {
  let start = moment().startOf(`minute`).add(90, `minutes`);
  const addStep = 10 - (start.minutes() % 10);
  start = start.add(addStep, `minutes`);
  return moment.utc(start);
};

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
export default function MovePlanner(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  // We need to add consumer pickup boolean to the move record
  // We need to add consumer name and phone to the move record
  // How to calc the pickup time of consumer appt (sequence === 0)
  //   let total_move_time = duration_sec + pickup_inspection_sec + delivery_inspection_sec
  //   consumer pickup pickup time = move.sequence[1].pickup_time - total_move_time
  // How to calc the pickup time of subsequent moves (sequence > 0)
  //   take the pickup_time of move[0].pickup_time and add total_move_time of
  //   each subsequent move to that move's pickup_time
  // let total_move_time = 0
  // moves.forEach(move => {
  //   if (move.sequence == 0) continue
  //   total_move_time += move.lane.duration_sec + move.lane.pickup_inspection_sec + move.lane.delivery_inspection_sec
  //   move.pickup_time = move[0].pickup_time + total_move_time
  // });
  // Temp ideation on object state for form

  // State
  const [customerID, setCustomerID] = useState(props && props.location && props.location.state && props.location.state.move && props.location.state.move.customer_id ? props.location.state.move.customer_id : 0)
  const [type, setType] = useState('concierge');
  const [now, setNow] = useState(moment());
  const [moves, setMoves] = useState([
    {
      index: 0,
      pickupTime: getStartTime(),
      consumerPickup: true,
      validation: {
        pickup: true,
        delivery: true,
        ptime: true,
        pdate: true,
        stock: true,
        vin: true,
        make: true,
        model: true,
        year: true,
        color: true,
        cname: true,
        cnumber: true,
      },
    },
    {
      index: 1,
      pickupTime: getStartTime(),
      consumerPickup: false,
      validation: {
        pickup: true,
        delivery: true,
        ptime: true,
        pdate: true,
        stock: true,
        vin: true,
        make: true,
        model: true,
        year: true,
        color: true,
        cname: true,
        cnumber: true,
      },
    },
  ]);
  const [moveCount, setMoveCount] = useState(1);
  const [primaryLane, setPrimaryLane] = useState(null);
  const [sequenceA, setSequenceA] = useState(1); // When sequenceA = 0, move in sequence 1 is a loaner vehicle
  const [sequenceB, setSequenceB] = useState(0);

  const [loading, setLoading] = useState(false);

  const validMoveTypes = ['one-way', 'round-trip', 'milk-run', 'concierge', 'loaner'];
  const roundTripMoveTypes = ['round-trip', 'loaner'];
  const oneWayMoveTypes = ['one-way', 'concierge'];

  const [consumerLocation, setConsumerLocation] = useState('pickup');

  // Check for props passed in via method from outside of default router
  useEffect(() => {
    if (props && props.location && props.location.state && props.location.state.move) {
      if (props.location.state.inverse) handlePreload(props.location.state.move, true);
      else handlePreload(props.location.state.move);
    }
  }, []);

  // Extra useEffect to force times to update upon changing types
  useEffect(() => {
    updatePickupTime();
  }, [type]);

  /**
   * Determines what move type to assign to a move or pair of moves based on the move object structure, then preloads the page with the appropriate vehicle and lane info for the inverse of the move.
   * @param {Object} move The move object passed in as a prop from the move details page
   * @returns {Function} Executes a series of functions to configure the move planner for the inverse of the provided move (or pair of moves)
   */
  async function handlePreload(move, inverse = false) {
    // Init temporary variables
    let type,
      moveQty,
      move1 = {},
      move2 = {},
      inverseLane = false,
      inverseLoaner = true,
      consumerAtPickup = true;
    // Function to create the object structure utilized by the MovePlanner component
    function handleVehicleObj(move, objProp = false) {
      try {
        return {
          refNum: objProp ? move[objProp].reference_num : move.reference_num,
          manual: objProp ? move[objProp].manual_flag : move.manual_flag,
          stock: objProp ? { vehicle_stock: move[objProp].vehicle_stock } : { vehicle_stock: move.vehicle_stock },
          vin: objProp ? { vehicle_vin: move[objProp].vehicle_vin } : { vehicle_vin: move.vehicle_vin },
          make: objProp ? { name: move[objProp].vehicle_make } : { name: move.vehicle_make },
          model: objProp ? { name: move[objProp].vehicle_model } : { name: move.vehicle_model },
          year: objProp ? move[objProp].vehicle_year : move.vehicle_year,
          color: objProp ? move[objProp].vehicle_color : move.vehicle_color,
        };
      } catch (err) {
        toast.warning(`Could not preload fields, please enter details manually.`);
        return {};
      }
    };
    // Try to determine the type of move(s) and set appropriate vehicle info
    try {
      if (!move.consumer_name && !move.consumer_phone) {
        // Move does not have a consumer associated
        if (!move.parent_move && !move.moveByReturnRideId) {
          // Single move with no return ride associated
          type = 'one-way';
          moveQty = 1;
          move1.vehicle = handleVehicleObj(move);
        } else if (move.parent_move) {
          if (move.parent_move.move_type === 'drive') {
            // Round trip move that was the second in a sequence of 2
            type = 'round-trip';
            moveQty = 2;
            move1.vehicle = handleVehicleObj(move, 'parent_move');
            move2.vehicle = handleVehicleObj(move);
          } else {
            // Single move with no return ride associated
            type = 'one-way';
            moveQty = 1;
            move1.vehicle = handleVehicleObj(move);
          }
        } else if (move.moveByReturnRideId) {
          if (move.moveByReturnRideId.move_type === 'drive') {
            // Round trip move that was the first in a sequence of 2
            type = 'round-trip';
            moveQty = 2;
            move1.vehicle = handleVehicleObj(move, 'moveByReturnRideId');
            move2.vehicle = handleVehicleObj(move);
          } else {
            // Single move with no return ride associated
            type = 'one-way';
            moveQty = 1;
            move1.vehicle = handleVehicleObj(move);
          }
        }
      } else {
        // Move has a consumer associated
        if (
          (move.consumer_type === 'loaner' || (move.tags && move.tags.includes('loaner'))) &&
          move.moveByReturnRideId &&
          move.moveByReturnRideId.move_type === 'drive'
        ) {
          // Loaner-first C+L move that was the first in a sequence of 2
          type = 'loaner';
          moveQty = 2;
          move1.vehicle = handleVehicleObj(move);
          move2.vehicle = handleVehicleObj(move, 'moveByReturnRideId');
          move2.consumer = { name: move.consumer_name, phone: move.consumer_phone };
          inverseLoaner = false;
        } else if (
          (move.consumer_type === 'loaner' || (move.tags && move.tags.includes('loaner'))) &&
          move.parent_move &&
          move.parent_move.move_type === 'drive'
        ) {
          // Loaner-first C+L move that was the second in a sequence of 2
          type = 'loaner';
          moveQty = 2;
          move1.vehicle = handleVehicleObj(move, 'parent_move');
          move2.vehicle = handleVehicleObj(move);
          move2.consumer = { name: move.consumer_name, phone: move.consumer_phone };
          inverseLane = true;
        } else if (
          move.consumer_type === 'customer' &&
          move.moveByReturnRideId &&
          move.moveByReturnRideId.move_type === 'drive'
        ) {
          // Customer-first C+L move that was the first in a sequence of 2
          type = 'loaner';
          moveQty = 2;
          move1.vehicle = handleVehicleObj(move);
          move2.vehicle = handleVehicleObj(move, 'moveByReturnRideId');
          move2.consumer = { name: move.consumer_name, phone: move.consumer_phone };
        } else if (move.consumer_type === 'customer' && move.parent_move && move.parent_move.move_type === 'drive') {
          // Customer-first C+L move that was the second in a sequence of 2
          type = 'loaner';
          moveQty = 2;
          move1.vehicle = handleVehicleObj(move, 'parent_move');
          move2.vehicle = handleVehicleObj(move);
          move2.consumer = { name: move.consumer_name, phone: move.consumer_phone };
          inverseLane = true;
          inverseLoaner = false;
        } else if (move.consumer_type === 'customer' && move.consumer_at_pickup) {
          type = 'concierge';
          moveQty = 1;
          move1.vehicle = handleVehicleObj(move);
          move1.consumer = { name: move.consumer_name, phone: move.consumer_phone };
          consumerAtPickup = false;
          inverseLoaner = false;
        } else if (move.consumer_type === 'customer' && !move.consumer_at_pickup) {
          type = 'concierge';
          moveQty = 1;
          move1.vehicle = handleVehicleObj(move);
          move1.consumer = { name: move.consumer_name, phone: move.consumer_phone };
          inverseLoaner = false;
        } else if (move.consumer_type === 'loaner' && move.consumer_at_pickup) {
          type = 'concierge';
          moveQty = 1;
          move1.vehicle = handleVehicleObj(move);
          move1.consumer = { name: move.consumer_name, phone: move.consumer_phone };
          consumerAtPickup = false;
        } else if (move.consumer_type === 'loaner' && !move.consumer_at_pickup) {
          type = 'concierge';
          moveQty = 1;
          move1.vehicle = handleVehicleObj(move);
          move1.consumer = { name: move.consumer_name, phone: move.consumer_phone };
        }
      }
    } catch (err) {
      toast.warning('Could not preload fields, please enter details manually.');
      console.error(`Failed to preload fields for ${inverse ? 'inverse' : 'duplicate'} move:`, err);
    }
    handleTypeChange(type, moveQty, inverseLoaner);
    if (!consumerAtPickup) setConsumerLocation('delivery');
    setMoves([Object.assign([...moves][0], move1), Object.assign([...moves][1], move2)]);
    try {
      const foundLane = inverse ? await getLaneByLocationIds(move.lane.delivery.id, move.lane.pickup.id, inverseLane) : await getLaneByLocationIds(move.lane.pickup.id, move.lane.delivery.id);
      if (foundLane) handleLaneChange(foundLane);
      else toast.warning('Could not set lane, please enter details manually.');
    } catch (err) {
      console.error(`Failed to set lane for ${inverse ? 'inverse' : 'duplicate'} move:`, err);
      toast.warning('Could not set lane, please enter details manually.');
    }
  }

  const getLaneByLocationIds = async (pickupId, deliveryId, inverseLane = false) => {
    try {
      const pickup = inverseLane ? deliveryId : pickupId;
      const delivery = inverseLane ? pickupId : deliveryId;
      const res = await ctx.apolloClient.query({
        query: GET_LANES_BY_LOCATIONS,
        variables: { pickupId: pickup, deliveryId: delivery },
        fetchPolicy: 'network-only'
      });
      if (res.data && res.data.lanes.length > 0) {
        log && console.log(`Found lane:`, res.data.lanes[0]);
        return res.data.lanes[0];
      }
    } catch (err) {
      log && console.log(`Failed to find lane:`, err);
      toast.error(`Failed to find lane: ${err.toString()}`);
    }
  };

  function resetValidation() {
    let _moves = [...moves];
    for (let move of _moves) {
      move.validation = {
        pickup: true,
        delivery: true,
        ptime: true,
        pdate: true,
        stock: true,
        vin: true,
        make: true,
        model: true,
        year: true,
        color: true,
        cname: true,
        cnumber: true,
      };
    }
    setMoves(_moves);
  }

  function handleValidation(index, field, value) {
    let _moves = [...moves];
    _moves[index].validation[field] = value;
    setMoves(_moves);
  }

  const handleTypeChange = (type, count, inverseLoaner = false) => {
    setType(type);
    setMoveCount(count);

    if (type === 'one-way' || type === 'round-trip') {
      moves[0].consumerPickup = false;
      moves[1].consumerPickup = false;
    }

    if (type === 'concierge') {
      moves[0].consumerPickup = true;
      moves[1].consumerPickup = false;
      // if (!inverseLoaner) {
      //   setSequenceA(1);
      //   setSequenceB(0);
      // } else {
      //   setSequenceA(0);
      //   setSequenceB(1);
      // }
    }

    if (type === 'loaner') {
      moves[0].consumerPickup = false;
      moves[1].consumerPickup = true;
      if (!inverseLoaner) {
        setSequenceA(0);
        setSequenceB(1);
      } else {
        setSequenceA(1);
        setSequenceB(0);
      }
      updatePickupTime('loaner'); // Forcing pickup times to update in case lane was set from a different type, causing both moves to have the same pickup time 90 minutes from current time
    }
  };

  const clearForm = () => {
    setMoves([
      {
        index: 0,
        pickupTime: getStartTime(),
        consumerPickup: true,
      },
      {
        index: 1,
        pickupTime: getStartTime(),
        consumerPickup: false,
      },
    ]);
  };

  // Function that detects state changes and applies them to the moves array
  const handleFormChange = (name, index) => data => {
    moves[index][name] = data;
  };

  // Called when the allowed datetime is changed
  const handleTimeChange = index => time => {
    log && console.log(`Time changed for move[${index}] to ${time.format()}`);

    moves[index].pickupTime = time.clone();
    updatePickupTime();
  };

  // Auto-calculate the opposite pickupTime
  const updatePickupTime = (forceAs = null) => {
    // Added an optional forceAs param to enable running the func before the setType func has resolved
    let currentType = type;
    if (forceAs) {
      currentType = forceAs;
    }
    switch (currentType) {
      case 'round-trip':
        log && console.log('Setting time for round trip move');
        //For round trips, we set the first move and calc the second
        if (moves && moves.length > 0) {
          if (moves[0].lane) {
            const duration = moves[0].lane.duration_sec;
            //Set the pickup time for the second move based on the total
            // duration to get to the second from the first including
            // inspection time
            moves[1].pickupTime = moves[0].pickupTime.clone().add(duration, `seconds`);
            log &&
              console.log(
                `Set time for move[1] to (${moves[0].pickupTime.format()} + ${
                  duration / 60 / 60
                } hours) = ${moves[1].pickupTime.format()}`
              );
          } else {
            log && console.log('Cannot set time because lane is not known yet');
          }
        } else {
          log && console.log('Cannot set time because moves array has no entries');
        }
        break;
      case 'loaner':
        log && console.log('Setting time for concierge+loaner move');
        //For loaners, we set the second move and calc the first
        if (moves && moves.length > 0) {
          if (moves[0].lane) {
            const duration = moves[0].lane.duration_sec; // Length of the first move
            // If the auto-calculated time will push the first move to be within 90 minutes of the current time (or in the past), adjust the second move instead
            if (moment(moves[1].pickupTime.clone().subtract(duration, `seconds`)).diff(moment(), 'minutes') < 90) {
              moves[1].pickupTime = moves[0].pickupTime.clone().add(duration, `seconds`);
              log &&
                console.log(
                  `Auto-calc would have pushed move[0] into the past. Instead, set time for move[1] to (${moves[0].pickupTime.format()} + ${
                    duration / 60 / 60
                  } hours) = ${moves[1].pickupTime.format()}`
                );
            } else {
              // duration to get there from the first including inspection time
              moves[0].pickupTime = moves[1].pickupTime.clone().subtract(duration, `seconds`);
              log &&
                console.log(
                  `Set time for move[0] to (${moves[1].pickupTime.format()} - ${
                    duration / 60 / 60
                  } hours) = ${moves[0].pickupTime.format()}`
                );
            }
          } else {
            log && console.log('Cannot set time because lane is not known yet');
          }
        } else {
          log && console.log('Cannot set time because moves array has no entries');
        }
        break;
    }

    setNow(moment()); //Force the form to rerender
  };

  const handleLocationChange = moveIndex => async location => {
    if (moveIndex < 0) return;
    if (!location) return;
    await updateAllMoveLocationsAndLanes(moveIndex, location);
  };

  const updateAllMoveLocationsAndLanes = async (moveIndex, location) => {
    switch (moveIndex) {
      //First move pickup location changed
      case 0:
        //Update the lane object in the first move
        log && console.log('updateAllMoveLocationsAndLanes clearing pickup', location);
        moves[0].pickup = location;
        await updateMoveLane(moves[0]);

        //Update the primary lane with the lane of the first move
        setPrimaryLane(moves[0].lane);

        if (roundTripMoveTypes.includes(type)) {
          //We can assume the second move is returning back here because its a round trip
          moves[1].delivery = location;
          await updateMoveLane(moves[1]);

          //Handle updating the inverse lane if the form type is a variation of a round trip and
          // we successfully found a lane for the first move
          await setMoveLaneInverse(moves[1], moves[0]);
        }

        break;

      //Second move pickup location changed
      case 1:
        //Update the pickup location of the second move
        moves[1].pickup = location;
        await updateMoveLane(moves[1]);

        //Update the delivery of the first move to match
        moves[0].delivery = location;
        await updateMoveLane(moves[0]);

        //Update the primary lane with the lane of the first move
        setPrimaryLane(moves[0].lane);

        //Handle updating the inverse lane if the form type is a variation of a round trip and
        // we successfully found a lane for the second move
        if (roundTripMoveTypes.includes(type)) {
          await setMoveLaneInverse(moves[0], moves[1]);
        }
        break;

      default:
        break;
    }
  };

  const setMoveLaneInverse = async (moveToChange, referenceMove) => {
    if (!referenceMove.pickup) {
      log && console.log(`setMoveLaneInverse: no pickup passed`);
      return;
    }
    if (!referenceMove.delivery) {
      log && console.log(`setMoveLaneInverse: no delivery passed`);
      return;
    }

    //Update the lane object in the second move to the inverse
    moveToChange.pickup = referenceMove.delivery;
    moveToChange.delivery = referenceMove.pickup;
    await updateMoveLane(moveToChange);

    log && console.log(`set inverse lane: `, moveToChange.lane);
  };

  const updateMoveLane = async moveToChange => {
    if (!moveToChange.pickup) {
      //The pickup has been cleared so there cannot be a lane
      log && console.log(`updateMoveLane: pickup removed, lane set to null`);
      moveToChange.pickup = null;
      moveToChange.lane = null;
    }

    if (!moveToChange.delivery) {
      //The delivery has been cleared so there cannot be a lane
      log && console.log(`updateMoveLane: delivery removed, lane set to null`);
      moveToChange.delivery = null;
      moveToChange.lane = null;
    }

    if (moveToChange.pickup && moveToChange.delivery) {
      if (moveToChange.pickup.id === moveToChange.delivery.id) {
        log && console.log(`updateMoveLane: pickup and delivery same, lane set to null`);
        moveToChange.lane = null;
        return;
      }
    }

    //Update the lane object in the second move to the inverse
    moveToChange.lane = await getOrBuildLaneByLocations(moveToChange.pickup, moveToChange.delivery);
    log && console.log(`Updated move ${moveToChange.index} lane: `, moveToChange.lane);

    updatePickupTime();
  };

  // Finds the corresponding lane when two locations are selected
  // If no lane is found, a new lane is created along with a reverse
  // lane (not created if it already exists)
  const getOrBuildLaneByLocations = async (pickup, delivery) => {
    if (!pickup) {
      log && console.log(`getOrBuildLaneByLocations: no pickup passed`);
      return;
    }
    if (!delivery) {
      log && console.log(`getOrBuildLaneByLocations: no delivery passed`);
      return;
    }
    if (!pickup.id) {
      log && console.log(`getOrBuildLaneByLocations: pickup missing id prop`);
      return;
    }
    if (!delivery.id) {
      log && console.log(`getOrBuildLaneByLocations: delivery missing id prop`);
      return;
    }
    if (pickup.id === delivery.id) {
      log && console.log(`getOrBuildLaneByLocations: pickup and delivery same`);
      return;
    }

    let lane = null;
    try {
      const res = await ctx.apolloClient.query({
        query: GET_LANES_BY_LOCATIONS,
        variables: { pickupId: pickup.id, deliveryId: delivery.id },
        fetchPolicy: 'network-only'
      });
      if (res.data && res.data.lanes.length > 0) {
        lane = res.data.lanes[0];
      } else {
        log &&
          console.log(
            `GET_LANES_BY_LOCATIONS didn't return a lane for pickup ${pickup.id} to delivery ${delivery.id}`,
            res
          );
      }
    } catch (e) {
      log && console.error(`GET_LANES_BY_LOCATIONS threw an error:`, e);
    }

    //If we didn't find a lane, then lets build one
    if (!lane) { lane = buildLanePair(pickup, delivery) }

    return lane;
  };

  const buildLanePair = async (pickup, delivery) => {
    //Only call this if you have first verified that the lane doesn't exist
    if (!pickup) return;
    if (!delivery) return;
    if (!pickup.id) return;
    if (!delivery.id) return;
    if (pickup.id === delivery.id) return;

    log && console.log(`Building lane pair for customer #${customerID} using:`, pickup.name, delivery.name);

    let builtLanes = [];
    try {
      // Netlify function that creates a lane
      const netRes = await axios({
        method: 'POST',
        url: '/.netlify/functions/build-lanes',
        data: {
          type: 'fromLocations',
          customer_id: customerID,
          pickup: { name: pickup.name, address: pickup.address, id: pickup.id },
          delivery: { name: delivery.name, address: delivery.address, id: delivery.id },
        },
      });
      builtLanes = netRes.data;
    } catch (err) {
      log && console.log('Failed to build lane objects', err);
      return null;
    }

    if (builtLanes.length == 0) return null;
    let laneA = null;
    let laneB = null;

    try {
      //Make sure lane is a lane before inserting into database
      if (!builtLanes[0].customer_id || !builtLanes[0].origin_location_id || !builtLanes[0].destination_location_id) {
        return null;
      }
      // Inserting the first of two lanes
      log && console.log(`Inserting lane A ${builtLanes[0].description} into database`, builtLanes[0]);
      const res = await ctx.apolloClient.mutate({
        mutation: INSERT_LANES,
        variables: { laneObjects: builtLanes[0] },
      });

      if (res.data && res.data.insert_lanes.returning) {
        laneA = res.data.insert_lanes.returning[0];
      } else {
        log && console.log('Insert of lane A returned 0 affected rows', res);
      }
    } catch (err) {
      log && console.log('Insert of lane A threw an error', err);
    }

    try {
      //Make sure lane is a lane before inserting into database
      if (!builtLanes[1].customer_id || !builtLanes[1].origin_location_id || !builtLanes[1].destination_location_id) {
        return null;
      }
      // Inserting the second of two lanes
      log && console.log(`Inserting lane B ${builtLanes[1].description} into database`, builtLanes[1]);
      const res = await ctx.apolloClient.mutate({
        mutation: INSERT_LANES,
        variables: { laneObjects: builtLanes[1] },
      });

      if (res.data && res.data.insert_lanes.returning) {
        laneB = res.data.insert_lanes.returning[0];
      } else {
        log && console.log('Insert of lane B returned 0 affected rows', res);
      }
    } catch (err) {
      log && console.log('Insert of lane B threw an error', err);
    }

    return laneA;
  };

  const handleLaneChange = async lane => {
    log && console.log('Handle lane change: ', lane);
    if (!lane) return;

    const inboundPickupId = lane && lane.pickup && lane.pickup.id ? lane.pickup.id : 0;
    const inboundDeliveryId = lane && lane.delivery && lane.delivery.id ? lane.delivery.id : 0;
    const movePickupId = moves[0] && moves[0].pickup && moves[0].pickup.id ? moves[0].pickup.id : 0;
    const moveDeliveryId = moves[0] && moves[0].delivery && moves[0].delivery.id ? moves[0].delivery.id : 0;

    //Handle the pickup location changing
    if (inboundPickupId != movePickupId) {
      moves[0].pickup = lane.pickup;
      moves[1].delivery = lane.pickup;
      if (!lane.pickup && log) console.log('clearing pickup');
      await updateAllMoveLocationsAndLanes(0, lane.pickup);
    }

    //Handle the delivery location changing
    if (inboundDeliveryId != moveDeliveryId) {
      moves[0].delivery = lane.delivery;
      moves[1].pickup = lane.delivery;
      if (!lane.delivery && log) console.log('clearing delivery');
      await updateAllMoveLocationsAndLanes(1, lane.delivery);
    }
  };

  // Function that handles the vehicle switch on the vehicle form
  const handleSwitchChange = index => labelIndex => {
    if ((index === 0 && labelIndex === 0) || (index === 1 && labelIndex === 1)) {
      setSequenceA(0);
      setSequenceB(1);
    }
    if ((index === 0 && labelIndex === 1) || (index === 1 && labelIndex === 0)) {
      setSequenceA(1);
      setSequenceB(0);
    }
  };

  const getSectionTitle = (index = 0) => {
    if (type === `round-trip`) return `Move ${index + 1}`;
    else if (type === `milk-run`) return `Stop ${index + 1}`;
    else if (type === `concierge`) return `Pickup Location`;
    else if (type === `loaner` && index === 0) return `Service Location`;
    else if (type === `loaner` && index === 1) return `Customer Location`;
    else return `Move`;
  };

  const getSectionTip = (index = 0) => {
    if (type === ``) return ``;
    else return `Fill out the form for the vehicle at this location.`;
  };

  const getDatetimeTip = (index = 0) => {
    if ((type === `one-way` || type === `round-trip` || type === `milk-run`) && index === 0)
      return `The pickup time allows you to set a time when the vehicle should be picked up. Note that the pickup time is not exact and may differ from 5-10 minutes.`;
    else if (type === `round-trip` && index === 1)
      return `This pickup time is auto-calculated based on the first move's pickup time.`;
    else if (type === `milk-run` && index > 0)
      return `This pickup time is auto-calculated based on the previous stop's pickup time.`;
    else if (type === `concierge` || (type === `loaner` && index === 1))
      return `The pickup time for the customer's location should be based on the appointment time scheduled with your customer. Note that the pickup time is not exact and may differ from 5-10 minutes.`;
    else if (type === `loaner` && index === 0)
      return `This pickup time is auto-calculated based on the customer appointment time. Please adjust the scheduled appointment below to ensure this time is at least 90 minutes from the current time.`;
    else return null;
  };

  const disableTimeSelect = (index = 0) => {
    if (type !== `loaner` && index === 0) return false;
    else if (type === `loaner` && index === 1) return false;
    else return true;
  };

  const disableCustomerNotes = (index = 0) => {
    if ((type === `concierge` && index === 0) || (type === `loaner` && index === 1)) return false;
    else return true;
  };

  const disableSwitch = (index = 0) => {
    if (type === `loaner`) return false;
    else return true;
  };

  function validateForm() {
    resetValidation();
    try {
      const _moves = [...moves];
      _moves.map((move, i) => {
        if ((type === 'one-way' || type === 'concierge') && i > 0) return;
        if (!move.pickup || move.pickup === null) handleValidation(i, 'pickup', false);
        if (!move.delivery || move.delivery === null) handleValidation(i, 'delivery', false);
        if (!move.vehicle.vin || move.vehicle.vin === null) handleValidation(i, 'vin', false);
        if (!move.vehicle.make || move.vehicle.make === null) handleValidation(i, 'make', false);
        if (!move.vehicle.model || move.vehicle.model === null) handleValidation(i, 'model', false);
        if (!move.vehicle.year || move.vehicle.year === null) handleValidation(i, 'year', false);
        if (move.pickupTime < moment()) handleValidation(i, 'ptime', false);
        if (type === 'concierge' && i === 0) {
          if (!move.consumer.name || move.consumer.name.trim().length < 1) handleValidation(i, 'cname', false);
          if (!move.consumer.phone || move.consumer.phone.trim().length < 1) handleValidation(i, 'cnumber', false);
        }
        if (type === 'loaner' && i === 1) {
          if (!move.consumer.name || move.consumer.name.trim().length < 1) handleValidation(i, 'cname', false);
          if (!move.consumer.phone || move.consumer.phone.trim().length < 1) handleValidation(i, 'cnumber', false);
        }
      });
    } catch (err) {
      log && console.log('Could not validate move form:', err.toString());
    }
  }

  const isFormValid = () => {
    validateForm();
    try {
      //Loop over all moves and check for structural integrity that is
      // required for all moves regardless of type
      for (let index = 0; index < moveCount; index++) {
        if (!isLaneFound(moves[index])) {
          log && console.log(`move #${index} missing lane info`);
          return false;
        }
        if (!isVehicleFound(moves[index])) {
          log && console.log(`move #${index} missing vehicle info`);
          return false;
        }
        if (!moves[index].hasOwnProperty('consumerPickup')) {
          log && console.log(`move #${index} missing consumer pickup flag`);
          return false;
        }
        if (moves[index].pickupTime < moment()) {
          log && console.log(`move #${index} pickup time is in the past`);
          return false;
        }
      }

      //For concierge, the first move must have consumer info
      if (type === 'concierge') {
        if (!isConsumerFound(moves[0])) {
          log && console.log('Concierge move missing consumer');
          return false;
        }
      }

      //For concierge+loaner, the second move must have consumer info
      if (type === 'loaner') {
        if (!isConsumerFound(moves[1])) {
          log && console.log('Loaner move missing consumer');
          return false;
        }
      }
    } catch (error) {
      log && console.log('Form validation crashed', error);
      return false;
    }

    return true;
  };

  const isLaneFound = move => {
    if (!move.hasOwnProperty('lane')) {
      log && console.log(`Move ${move.index} missing lane property`);
      return false;
    }
    if (move.lane == null) {
      log && console.log(`Move ${move.index} lane is null`);
      return false;
    }
    if (Object.keys(move.lane).length === 0) {
      log && console.log(`Move ${move.index} lane has no properties`);
      return false;
    }

    if (move.lane && move.lane.id <= 0) {
      log && console.log(`Move ${move.index} missing lane id`, move.lane);
      return false;
    }
    return true;
  };

  const isVehicleFound = move => {
    if (!move.hasOwnProperty('vehicle')) return false;
    if (move.vehicle == null) return false;
    if (Object.keys(move.vehicle).length === 0) return false;

    if (!move.vehicle.vin) return false;
    if (!move.vehicle.make) return false;
    if (!move.vehicle.model) return false;
    if (!move.vehicle.year) return false;
    return true;
  };

  const isConsumerFound = move => {
    if (!move.hasOwnProperty('consumer')) return false;
    if (move.consumer == null) return false;
    if (Object.keys(move.consumer).length === 0) return false;

    if (!move.consumer.name) return false;
    if (!move.consumer.phone) return false;
    return true;
  };

  const getChargeable = index => {
    if (type === `loaner` && index === 0 && sequenceA === 0) return false;
    else if (type === `loaner` && index === 1 && sequenceB === 0) return false;
    else return true;
  };

  const getTags = index => {
    let tags = ``;

    if (type === `loaner` && index === 0 && sequenceA === 0) tags = `loaner`;
    else if (type === `loaner` && index === 1 && sequenceB === 0) tags = `loaner`;

    return tags;
  };

  const handleFinishPlan = async () => {
    try {
      let valid = await isFormValid();

      if (!valid) {
        toast.error('Please ensure highlighted fields are valid before continuing.');
        log && console.log(`form is invalid`);
        return;
      }

      log && console.log(`form is valid`);

      let insertableMoves = [];
      for (let index = 0; index < moveCount; index++) {
        const insertableMove = buildInsertableMove(moves[index], index);
        if (insertableMove) insertableMoves.push(insertableMove);
      }

      if (insertableMoves.length > 0) {
        setLoading(true);
        toast.info(`Please wait, your move(s) are being created...`);
        log && console.log(`Moves to insert:`, insertableMoves);
        const insertedMove = await insertMoves(insertableMoves);

        if (insertedMove) {
          clearForm();
          props.history.push({
            pathname: '/',
            state: { insertedMove: insertedMove },
          });
        }
      } else {
        log && console.log('There are no insertable moves to insert. Did they fail to generate?');
      }
    } catch (error) {
      log && console.log('handleFinishPlan crashed', error);
    }

    setLoading(false);
  };

  const buildInsertableMove = (move, index) => {
    log && console.log(`Building insertable move`, move);
    // Set consumer info for concierge & concierge + loaner
    let consumerName = null;
    let consumerPhone = null;
    let consumerAtPickup = 0;
    let consumerType = null;
    // =============== Init these to their default conditionals, then change as needed below ================ //
    let classOverride = roundTripMoveTypes.includes(type) ? 1 : 0;
    let moveClass = roundTripMoveTypes.includes(type) && move.consumerPickup === false ? 'base' : 'stranded';
    // ====================================================================================================== //
    if (type === `loaner` && index === 0) {
      consumerName = moves[1].consumer.name;
      consumerPhone = moves[1].consumer.phone;
    } else if (move.consumerPickup) {
      consumerName = move.consumer.name;
      consumerPhone = move.consumer.phone;
    } else {
      consumerName = null;
      consumerPhone = null;
    }
    if (index === 0 && type === 'concierge') {
      consumerLocation === 'pickup' ? (consumerAtPickup = 1) : (consumerAtPickup = 0);
      sequenceA === 0 ? (consumerType = 'loaner') : (consumerType = 'customer'); // sequenceA = 0 is when the first move is a loaner
    }
    if (index === 0 && type === 'loaner') {
      consumerAtPickup = 0; // consumer is always the second move (delivery)
      sequenceA === 0 ? (consumerType = 'loaner') : (consumerType = 'customer'); // sequenceA = 0 is when the first move is a loaner
      moveClass = 'stranded'; // Need concierge + loaner moves to always be stranded
      classOverride = 1; // Need concierge + loaner moves to always be stranded
    }
    if (index === 1 && type === 'loaner') {
      consumerAtPickup = 0; // consumer is always the second move (delivery)
      sequenceB === 0 ? (consumerType = 'loaner') : (consumerType = 'customer'); // sequenceB = 0 is when the second move is a loaner
      moveClass = 'stranded'; // Need concierge + loaner moves to always be stranded
      classOverride = 1; // Need concierge + loaner moves to always be stranded
    }

    // Create a move that can be inserted with a GQL mutation from
    // the data collected into adhoc move objects from the form
    try {
      return {
        customer_id: customerID,
        ready_by: move.pickupTime ? move.pickupTime.format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null,
        consumer_name: consumerName,
        consumer_phone: consumerPhone,
        consumer_at_pickup: consumerAtPickup,
        consumer_type: consumerType,
        move_details: move.notes ? move.notes : null,
        reference_num: move.vehicle && move.vehicle.refNum ? move.vehicle.refNum.trim() : null,
        manual_flag: move.vehicle && move.vehicle.manual ? move.vehicle.manual : false,
        vehicle_stock:
          move.vehicle && move.vehicle.stock
            ? move.vehicle.stock.vehicle_stock.trim()
            : consumerType && consumerType === 'customer'
            ? 'consumer'
            : null,
        vehicle_vin: move.vehicle && move.vehicle.vin ? move.vehicle.vin.vehicle_vin.toUpperCase().trim() : null,
        vehicle_make: move.vehicle && move.vehicle.make ? move.vehicle.make.name.trim() : null,
        vehicle_model: move.vehicle && move.vehicle.model ? move.vehicle.model.name.trim() : null,
        vehicle_year: move.vehicle && move.vehicle.year ? move.vehicle.year.trim() : null,
        vehicle_color: move.vehicle && move.vehicle.color ? move.vehicle.color.trim() : null,
        consumer_pickup: move.consumerPickup ? move.consumerPickup : false,
        lane_id: move.lane && move.lane.id ? move.lane.id : null,
        rate_class_override: classOverride,
        class: moveClass,
        pickup_time: move.pickupTime.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        chargeable: getChargeable(index),
        tags: getTags(index),
        dealer_contact: move.dealer_contact ? move.dealer_contact : null,
      };
    } catch (error) {
      console.log('Crashed trying to create insertable move', error);
      return null;
    }
  };

  const insertMoves = async movesArray => {
    log && console.log(`Attempting to insert moves array...`, movesArray);
    try {
      const res = await ctx.apolloClient.mutate({
        mutation: INSERT_MOVES,
        variables: { movesObjectArray: movesArray },
      });
      if (res.data && res.data.insert_moves.returning.length > 0) {
        log && console.log(`>> INSERT moves array success:`, res.data.insert_moves);
        if (moveCount > 1) {
          try {
            let newMoves = res.data.insert_moves.returning;
            let serviceMove = newMoves.find(o => o.lane_id === primaryLane.id);
            let consumerMove = newMoves.find(o => o.lane_id !== primaryLane.id);
            await updateReturnRideId(consumerMove.id, serviceMove.id);
          } catch (error) {
            log && console.log('failed to set the return ride id', error);
          }
        }
        try {
          // Check if any of the inserted moves are associated with a consumer address
          // If so, update the related location to reflect a type of 'consumer residential'
          let firstMove = res.data.insert_moves.returning[0];
          let consumerMove = res.data.insert_moves.returning.find(o => o.lane_id !== primaryLane.id);
          if (moveCount > 1 && type === 'loaner') {
            // C+L moves will always have a consumer address at the pickup of the consumer move
            if (consumerMove.lane.pickup.type !== 'consumer residential')
              updateLocationType(consumerMove.lane.pickup.id, 'consumer residential');
          } else if (type === 'concierge' && firstMove.consumer_type === 'customer') {
            // Concierge moves will have a consumer address at the pickup if the consumer_at_pickup flag is true
            if (firstMove.consumer_at_pickup === 1) {
              if (firstMove.lane.pickup.type !== 'consumer residential')
                updateLocationType(firstMove.lane.pickup.id, 'consumer residential');
            } else {
              if (firstMove.lane.delivery.type !== 'consumer residential')
                updateLocationType(firstMove.lane.delivery.id, 'consumer residential');
            }
          }
        } catch (error) {
          log && console.log('failed to update consumer location', error);
        }

        toast.success(`Your move(s) have been created. A dispatcher is working to assign a driver.`);
        return true;
      }
    } catch (err) {
      console.error(`Failed to insert move:`, err);
      toast.error( `Failed to create move(s). Please ensure that you've entered all the correct data and resubmit. If the problem persists, please contact your representative.`);
    }
    return false;
  };

  const updateLocationType = async (locationId, type) => {
    try {
      ctx.apolloClient.mutate({
        mutation: UPDATE_LOCATION_TYPE,
        variables: {
          id: locationId,
          type: type,
        },
      });
    } catch (error) {
      console.error("Failed to update the location object's type:", error);
    }
  };

  const updateReturnRideId = async (moveId, returnRideId) => {
    log && console.log(`Attempting to set the return ride id for move #${moveId} to ${returnRideId}`);
    try {
      const res = await ctx.apolloClient.mutate({
        mutation: UPDATE_RETURN_RIDE,
        variables: { moveid: moveId, returnrideid: returnRideId },
      });
      if (res.data && res.data.update_moves.returning.length > 0) {
        log && console.log(`Setting return ride id success:`, res.data.update_moves.affected_rows);
      }
    } catch (err) {
      console.error(`Failed to update move:`, err);
    }
  };

  if (loading) return <Loading fixed />;
  return (
    <>
      <div className={cls.root}>
        {ctx && ctx.userIsAuthenticated() && (
          <Container maxWidth='lg'>
            <Typography className={cls.head}>Move Planner</Typography>
            <Typography className={cls.sub}>
              Welcome to the move planner. This form allows you to set up a plan to move your vehicles. When the pickup
              time arrives, one of our drivers will be ready to move your vehicle(s) for you. Be sure to have someone
              meet our driver at both ends for&nbsp;inspection.
            </Typography>

            <Divide spacer tip='Choose a move type to prepare the form.'>
              Type
            </Divide>
            <TypeForm type={type} onChange={handleTypeChange} />

            <div style={{ display: moveCount ? `block` : `none` }}>
              <Divide
                spacer
                tip='Choose a customer to create move plan for.'
              >
                Customer
              </Divide>
              <div className={cls.customerSelect}>
                <div className={cls.paperWhite}>
                  <CustomerSelect
                    required
                    margin='normal'
                    value={customerID}
                    onChange={event => setCustomerID(event.target.value)}
                  />
                </div>
              </div>
            </div>

            <div style={{ display: moveCount ? `block` : `none` }}>

              <Divide
                spacer
                tip='Choose an origin and destination to create a lane between the two. If the location is not in our system, you may choose a Google-suggested address and create a new location.'
              >
                Lane
              </Divide>
              <LaneForm
                type = {type}
                customerId={customerID}
                validation={moves[0].validation}
                laneData={primaryLane}
                onLaneChange={handleLaneChange}
                getLaneByLocationIds={getLaneByLocationIds}
              />
            </div>

            {moves.map((move, moveIndex) => (
              <div
                key={`move-plan-${moveIndex}`}
                style={{ display: moveIndex < moveCount || moveCount === null ? `block` : `none` }}
              >
                <Divide spacer tip={getSectionTip(moveIndex)}>
                  {getSectionTitle(moveIndex)}
                </Divide>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <div className={cls.paperWhite}>
                      {type === 'concierge' ? (
                        <>
                          <ConsumerLocButtonGroup
                            consumerLocation={consumerLocation}
                            setConsumerLocation={setConsumerLocation}
                            switchLabelA={`Vehicle Is With Customer`}
                            switchLabelB={`Vehicle Is With Dealer`}
                          />
                        </>
                      ) : (
                        <>
                          <LocationSelect
                            customerId={customerID}
                            valid={move.validation && move.validation.pickup ? move.validation.pickup : false}
                            locationData={move.pickup ? move.pickup : null}
                            onChange={handleLocationChange(moveIndex)}
                            label='Pickup Location'
                          />
                        </>
                      )}
                      <div className={cls.break} />
                      <DatetimeSelect
                        validation={move.validation}
                        timeData={move.pickupTime}
                        onChange={handleTimeChange(moveIndex)}
                        tip={getDatetimeTip(moveIndex)}
                        useUtc
                        disabled={disableTimeSelect(moveIndex)}
                        getDefaultStartTime={getStartTime}
                      />
                      <div className={cls.break} />

                      <div style={{ display: !disableCustomerNotes(moveIndex) ? `block` : `none` }}>
                        <ConsumerInfoForm
                          validation={move.validation}
                          consumerData={move.consumer}
                          onChange={handleFormChange(`consumer`, moveIndex)}
                        />
                        <div className={cls.break} />
                      </div>

                      <DealerContactInput onChange={handleFormChange(`dealer_contact`, moveIndex)} />
                      <div className={cls.break} />
                      <NotesInput notesData={move.notes} onChange={handleFormChange(`notes`, moveIndex)} />
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={cls.paperGray}>
                      <div style={{ display: !disableSwitch(moveIndex) ? `block` : `none` }}>
                        <SequenceButtonGroup
                          sequence={moveIndex === 0 ? sequenceA : sequenceB}
                          onChange={handleSwitchChange(moveIndex)}
                          switchLabelA={`Loaner Vehicle`}
                          switchLabelB={`Customer Vehicle`}
                        />
                        <div className={cls.break} />
                      </div>

                      <VehicleForm
                        customerId={customerID}
                        validation={move.validation}
                        vehicleData={move.vehicle}
                        onChange={handleFormChange(`vehicle`, moveIndex)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))}

            <div className={cls.actions}>
              <Button
                className={cls.action}
                variant='outlined'
                color='primary'
                size='large'
                onClick={() => handleFinishPlan()}
              >
                Finish Plan
              </Button>
            </div>
          </Container>
        )}
      </div>
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  paperWhite: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    background: '#fff',
  },
  paperGray: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    background: '#f4f4f4',
  },
  head: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.75),
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.5),
      fontSize: '18px',
    },
  },
  sub: {
    maxWidth: '640px',
    marginBottom: '-16px',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-12px',
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-8px',
      fontSize: '12px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: theme.spacing(4),
  },
  action: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  block: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '56px',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  customerSelect: {
    marginBottom: '-2rem'
  }
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////
const INSERT_LANES = gql`
  mutation insert_lanes($laneObjects: [lanes_insert_input!]!) {
    insert_lanes(objects: $laneObjects) {
      returning {
        id
        description
        favorite
        pickup {
          id
          name
          nickname
          address
          email
          phone
        }
        delivery {
          id
          name
          nickname
          address
          email
          phone
        }
        delivery_inspection_sec
        duration_sec
        pickup_inspection_sec
        return_ride_wait_sec
      }
    }
  }
`;

const GET_LANES_BY_LOCATIONS = gql`
  query get_lanes_by_locations($pickupId: bigint!, $deliveryId: bigint!) {
    lanes(
      where: { origin_location_id: { _eq: $pickupId }, destination_location_id: { _eq: $deliveryId } }
      order_by: [{ favorite: desc }, { description: asc }]
    ) {
      ...Lane
    }
  }
  ${fragments.lane}
`;

const INSERT_MOVES = gql`
  mutation insert_moves($movesObjectArray: [moves_insert_input!]!) {
    insert_moves(objects: $movesObjectArray) {
      returning {
        ...Move
      }
    }
  }
  ${fragments.move}
`;

const UPDATE_RETURN_RIDE = gql`
  mutation update_moves($moveid: bigint!, $returnrideid: bigint!) {
    update_moves(where: { id: { _eq: $moveid } }, _set: { return_ride_id: $returnrideid }) {
      affected_rows
      returning {
        id
        return_ride_id
      }
    }
  }
`;

const UPDATE_LOCATION_TYPE = gql`
  mutation update_location_type($id: bigint!, $type: String!) {
    update_locations(where: { id: { _eq: $id } }, _set: { type: $type }) {
      affected_rows
    }
  }
`;