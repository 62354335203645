//Get all drivers from Tookan
import { GlobalContext } from "../../global-context";
import { ApolloProvider } from "react-apollo";
import gql from "graphql-tag";
import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
// import { Query } from "react-apollo";
// import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Paper } from "@material-ui/core";
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// import axios from 'axios';
import { Query } from "react-apollo";
import * as Sentry from "@sentry/react";
// import moment from "moment";
import Loading from "../utils/Loading";
import DriverScheduleBody from "./driverSchedule/DriverScheduleBody";
import DriverScheduleHeader from "./driverSchedule/DriverScheduleHeader";

const { REACT_APP_ENV } = process.env;
const REACT_APP_TKN_SD = (REACT_APP_ENV === 'test' ? 'dispatch-api-test' : 'dispatch-api-prod');

let log = false;

export default function DriverSchedule(props) {
  const cls = useStyles();
  const ctx = useContext(GlobalContext);

  const [selectedRegionObj, setSelectedRegionObj] = useState(null);

  const [regionDrivers, setRegionDrivers] = useState([]);

  // Return a list of all drivers from Tookan's API
  const getTookanDrivers = () => {
    log && console.log("Retrieving drivers from Tookan API");
    const tookanBaseURL = `https://${REACT_APP_TKN_SD}.socialautotransport.com/V2`;
    log && console.log(process.env);
    axios({
      method: "post",
      url: `${tookanBaseURL}/get_available_agents`,
      data: {
        api_key: ctx.userProfile["https://api_keys.io/jwt/claims"]["TookanKey"],
      },
    }).then((res) => {
      log && console.log("response from getTookanDrivers (res.data):", res.data);
      if (res && res.data) {
        filterDrivers(res.data.data);
      }
    });
  };

  let filterDrivers = (drivers) => {
    let regionName = selectedRegionObj.name;
    let filteredDrivers = drivers.filter((driver) => driver.team_name === regionName);
    let sortedDrivers = filteredDrivers.sort((a, b) => (a.last_updated_timings > b.last_updated_timings ? 1 : -1));
    setRegionDrivers(sortedDrivers);
  };

  useEffect(() => {
    if (selectedRegionObj !== null) {
      getTookanDrivers();
    }
  }, [selectedRegionObj]);

  useEffect(() => {
    log && console.log("SELECTED DRIVERS", regionDrivers);
  }, [regionDrivers]);

  return (
    <Paper id="root" className={cls.root}>
      <Container maxWidth="lg">
        { ctx && ctx.userIsAuthenticated() && (
          <ApolloProvider client={ctx.apolloClient}>
            <DriverScheduleHeader setSelectedRegionObj={setSelectedRegionObj} />
            <div style={{ width: "100%", height: "24px" }} />
            {selectedRegionObj ? (
              <Query
                query={GET_SCHEDULES}
                onError={(error) => {
                  console.error(error);
                  Sentry.captureException(error);
                }}
                fetchPolicy="no-cache"
                notifyOnNetworkStatusChange= {true}
              >
                {({ loading, data, refetch, networkStatus }) => {
                  // if (loading) return <Loading fixed />
                  if (data && data.driverschedules) {
                    let schedules = data.driverschedules;
                    log && console.log("Query schedules",schedules)
                    return (<>
                        <DriverScheduleBody schedules={schedules} drivers={regionDrivers} refetch={refetch} />
                      </>);
                  }else{
                    return(
                      <Container maxWidth="sm">
                      <div className={cls.notFound}>
                        <Typography className={cls.notFoundTxt}>NO SCHEDULES FOUND</Typography>
                      </div>
                    </Container>
                    )
                  }
                }}
              </Query>
            ) : (
              <Container maxWidth="sm">
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>SELECT A REGION</Typography>
                </div>
              </Container>
            )}
          </ApolloProvider>)
        }
      </Container>
    </Paper>
  );
}

DriverSchedule.propTypes = {};

const GET_SCHEDULES = gql`
  query get_schedules {
    driverschedules(where: { expiration_date: { _gte: "now()" } }) {
      id
      driver_id
      type
      expiration_date
      details {
        id
        driver_schedule_id
        day
        block_start_time
        block_end_time
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr auto",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  dropdown: {
    minWidth: 200,
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));
