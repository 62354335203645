import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { List, ListSubheader, Divider, Typography, Box, Grid, Tooltip } from '@material-ui/core';
import ScheduleIcon from "@material-ui/icons/Schedule";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ManualIcon from "@material-ui/icons/NatureTwoTone";
import PriorityIcon from "@material-ui/icons/NewReleases";
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '@material-ui/core/Avatar';
// import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faLyft } from '@fortawesome/free-brands-svg-icons'
// import { faComment } from '@fortawesome/free-solid-svg-icons'
// import TriggeredIcon from '@material-ui/icons/More'
import moment from 'moment';
import { ContextMenuTrigger } from 'react-contextmenu';
import ReactDOM from 'react-dom';

let log = false; //Enable for verbose console logging
// const getPlanStyle = isDraggingOver => ({
//     background: isDraggingOver ? 'rgb(230, 242, 255)' : ''
// })


function collect(props) {
  return {
    move: props.move,
  };
}

const portal = document.createElement('div');
portal.classList.add('draggable-portal');

if (!document.body) {
  throw new Error('body not ready for portal creation!');
}

document.body.appendChild(portal);

const hasLaneWithLocations = move => (move && move.lane && move.lane.pickup && move.lane.delivery ? true : false);

const hasAssociatedRegions = move =>
  hasLaneWithLocations(move) && move.lane.pickup.region_id && move.lane.delivery.region_id ? true : false;

  
const Portal = props => {
    const { provided, snapshot, move, index, moves } = props;
    const usePortal = snapshot.isDragging;
    const duration = move.lane && move.lane.duration_sec ? moment.utc(move.lane.duration_sec * 1000) : null;

    const child = (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        // Injecting custom styling into the inerited object to correct for position offset:
        style={Object.assign(
          { top: '0 !important', backgroundColor: 'white' },
          provided.draggableProps.style,
          usePortal ? { position: 'absolute' } : {}
        )}
        key={move.plan_id + '.' + move.id}
        data-toggle='collapse'
        // onClick={this.showMoveDetails}
        value={move.id}
        aria-expanded='false'
        aria-controls={'unplanned-move-detail-' + move.id}
        id={`unplanned-draggable-move-${move.id}`}
      >
        {/* https://stackoverflow.com/questions/31000885/align-an-element-to-bottom-with-flexbox */}

        {(index == 0 || (index > 0 && moves[index - 1].customer_id !== move.customer_id)) && (
          <ListSubheader component='div' id='nested-list-subheader'>
            {move.lane.customer.name}
          </ListSubheader>
        )}
        <Box
          id={`move-details-flex-box-${move.id}`}
          display='flex'
          justifyContent='space-between'
          flexDirection='column'
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: move.cancel_status ? 'rgba(200, 0, 0, 0.35)' : '',
          }}
          p={2}
          border={1}
          borderTop={0}
          borderLeft={0}
          borderRight={0}
          /*borderBottom={1} // Now need to set border={1} then zero out the sides you don't want*/ borderColor='grey.300'
        >
          <Grid container item direction='row' xs={12}>
            {!hasLaneWithLocations(move) ? (
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography variant='subtitle2' color='error'>
                  ERROR WITH LANE
                </Typography>
              </Grid>
            ) : !hasAssociatedRegions(move) ? (
              <>
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                  <Typography variant='subtitle2' color='error'>
                    REGION MISSING
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                  <Typography variant='subtitle2' color='error'>
                    EDIT RELATED LOCATION
                  </Typography>
                </Grid>
              </>
            ) : null}
            <Grid item xs={4} style={{}}>
              <Tooltip title={move.id} placement='bottom'>
                <Typography variant='caption'>
                  <strong>{move.vehicle_stock}</strong>
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              <Typography variant='caption' color='error' noWrap>
                {moment.duration(moment().diff(moment(move.createdat))).humanize()}
                &nbsp;old
              </Typography>
            </Grid>
          </Grid>
          <Grid container item md={12} prb={12}>
            <Grid item xs={4} style={{ marginTop: '10px' }}>
              <Typography variant='caption' noWrap>
                <strong>{ move.lane.customer.name }</strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid container item md={12} pb={2}>
            <Grid item xs={6}>
              <Typography variant='caption' color='primary' noWrap>
                {move.lane && move.lane.pickup && move.lane.pickup.name ? move.lane.pickup.name : 'No related location'}
              </Typography>
            </Grid>
            {/* <Grid item xs={6} style={{ textAlign: "right" }}>
              <Typography variant="caption" color="primary">
                {move.lane && move.lane.delivery && move.lane.delivery.name
                  ? move.lane.delivery.name
                  : "No related location"}
              </Typography>
            </Grid> */}
          </Grid>
          <Grid container item xs={12} pb={2}>
            <Grid item xs={6}>
              <Tooltip title={`Estimated lane duration`} placement='bottom'>
                <Typography variant='caption' color='secondary' noWrap={true}>
                  { duration ? 
                    ( duration.hours() ? 
                      `${duration.hours()} hours ${duration.minutes()} minutes` 
                      : `${duration.minutes()} minutes` )
                   : null }
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container item xs={12} pb={2}>
            <Grid item xs={6}>
              <Tooltip title={`Do not pickup before this time`} placement='bottom'>
                <Typography variant='caption' color='secondary' noWrap={true}>
                  <em>
                    Ready by&nbsp;
                    {move.ready_by
                      ? `${moment(move.ready_by).format('h:mm A')} on ${moment(move.ready_by).format('MM/DD')}`
                      : ''}
                  </em>
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container item xs={12} pb={2}>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Tooltip title={`Must deliver before this time`} placement='bottom'>
                <Typography variant='caption' color='secondary' noWrap={true}>
                  <em>
                    {move.deliver_by
                      ? `${moment(move.deliver_by).format('h:mm A')} on ${moment(move.deliver_by).format('MM/DD')}`
                      : ''}
                  </em>
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Box width={'100%'} mb={'-8px'}>
            <Grid container item direction='row' xs={12}>
              <Grid item xs={8}>
                <Typography variant='caption'>
                  { move.consumer_name ? (move.consumer_name) : (`${move.vehicle_color} ${move.vehicle_year} ${move.vehicle_make} ${move.vehicle_model}`) }
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                {/* {move.lyft_flag === 1 &&
                            <Tooltip title={`Lyft Trigger`} placement="left">
                                <Fragment>
                                    <FontAwesomeIcon icon={faLyft} inverse mask={faComment} transform='shrink-3' style={{ transform: "scale(1.25)", margin: "2px" }} />
                                </Fragment>
                            </Tooltip>
                        } */}
                {move.priority && move.priority <= 5 && (
                  <Tooltip title={`Priority Level: ${move.priority}`} placement='left'>
                    <PriorityIcon style={{ transform: 'scale(0.65)', margin: '-4px' }} />
                  </Tooltip>
                )}
                {move.consumer_type && move.consumer_type === 'customer' && (
                  // Previously looking at 'consumer_pickup' field but that is currently always set on the 2nd move in a C+L sequence.
                  // This will set the tag based on the actual vehicle type specified by the dealer.
                  <Tooltip
                    title={`Consumer Vehicle - ${move.consumer_name || `Unknown`} @ ${move.consumer_phone || `N/A`}`}
                    placement='left'
                  >
                    <Typography
                      style={{
                        verticalAlign: 'top',
                        display: 'inline-block',
                        maxWidth: 'min-content',
                        margin: '0 4px',
                        lineHeight: 1.195,
                        fontSize: '15px',
                        fontWeight: '900',
                      }}
                    >
                      C
                    </Typography>
                  </Tooltip>
                )}
                {((move.tags && move.tags.includes(`loaner`)) ||
                  (move.consumer_type && move.consumer_type === 'loaner')) && (
                  <Tooltip title={`Loaner Vehicle`} placement='left'>
                    <Typography
                      style={{
                        verticalAlign: 'top',
                        display: 'inline-block',
                        maxWidth: 'min-content',
                        margin: '0 4px',
                        lineHeight: 1.195,
                        fontSize: '15px',
                        fontWeight: '900',
                      }}
                    >
                      L
                    </Typography>
                  </Tooltip>
                )}
                {move.move_details && (
                  <Tooltip title={move.move_details} placement='left'>
                    <AssignmentIcon style={{ transform: 'scale(0.65)', margin: '-4px' }} />
                  </Tooltip>
                )}
                {move.deliver_by && (
                  <Tooltip title={`Delivery by ${moment(move.deliver_by).format('h:mm a')}`} placement='left'>
                    <ScheduleIcon style={{ transform: 'scale(0.65)', margin: '-4px' }} />
                  </Tooltip>
                )}
                {move.manual_flag && (
                  <Tooltip title={`Manual Transmission`} placement='left'>
                    <ManualIcon style={{ transform: 'scale(0.65)', margin: '-4px' }} />
                  </Tooltip>
                )}
                {move.vehicle_image && (
                  <Tooltip title={<img src={`${move.vehicle_image}`} alt='vehicle img' />} placement='left'>
                    <InsertPhotoIcon style={{ transform: 'scale(0.65)', margin: '-4px' }} />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    );

    if (!usePortal) {
      return child;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(child, portal);
}

class UnplannedMoves extends Component {
  render() {
    const { moves } = this.props;
    return (
      <div style={{ height: '100vh', minHeight: '100vh', overflow: 'auto', minWidth: '5vw' }}>
        <Droppable droppableId={'unplanned'} direction='vertical'>
          {(provided, snapshot) => (
            <List component='div' aria-label='UnplannedMoves' ref={provided.innerRef} style={{ marginBottom: '200px' }}>
              {moves
                .sort((a, b) => {
                  //Sort dynamic since subscription adds to this list
                  // Below should emulate an SQL order by customer_id desc, id desc
                  var n = b.customer_id - a.customer_id;
                  if (n !== 0) {
                    return n;
                  }
                  return b.id - a.id;
                })
                .map((move, index) => {
                  if (log)
                    console.log(
                      `   Rendering move #${move.id} which is ${index + 1} of ${moves.length} unplanned moves`,
                      move
                    );
                  if (move.active) {
                    return (
                      <ContextMenuTrigger
                        id={`unplanned-move-draggable-context-menu`}
                        move={move}
                        holdToDisplay={1000}
                        collect={collect}
                        disableIfShiftIsPressed={true}
                        key={move.id}
                      >
                        <Draggable
                          draggableId={`unplanned-draggable-move-${move.id}`}
                          isDragDisabled={move.suggested}
                          value={move.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Portal provided={provided} snapshot={snapshot} move={move} index={index} moves={moves} />
                          )}
                        </Draggable>
                      </ContextMenuTrigger>
                    );
                  }
                })}
              {provided.placeholder}
              <Divider light />
            </List>
          )}
        </Droppable>
      </div>
    );
  }
}

export default UnplannedMoves;

