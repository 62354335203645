import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import { GlobalContext } from '../../global-context';

import {
  makeStyles,
  Container,
  Grid,
  Typography,
  IconButton,
  Icon,
  Tooltip,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Divide from '../reusable/Divide';

import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';

import Loading from '../utils/Loading';

import Modal from '../reusable/Modal';
import ManageAccessorials from './driverPayReview/ManageAccessorials';
import SpendAuthorization from '../reusable/SpendAuthorization';
import AuthorizationWindowModal from './moveDetails/AuthorizationWindowModal';

import CancelMoveModal from './plans/CancelMoveModal';
import LyftRideModal from './plans/LyftRideModal';

import MoveStatusTracker from '../reusable/MoveStatusTracker';
import MoveDetailsInfo from './moveDetails/MoveDetailsInfo';
import MoveDetailsLane from './moveDetails/MoveDetailsLane';
import MoveDetailsLocation from './moveDetails/MoveDetailsLocation';
import MoveDetailsTracking from './moveDetails/MoveDetailsTracking';
import MoveDetailsLyft from './moveDetails/MoveDetailsLyft';
import MoveDetailsAccessorials from './moveDetails/MoveDetailsAccessorials';
import MoveDetailsAR from './moveDetails/MoveDetailsAR';
import MoveDetailsAP from './moveDetails/MoveDetailsAP';
import MoveDetailsBreakdown from './moveDetails/MoveDetailsBreakdown';
import MoveDetailsRelated from './moveDetails/MoveDetailsRelated';
import MoveDetailsTimeline from './moveDetails/Timeline/MoveDetailsTimeline';

const log = false;

const { REACT_APP_ENV } = process.env;
const REACT_APP_TKN_SD = (REACT_APP_ENV === 'test' ? 'dispatch-api-test' : 'dispatch-api-prod');
const capFirst = str => { if (str) return str.charAt(0).toUpperCase() + str.slice(1) };

////////// COMPONENT //////////
function MoveDetails(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const moveId = props.match.params.id;

  const [editMode, setEditMode] = useState(
    props.location && props.location.state && props.location.state.editMode ? props.location.state.editMode : false
  );
  const [actionsOpen, setActionsOpen] = useState(null);

  const [tookan, setTookan] = useState({
    loading: true,
    pickup: { images: [], signature: null, name: null },
    delivery: { images: [], signature: null, name: null },
    drivers: [],
    driver: null,
  });

  const [modalRender, setModalRender] = useState(null);
  const [modalData, setModalData] = useState(null);

  const [accessorialsModal, setAccessorialsModal] = useState(false);
  const handleAccessorialsModalOpen = () => {
    setAccessorialsModal(true);
  };
  const handleAccessorialsModalClose = () => {
    setAccessorialsModal(false);
  };

  const [spendAuthModal, setSpendAuthModal] = useState(false);
  const handleSpendAuthModalOpen = () => {
    setSpendAuthModal(true);
  };
  const handleSpendAuthModalClose = () => {
    setSpendAuthModal(false);
  };

  const [authWindowModal, setAuthWindowModal] = useState(false);
  const handleAuthWindowModalOpen = () => {
    setAuthWindowModal(true);
  };
  const handleAuthWindowModalClose = () => {
    setAuthWindowModal(false);
  };


  const [cancelModal, setCancelModal] = useState(false);
  const handleCancelModalOpen = () => {
    setCancelModal(true);
  };
  const handleCancelModalClose = async (cancelStatus = null) => {
    if (moveId && cancelStatus) {
      try {
        let variables = {};
        if (cancelStatus === 'canceled') {
          variables = { id: moveId, cancelStatus: cancelStatus, payable: false, chargeable: false };
        } else {
          variables = { id: moveId, cancelStatus: cancelStatus, payable: true, chargeable: true };
        }
        log && console.log(variables, cancelStatus);
        const res = await ctx.apolloClient.mutate({
          mutation: CANCEL_MOVE,
          variables: variables,
        });
        if (res) {
          const resMove = res.data.update_moves.returning[0];
          log && console.log(`>> UPDATED Move:`, resMove);
          // ctx.handleNotifications(true, `success`, `The cancel status of Move #${resMove.id} was successfully updated.`);
          setCancelModal(false);
        }
      } catch (err) {
        console.error(`Failed to update move:`, err);
        ctx.handleNotifications(true, `error`, `Failed to update move: ${err.toString()}`);
      }
    } else setCancelModal(false);
  };

  const [lyftModal, setLyftModal] = useState(false);
  const handleLyftModalOpen = () => {
    setLyftModal(true);
  };
  const handleLyftModalClose = () => {
    setLyftModal(false);
  };

  const [active, setActive] = useState(false);
  const [autoAssign, setAutoAssign] = useState(false);
  const [cancelStatus, setCancelStatus] = useState(``);
  const [chargeable, setChargeable] = useState(false);
  const [color, setColor] = useState(``);
  const [consumerName, setConsumerName] = useState(``);
  const [consumerPhone, setConsumerPhone] = useState(``);
  const [consumerPickup, setConsumerPickup] = useState(false);
  const [dealerContact, setDealerContact] = useState(``);
  const [lyftFlag, setLyftFlag] = useState(false);
  const [make, setMake] = useState(``);
  const [manualFlag, setManualFlag] = useState(false);
  const [model, setModel] = useState(``);
  const [moveType, setMoveType] = useState(``);
  const [notes, setNotes] = useState(``);
  const [payable, setPayable] = useState(false);
  const [pickupTime, setPickupTime] = useState(``);
  const [priority, setPriority] = useState(10);
  const [rateClass, setRateClass] = useState(``);
  const [rateClassOverride, setRateClassOverride] = useState(false);
  const [refNum, setRefNum] = useState(``);
  const [rideType, setRideType] = useState(``);
  const [status, setStatus] = useState(``);
  const [statusTime, setStatusTime] = useState(``);
  const [stock, setStock] = useState(``);
  const [tags, setTags] = useState([]);
  const [vin, setVin] = useState(``);
  const [year, setYear] = useState(``);

  const GET_TOOKAN_DATA = async (api_key, job_id) =>
    axios.post(`https://${REACT_APP_TKN_SD}.socialautotransport.com/v2/get_task_details`, {
      api_key: api_key,
      job_id: job_id,
    });
  const GET_TOOKAN_DRIVERS = async api_key =>
    axios.post(`https://${REACT_APP_TKN_SD}.socialautotransport.com/v2/get_available_agents`, {
      api_key: api_key,
    });

  const getTookanData = async move => {
    log && console.log(`Getting data from tookan...`);

    let pickupJobId = move ? move.pickup_stop_id : null;
    let deliveryJobId = move ? move.delivery_stop_id : null;
    let pickupData = { images: [], signature: null, name: null };
    let deliveryData = { images: [], signature: null, name: null };
    let pickupImage = null;
    let deliveryImage = null;

    let drivers = [];
    let driver = null;

    // Tookan PICKUP images and signature
    if (pickupJobId) {
      await GET_TOOKAN_DATA(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`], pickupJobId)
        .then(res => {
          // log && console.log(`TOOKAN Pickup Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(item => item.description);
            tookanData = tookanData.filter(item => {
              if (item.includes(`task_images`)) return true;
              else if (item.includes(`acknowledgement_images`)) return true;
              else if (item.includes(`SignerName`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              // 6/21/2021 -- It appears that after a recent template update in Tookan
              // that custom field names no longer replace underscores with spaces internally.
              // This was an expected behavior for the below line to function.
              // I am adding an OR check for either version of the driver front template field
              // while also adding an additional check to filter out any reference to 'wheel'
              // since we updated the order of template and 'driver front wheel' would be included first
              let driverFront = tookanData.filter(
                obj =>
                  (obj.toLowerCase().includes(`driver_front`) || obj.toLowerCase().includes(`driver front`)) &&
                  !obj.toLowerCase().includes('wheel')
              );
              if (driverFront && driverFront.length > 0) pickupImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedData = tookanData.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedData && extractedData.length > 0) {
                extractedData = extractedData.sort((a, b) => {
                  if (!a.includes(`task_images`) && !a.includes(`acknowledgement_images`)) return 1;
                  if (!b.includes(`task_images`) && !b.includes(`acknowledgement_images`)) return -1;
                  else if (a.includes(`acknowledgement_images`)) return 1;
                  else if (b.includes(`acknowledgement_images`)) return -1;
                  else return 0;
                });

                for (let i = 0; i < extractedData.length; i++) {
                  if (extractedData[i].includes(`task_images`) || extractedData[i].includes(`acknowledgement_images`))
                    pickupData.images.push(extractedData[i]);
                  if (extractedData[i].includes(`acknowledgement_images`)) pickupData.signature = extractedData[i];
                  if (!extractedData[i].includes(`task_images`) && !extractedData[i].includes(`acknowledgement_images`))
                    pickupData.name = extractedData[i];
                }
              }
            }
          }
        })
        .catch(err => {
          console.error(`Failed to retrieve Tookan PICKUP data:`, err);
          // ctx.handleNotifications(true, `error`, `Error retrieving Tookan pickup data: ` + err.toString());
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan DELIVERY images and signature
    if (deliveryJobId) {
      await GET_TOOKAN_DATA(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`], deliveryJobId)
        .then(res => {
          // log && console.log(`TOOKAN Delivery Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(item => item.description);
            tookanData = tookanData.filter(item => {
              if (item.includes(`task_images`)) return true;
              else if (item.includes(`acknowledgement_images`)) return true;
              else if (item.includes(`SignerName`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              let driverFront = tookanData.filter(obj => obj.includes(`Driver_Front`));
              if (driverFront && driverFront.length > 0) deliveryImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedData = tookanData.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedData && extractedData.length > 0) {
                extractedData = extractedData.sort((a, b) => {
                  if (!a.includes(`task_images`) && !a.includes(`acknowledgement_images`)) return 1;
                  if (!b.includes(`task_images`) && !b.includes(`acknowledgement_images`)) return -1;
                  else if (a.includes(`acknowledgement_images`)) return 1;
                  else if (b.includes(`acknowledgement_images`)) return -1;
                  else return 0;
                });

                for (let i = 0; i < extractedData.length; i++) {
                  if (extractedData[i].includes(`task_images`) || extractedData[i].includes(`acknowledgement_images`))
                    deliveryData.images.push(extractedData[i]);
                  if (extractedData[i].includes(`acknowledgement_images`)) deliveryData.signature = extractedData[i];
                  if (!extractedData[i].includes(`task_images`) && !extractedData[i].includes(`acknowledgement_images`))
                    deliveryData.name = extractedData[i];
                }
              }
            }
          }
        })
        .catch(err => {
          console.error(`Error retrieving Tookan DELIVERY data:`, err);
          // ctx.handleNotifications(true, `error`, `Error retrieving Tookan delivery data: ` + err.toString());
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan DRIVER data
    await GET_TOOKAN_DRIVERS(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`])
      .then(res => {
        // log && console.log(`TOOKAN Drivers Response:`, res);
        if (res.status === 200 && res.data.status === 200 && res.data.data.length > 0) {
          let tookanDrivers = res.data.data;
          let tookanDriver = res.data.data.filter(driver => driver.fleet_id === move.driver_id);
          if (tookanDrivers.length > 0) drivers = tookanDrivers[0];
          if (tookanDriver.length > 0) driver = tookanDriver[0];
        }
      })
      .catch(err => {
        console.error(`Failed to retrieve Tookan drivers:`, err);
        setTookan({ ...tookan, loading: false });
      });

    // Set Tookan state object
    setTookan({
      loading: false,
      pickup: pickupData,
      pickupImage: pickupImage,
      delivery: deliveryData,
      deliveryImage: deliveryImage,
      drivers: drivers,
      driver: driver,
    });
  };

  const fetchTookan = move => {
    getTookanData(move);
    if (
      move.status &&
      move.cancel_status === null &&
      move.status !== `delivery successful` &&
      (move.status.includes(`pickup`) || move.status.includes(`delivery`))
    ) {
      setInterval(() => getTookanData(move), 60000);
    }
  };

  const goBack = () => {
    props.history.goBack();
  };
  const goToLaneDetails = laneId => {
    props.history.push(`/map/lanes/${laneId}`);
  };
  const goToLocationDetails = locId => {
    props.history.push(`/map/locations/${locId}`);
  };
  const goToInvoice = (customerId, invoiceId, expandedRowId) => {
    localStorage.setItem('customerId', customerId);
    localStorage.setItem('invoiceId', invoiceId);
    localStorage.setItem('expandedRowId', expandedRowId);
    props.history.push(`/invoices`);
  };

  const handleEditMode = () => {
    if (editMode) setEditMode(false);
    else setEditMode(true);
  };
  const handleSaveChanges = async moveId => {
    try {
      let variables = {
        id: moveId,
        active: active ? 1 : 0,
        autoAssign: autoAssign ? 1 : 0,
        cancelStatus: cancelStatus || null,
        chargeable: chargeable,
        color: color || null,
        consumerName: consumerName,
        consumerPhone: consumerPhone,
        consumerPickup: consumerPickup,
        dealerContact: dealerContact || null,
        lyftFlag: lyftFlag ? 1 : 0,
        make: make || null,
        manualFlag: manualFlag,
        model: model || null,
        moveType: moveType || null,
        notes: notes || null,
        payable: payable,
        pickupTime: pickupTime || null,
        priority: Number(priority),
        rateClass: rateClass || null,
        rateClassOverride: rateClassOverride ? 1 : 0,
        refNum: refNum || null,
        rideType: rideType || null,
        status: status || null,
        stock: stock || null,
        tags: tags.length > 0 ? tags.join(`,`) : null,
        vin: vin || null,
        year: year || null,
      };

      if (status && statusTime) {
        const statusTimeKey = getStatusTimeKey(status);
        if (statusTimeKey) variables[statusTimeKey] = statusTime;
      }

      const res = await ctx.apolloClient.mutate({
        mutation: UPDATE_MOVE(variables),
        variables: variables,
      });
      if (res) {
        const resMove = res.data.update_moves.returning[0];
        log && console.log(`>> UPDATED Move:`, resMove);
        // ctx.handleNotifications(true, `success`, `Move #${resMove.id} was successfully updated.`);
        setEditMode(false);
      }
    } catch (err) {
      console.error(`Failed to update move:`, err);
      ctx.handleNotifications(true, `error`, `Failed to update move: ${err.toString()}`);
    }
  };

  const getStatusTimeKey = status => {
    if (status !== 'dispatched' && status !== 'pickedUp' && status !== 'droppedOff' && status !== 'failed') {
      return status.split(' ').join('_');
    } else return;
  };

  const handleActionsOpen = event => {
    setActionsOpen(event.currentTarget);
  };
  const handleActionsClose = event => {
    setActionsOpen(null);
  };

  const handleTagDeliveryReported = async move => {
    let tags = move.tags || '';
    if (tags === '') tags = 'delivery reported';
    else tags += ',delivery reported';
    await ctx.apolloClient
      .mutate({
        mutation: gql`
          mutation updateTags($id: bigint!, $tags: String!) {
            update_moves(where: { id: { _eq: $id } }, _set: { tags: $tags }) {
              affected_rows
              returning {
                id
                tags
              }
            }
          }
        `,
        variables: { id: move.id, tags: tags },
      })
      .then(res => {
        log && console.log(`>> UPDATED Move: Added delivery reported tag.`);
        ctx.handleNotifications(true, `success`, `'Delivery Reported' tag was added to Move #${move.id}.`);
      })
      .catch(err => {
        console.error(`Failed to update move:`, err);
        ctx.handleNotifications(true, `error`, `Failed to update move: ${err.toString()}`);
      });
  };

  const handleUntagDeliveryReported = async move => {
    let tags = move.tags || '';
    if (tags === 'delivery reported') tags = '';
    else {
      tags = tags.replace(',delivery reported', '');
      tags = tags.replace('delivery reported,', '');
    }
    await ctx.apolloClient
      .mutate({
        mutation: gql`
          mutation updateTags($id: bigint!, $tags: String) {
            update_moves(where: { id: { _eq: $id } }, _set: { tags: $tags }) {
              affected_rows
              returning {
                id
                tags
              }
            }
          }
        `,
        variables: { id: move.id, tags: tags.length > 0 ? tags : null },
      })
      .then(res => {
        log && console.log(`>> UPDATED Move: Removed delivery reported tag.`);
        ctx.handleNotifications(true, `success`, `'Delivery Reported' tag was removed from Move #${move.id}.`);
      })
      .catch(err => {
        console.error(`Failed to update move:`, err);
        ctx.handleNotifications(true, `error`, `Failed to update move: ${err.toString()}`);
      });
  };

  const handleCopyToClipboard = str => {
    if (str) {
      navigator.clipboard.writeText(str);
      ctx.handleNotifications(true, `info`, `Copied text to clipboard!`);
    } else {
      ctx.handleNotifications(true, `warning`, `No text was found to copy!`);
    }
  };

  const handleAction = action => {
    handleActionsClose();
    if (action.handler) action.handler();
  };

  const handleReviewModal = armove => {
    setModalData(armove);
    setModalRender(`review`);
  };
  const handleARLogsModal = events => {
    setModalData(events);
    setModalRender(`invoice logs`);
  };

  const handleRerunAR = armove => {
    try {
      axios({
        method: `POST`,
        url: `/.netlify/functions/rerunARMove`,
        data: {
          id: armove.move_id,
          user: ctx.userProfile.email,
        },
        headers: {
          authorization: `Bearer ${ctx.userToken}`,
        },
      })
        .then(res => {
          log && console.log(`Rerun armove success:`, res);
        })
        .catch(err => {
          console.error(`An error occured while attempting to rerun armove:`, err);
        });
    } catch (err) {
      console.error(`An error occured while attempting to rerun armove:`, err);
    }
  };

  const handleRerunAP = move => {
    try {
      axios({
        method: `POST`,
        url: `/.netlify/functions/rerunAccountsPayable`,
        data: {
          move: move,
          user: ctx.userProfile.email,
        },
        headers: {
          authorization: `Bearer ${ctx.userToken}`,
        },
      })
        .then(res => {
          log && console.log(`Rerun AP success:`, res);
        })
        .catch(err => {
          console.error(`An error occured while attempting to rerun accounts payable:`, err);
        });
    } catch (err) {
      console.error(`An error occured while attempting to rerun accounts payable:`, err);
    }
  };

  const handleSpendAuthModalSave = () => {
    log && console.log('Spend authorization model saved!');
  };

  const handleAuthWindowModalSave = async (startTime, endTime, pendingAccId, pendingAuthId) => {
    const start_time = ctx.sdk.utilities.getLocalISOString(new Date(startTime));
    const end_time = ctx.sdk.utilities.getLocalISOString(new Date(endTime));
    if (start_time && end_time) {
      if (start_time > end_time) {
        ctx.handleNotifications(true, `warning`, `Invalid Window: End Time is earlier than Start Time`);
        log && console.log('Failed to save spend authorization window');
      } else if (start_time === end_time) {
        ctx.handleNotifications(true, `warning`, `Invalid Window: End Time = Start Time`);
        log && console.log('Failed to save spend authorization window');
      } else {
        let authorization = {
          updated_at: 'now()',
          valid_from: start_time,
          valid_to: end_time,
        };
        const response = await ctx.sdk.authorizations.update(pendingAuthId, authorization);
        if (response.success === true) {
          log && console.log(`>> UPDATED Accessorial Authorization Window: Times manually set`);
          ctx.handleNotifications(true, `success`, `Window was set on Accessorial #${pendingAccId}.`);
        } else {
          console.error(`Failed to update accessorial:`, response.message);
          ctx.handleNotifications(true, `error`, `Failed to update authorization: ${response.message}`);
        }
      }
    } else {
      if (!start_time) {
        ctx.handleNotifications(true, `warning`, `Invalid Start Time`);
      } else {
        ctx.handleNotifications(true, `warning`, `Invalid End Time`);
      }
      log && console.log('Failed to save spend authorization window');
    }
  };

  const checkPendingAuthorization = (acc) => {
    return acc.status === 'pending' && acc.authorization
  };

  const checkDeclinedExpired = (acc) => {
    return (acc.status === 'declined' && acc.authorization) || (acc.status === 'expired' && acc.authorization)
  };


  const handleResetWindow = async move => {
    const pendingAcc = move.accessorials.find(checkPendingAuthorization);
    const { id: accId, authorization: { id: authId } } = pendingAcc;

    const start_time = ctx.sdk.utilities.getLocalISOString(ctx.sdk.utilities.addMinutes(0 - ctx.sdk.authorizations.DEFAULT_VALID_CHARGE_BUFFER_MINUTES, new Date(pickupTime)));
    const durationMin = move.lane.duration_sec / 60
    const end_time = ctx.sdk.utilities.getLocalISOString(ctx.sdk.utilities.addMinutes(Number(durationMin.toFixed()) + ctx.sdk.authorizations.DEFAULT_VALID_CHARGE_BUFFER_MINUTES, new Date(pickupTime)));
    let authorization = {
      updated_at: 'now()',
      valid_from: start_time,
      valid_to: end_time,
    };
    const response = await ctx.sdk.authorizations.update(authId, authorization);
    if (response.success === true) {
      log && console.log(`>> UPDATED Accessorial Authorization Window: Reset valid window to pickup and dropoff times`);
      ctx.handleNotifications(true, `success`, `Authorization Window was reset on Accessorial #${accId}.`);
    } else {
      console.error(`Failed to update authorization:`, response.message);
      ctx.handleNotifications(true, `error`, `Failed to update authorization: ${response.message}`);
    }
  };

  const handleResetAttempts = async accs => {
    const pendingAcc = accs.find(checkPendingAuthorization);
    const { id: accId, authorization: { id: authId } } = pendingAcc;
    let authorization = {
      auth_attempts: 0,
      updated_at: 'now()'
    };
    const response = await ctx.sdk.authorizations.update(authId, authorization);
    if (response.success === true) {
      log && console.log(`>> UPDATED Accessorial Authorization: Reset attempts to 0`);
      ctx.handleNotifications(true, `success`, `Authorization Swipe Attempts were reset on Accessorial #${accId}.`);
  } else {
      console.error(`Failed to update authorization:`, response.message);
      ctx.handleNotifications(true, `error`, `Failed to update authorization: ${response.message}`);
    }
  };

  const handleExpirePending = async accs => {
    const pendingAcc = accs.find(checkPendingAuthorization);
    const { id: accId, authorization: { id: authId } } = pendingAcc;
    let accessorial = {
      status: ctx.sdk.accessorials.statusCodes.EXPIRED.code,
      updatedat: 'now()',
      notes: pendingAcc.notes + ', manually expired from move details',
    };
    let authorization = {
      approved_at: null,
      updated_at: 'now()',
      valid_from: null,
      valid_to: null,
    };
    const response = await ctx.sdk.authorizations.updateAccessorialAndAuthorization(accId, authId, accessorial, authorization);
    if (response.success === true) {
      log && console.log(`>> UPDATED Accessorial and Authorization: Expired Accessorial #${accId}`);
      ctx.handleNotifications(true, `success`, `Accessorial #${accId} was expired successfully.`);
  } else {
      console.error(`Failed to update accessorial:`, response.message);
      ctx.handleNotifications(true, `error`, `Failed to expire accessorial: ${response.message}`);
    }
  };

  return (
    <>
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <IconButton style={{ marginRight: ctx.theme.spacing(1) }} className={cls.iconBtn} onClick={() => goBack()}>
            <Icon>arrow_back</Icon>
          </IconButton>
          <Typography className={cls.head}>Move Details</Typography>
          {ctx.userProfile['https://hasura.io/jwt/claims'] && ctx.userIsAuthenticated() && (
            <Subscription
              subscription={GET_MOVE}
              variables={{ moveId: moveId }}
            // onError={err => ctx.handleNotifications(true, `error`, `Failed to retrieve move: ` + err.toString())}
            >
              {({ loading, error, data }) => {
                if (loading) return <Loading fixed />;
                if (error) {
                  console.error(`Failed to retrieve move:`, error);
                  return (
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>ERROR FINDING MOVE RECORD</Typography>
                    </div>
                  );
                }
                if (data && data.moves && data.moves.length > 0) {
                  const move = data.moves[0];
                  log && console.log(`Move Details:`, move);

                  const relatedMoves =
                    move && move.plan && move.plan.moves && move.plan.moves.length > 0
                      ? move.plan.moves.filter(m => m.id !== move.id)
                      : [];

                  const hasActiveAuthorization = move.accessorials.some(
                    acc => acc.status === 'pending' && acc.authorization
                  );

                  // Set actions
                  const globalActions = [
                    { label: `Manage Accessorials`, handler: () => handleAccessorialsModalOpen() },
                    { label: `Manage Cancel Status`, handler: () => handleCancelModalOpen() },
                    {
                      label: `Tag Delivery Reported`,
                      handler: () => handleTagDeliveryReported(move),
                      hide: (move.tags && move.tags.includes(`delivery reported`)) || move.customer.id !== 2,
                    },
                    {
                      label: `Untag Delivery Reported`,
                      handler: () => handleUntagDeliveryReported(move),
                      hide: !move.tags || !move.tags.includes(`delivery reported`),
                    },
                    {
                      label: `Copy Delivery Timestamp`,
                      handler: () => handleCopyToClipboard(move.delivery_successful),
                      hide: !move.delivery_successful,
                    },
                    {
                      label: `Authorize Spend`,
                      handler: () => handleSpendAuthModalOpen(),
                      hide: hasActiveAuthorization,
                    },
                  ];
                  const laneActions = [
                    { label: `View Lane Details`, handler: () => goToLaneDetails(move.lane.id) },
                    { label: `View Pickup Details`, handler: () => goToLocationDetails(move.lane.pickup.id) },
                    { label: `View Delivery Details`, handler: () => goToLocationDetails(move.lane.delivery.id) },
                  ];
                  const lyftActions = [
                    { label: `Manage ${move.moveByReturnRideId ? capFirst(move.moveByReturnRideId.ride_type) : 'Lyft'}`, handler: () => handleLyftModalOpen(), hide: !move.moveByReturnRideId },
                  ];
                  const accessorialActions = [
                    {
                      label: `Authorize Spend`,
                      handler: () => handleSpendAuthModalOpen(),
                      hide: hasActiveAuthorization,
                    },
                    {
                      label: `Set Valid Window`,
                      handler: () => handleAuthWindowModalOpen(),
                      hide: !hasActiveAuthorization,
                    },
                    {
                      label: `Reset Valid Window`,
                      handler: () => handleResetWindow(move),
                      hide: !hasActiveAuthorization,
                    },
                    {
                      label: `Reset Swipe Attempts`,
                      handler: () => handleResetAttempts(move.accessorials),
                      hide: !hasActiveAuthorization,
                    },
                    {
                      label: `Expire Pending Accessorial`,
                      handler: () => handleExpirePending(move.accessorials),
                      hide: !hasActiveAuthorization,
                    }
                  ];
                  const arActions = [
                    {
                      label: `Review/Discount`,
                      handler: () => handleReviewModal(move.accountsReceivable),
                      hide: !move.accountsReceivable,
                    },
                    {
                      label: `Manually Rerun AR`,
                      handler: () => handleRerunAR(move.accountsReceivable),
                      hide: !move.accountsReceivable,
                    },
                    {
                      label: `View Invoice Details`,
                      handler: () =>
                        goToInvoice(move.customer.id, move.accountsReceivable.invoice.id, move.accountsReceivable.id),
                      hide: !move.accountsReceivable,
                    },
                    {
                      label: `View AR Logs`,
                      handler: () => handleARLogsModal(move.accountsReceivable.eventsByEventId),
                      hide: !move.accountsReceivable || !move.accountsReceivable.eventsByEventId,
                    },
                  ];
                  const apActions = [
                    {
                      label: 'Manually Rerun AP',
                      handler: () => handleRerunAP(move),
                      hide: !move.payable, //no point in rerunning non-payable moves
                    },
                  ];

                  // Get images and driver data from Tookan (Set interval to fetch)
                  if (tookan.loading) {
                    fetchTookan(move);
                  }

                  return (
                    <>
                      <Modal
                        open={modalRender !== null}
                        data={modalData}
                        setData={setModalData}
                        render={modalRender}
                        setRender={setModalRender}
                      />
                      <ManageAccessorials
                        open={accessorialsModal}
                        close={handleAccessorialsModalClose}
                        moveId={move.id}
                      />
                      <SpendAuthorization
                        open={spendAuthModal}
                        close={handleSpendAuthModalClose}
                        moveId={move.id}
                        pickupTime={move.pickup_time}
                        durationSec={move.lane.duration_sec}
                        driverPayPerMinute={move.lane.driver_pay_per_minute}
                        customerId={move.customer.id}
                        declinedExpiredAccs={move.accessorials.filter(checkDeclinedExpired)}
                        save={handleSpendAuthModalSave}
                      />
                      <AuthorizationWindowModal
                        open={authWindowModal}
                        close={handleAuthWindowModalClose}
                        save={handleAuthWindowModalSave}
                        pendingAcc={move.accessorials.find(checkPendingAuthorization)}
                      />

                      <CancelMoveModal
                        open={cancelModal}
                        onSave={async cancelStatus => {
                          handleCancelModalClose(cancelStatus);
                        }}
                        onCancel={() => {
                          handleCancelModalClose();
                        }}
                        move={move}
                      />
                      {move.moveByReturnRideId ? (
                        <LyftRideModal
                          open={lyftModal}
                          close={handleLyftModalClose}
                          move={move.moveByReturnRideId}
                          drivers={tookan.drivers}
                        />
                      ) : null}

                      <div style={{ float: 'right' }}>
                        {editMode ? (
                          <>
                            <Tooltip placement='top' title={`Click to lock & save your changes`}>
                              <Button className={cls.saveBtn} onClick={() => handleSaveChanges(move.id)}>
                                Save Changes
                              </Button>
                            </Tooltip>
                          </>
                        ) : null}
                        <Tooltip
                          placement='top'
                          title={editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the move`}
                        >
                          <IconButton
                            style={{
                              marginLeft: ctx.theme.spacing(1),
                              color: editMode ? ctx.theme.palette.error.main : ctx.theme.palette.text.secondary,
                            }}
                            className={cls.iconBtn}
                            onClick={() => handleEditMode()}
                          >
                            <Icon>{editMode ? `lock_open` : `lock`}</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip placement='top' title={`Actions`}>
                          <IconButton
                            style={{ color: ctx.theme.palette.text.secondary }}
                            className={cls.iconBtn}
                            onClick={handleActionsOpen}
                          >
                            <Icon>settings</Icon>
                          </IconButton>
                        </Tooltip>
                        <Menu
                          keepMounted
                          id={`move-actions-menu`}
                          anchorEl={actionsOpen}
                          open={Boolean(actionsOpen)}
                          onClose={handleActionsClose}
                        >
                          {globalActions.map((action, i) =>
                            !action.hide ? (
                              <MenuItem key={`move-action-${i}`} onClick={() => handleAction(action)}>
                                {action.label || `Action ${i + 1}`}
                              </MenuItem>
                            ) : null
                          )}
                        </Menu>
                      </div>

                      <div style={{ width: '100%', height: ctx.theme.spacing(3) }} />
                      <MoveStatusTracker move={move} size='large' />
                      <div style={{ width: '100%', height: ctx.theme.spacing(3) }} />
                      <MoveDetailsInfo
                        move={move}
                        tookan={tookan}
                        editMode={editMode}
                        editVals={{
                          active,
                          autoAssign,
                          cancelStatus,
                          chargeable,
                          color,
                          consumerName,
                          consumerPhone,
                          consumerPickup,
                          dealerContact,
                          lyftFlag,
                          make,
                          manualFlag,
                          model,
                          moveType,
                          notes,
                          payable,
                          pickupTime,
                          priority,
                          rateClass,
                          rateClassOverride,
                          refNum,
                          rideType,
                          status,
                          statusTime,
                          stock,
                          tags,
                          vin,
                          year,
                        }}
                        editSetVals={{
                          setActive,
                          setAutoAssign,
                          setCancelStatus,
                          setChargeable,
                          setColor,
                          setConsumerName,
                          setConsumerPhone,
                          setConsumerPickup,
                          setDealerContact,
                          setLyftFlag,
                          setMake,
                          setManualFlag,
                          setModel,
                          setMoveType,
                          setNotes,
                          setPayable,
                          setPickupTime,
                          setPriority,
                          setRateClass,
                          setRateClassOverride,
                          setRefNum,
                          setRideType,
                          setStatus,
                          setStatusTime,
                          setStock,
                          setTags,
                          setVin,
                          setYear,
                        }}
                      />

                      <Divide spacer tip='View the lane this move is associated with.' actions={laneActions}>
                        Lane
                      </Divide>
                      <MoveDetailsLane move={move} />
                      <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <MoveDetailsLocation type='pickup' move={move} tookan={tookan.pickup} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <MoveDetailsLocation type='delivery' move={move} tookan={tookan.delivery} />
                        </Grid>
                      </Grid>

                      <Divide spacer tip='Track where the move is currently located.'>
                        Tracking
                      </Divide>
                      <MoveDetailsTracking move={move} />

                      {move.moveByReturnRideId ? (
                        <>
                          <Divide
                            spacer
                            tip='Track attempts at contacting Lyft or Uber and view details related to those attempts.'
                            actions={lyftActions}
                          >
                            Ride Hail
                          </Divide>
                          <MoveDetailsLyft move={move} lyft={move.moveByReturnRideId} />
                        </>
                      ) : null}

                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <Divide
                            spacer
                            tip='View what the customer is being charged for this move.'
                            actions={arActions}
                          >
                            Customer Price
                          </Divide>
                          <MoveDetailsAR move={move} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Divide spacer tip='View what the driver is being payed for this move.' actions={apActions}>
                            Driver Pay
                          </Divide>
                          <MoveDetailsAP move={move} />
                        </Grid>
                      </Grid>

                      {move.accessorials && move.accessorials.length > 0 ? (
                        <>
                          <Divide
                            spacer
                            tip='View accessorials and spend authorizations placed on this move'
                            actions={accessorialActions}
                          >
                            Accessorials
                          </Divide>
                          <MoveDetailsAccessorials move={move} />
                        </>
                      ) : null}

                      {move.accountsReceivable &&
                        move.appayments &&
                        move.appayments.length > 0 &&
                        ctx.userProfile['https://hasura.io/jwt/claims']['x-hasura-default-role'] === `admin` ? (
                        <>
                          <Divide spacer tip='View the profits and costs associated with this move.'>
                            Admin Breakdown
                          </Divide>
                          <MoveDetailsBreakdown move={move} />
                        </>
                      ) : null}

                      {relatedMoves.length > 0 ? (
                        <>
                          <Divide spacer tip='View moves related to this move by Plan ID.'>
                            Related Moves
                          </Divide>
                          <MoveDetailsRelated relatedMoves={relatedMoves} />
                        </>
                      ) : null}

                      <Divide spacer tip='Timeline of actions taken on this move.'>
                        Audit Timeline
                      </Divide>
                      <MoveDetailsTimeline moveId={move.id} />
                    </>
                  );
                } else
                  return (
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>NO MOVE RECORD FOUND</Typography>
                    </div>
                  );
              }}
            </Subscription>
          )}
        </Container>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  iconBtn: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginTop: '-12px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-14px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-16px',
    },
  },
  saveBtn: {
    verticalAlign: 'top',
    marginTop: '-6px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  head: {
    verticalAlign: 'top',
    display: 'inline-block',
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

////////// GRAPHQL //////////
const GET_MOVE = gql`
  subscription get_moves($moveId: bigint!) {
    moves(where: { id: { _eq: $moveId } }) {
      id
      trip_id
      plan_id
      tookan_relationship_id
      return_ride_id
      lyft_trigger_id
      active
      status
      cancel_status
      class
      move_type
      ride_type
      priority
      rate_class_override
      auto_assign
      lyft_flag
      chargeable
      payable
      consumer_pickup
      consumer_name
      consumer_phone
      tags
      dealer_contact
      driver_id
      driver_name
      customer {
        id
        name
      }
      pickup_stop_id
      delivery_stop_id
      lane {
        id
        description
        duration_sec
        distance_miles
        average_drive_speed_mph
        dealer_base_rate_type
        driver_base_pay
        dealer_base_price
        dealer_stranded_rate_type
        driver_return_pay
        driver_pay_per_minute
        dealer_stranded_price
        insurance_cost
        estimated_rideshare_return_cost
        pickup {
          id
          name
          address
        }
        delivery {
          id
          name
          address
        }
      }
      ready_by
      deliver_by
      pickup_time
      pickup_started
      pickup_arrived
      pickup_successful
      delivery_time
      delivery_started
      delivery_arrived
      delivery_successful
      tracking_link
      reference_num
      manual_flag
      vehicle_stock
      vehicle_vin
      vehicle_make
      vehicle_model
      vehicle_year
      vehicle_color
      vehicle_image
      vehicle_odometer
      move_details
      accountsReceivable {
        id
        move_id
        invoice {
          id
          status
          accounting_num
          start_datetime
          end_datetime
        }
        status
        notes
        due_amount
        paid_amount
        discount_amount
        discount_reason
        disputed
        dispute_reason
        details {
          id
          name
          notes
          amount
        }
        revisions(order_by: { revision: desc }) {
          id
          revision
          notes
          due_amount
          paid_amount
          discount_amount
          discount_reason
          disputed
          dispute_reason
          details {
            id
            name
            notes
            amount
          }
        }
        eventsByEventId {
          id
          move_id
          invoice_id
          message
          createdat
        }
      }
      appayments {
        id
        status
        amount
        notes
        type
        move {
          id
          move_type
        }
        accessorial {
          id
          code
          notes
        }
      }
      accessorials {
        id
        code
        status
        notes
        cost
        ap_amount
        ar_amount
        authorization {
          id
          vendor_auth_id
          card_id
          last4
          merchant_name
          merchant_city
          merchant_state
          merchant_postalcode
          max_charge
          driver_id
          driver_name
          auth_attempts
          last_declined_at
          last_declined_reason
          created_by
          created_at
          updated_at
          valid_from
          valid_to
        }
      }
      plan {
        id
        moves(order_by: { id: asc }) {
          id
          status
          cancel_status
          move_type
          ride_type
          reference_num
          vehicle_stock
          vehicle_vin
          vehicle_make
          vehicle_model
          vehicle_year
          vehicle_color
          lane {
            id
            description
          }
        }
      }
      lyftrides {
        attempts(order_by: { id: desc }) {
          id
          status
          ride_type
          estimated_ride_cost
          ride_cost
          ride_duration
          ride_distance
          driver_first_name
          driver_phone
          driver_rating
          driver_vehicle_make
          driver_vehicle_model
          driver_vehicle_year
          driver_vehicle_color
          driver_vehicle_license_plate
          driver_vehicle_license_plate_state
          requested_at
          createdat
        }
      }
      moveByReturnRideId {
        id
        driver_id
        driver_name
        ride_type
        lyft_trigger_id
        tags
        movesByLyftTriggerId(order_by: { id: desc }) {
          id
          driver_id
          driver_name
          parent_move {
            id
          }
        }
        lane {
          id
          pickup {
            id
            name
            latitude
            longitude
          }
        }
        parent_move {
          id
        }
        lyft_trigger_move {
          id
          driver_name
        }
        appayments(where: { type: { _eq: "move pay" } }) {
          id
          type
          amount
        }
      }
    }
  }
`;

const UPDATE_MOVE = variables => gql`
  mutation update_move(
    $id: bigint!
    $active: smallint!
    $autoAssign: smallint!
    $cancelStatus: String
    $chargeable: Boolean!
    $color: String
    $consumerName: String
    $consumerPhone: String
    $consumerPickup: Boolean!
    $dealerContact: String
    $lyftFlag: smallint!
    $make: String
    $manualFlag: Boolean!
    $model: String
    $moveType: String!
    $notes: String
    $payable: Boolean!
    $pickupTime: timestamptz
    $priority: bigint!
    $rateClass: String
    $rateClassOverride: smallint!
    $refNum: String
    $rideType: String
    $status: String
    $stock: String
    $tags: String
    $vin: String
    $year: String
    ${variables.pickup_started ? '$pickup_started: timestamptz' : ''}
    ${variables.pickup_arrived ? '$pickup_arrived: timestamptz' : ''}
    ${variables.pickup_successful ? '$pickup_successful: timestamptz' : ''}
    ${variables.delivery_started ? '$delivery_started: timestamptz' : ''}
    ${variables.delivery_arrived ? '$delivery_arrived: timestamptz' : ''}
    ${variables.delivery_successful ? '$delivery_successful: timestamptz' : ''}
  ) {
    update_moves(
      where: { id: { _eq: $id } }
      _set: {
        active: $active
        auto_assign: $autoAssign
        cancel_status: $cancelStatus
        chargeable: $chargeable
        class: $rateClass
        consumer_name: $consumerName
        consumer_phone: $consumerPhone
        consumer_pickup: $consumerPickup
        dealer_contact: $dealerContact
        lyft_flag: $lyftFlag
        manual_flag: $manualFlag
        move_details: $notes
        move_type: $moveType
        payable: $payable
        pickup_time: $pickupTime
        priority: $priority
        rate_class_override: $rateClassOverride
        reference_num: $refNum
        ride_type: $rideType
        status: $status
        tags: $tags
        vehicle_color: $color
        vehicle_make: $make
        vehicle_model: $model
        vehicle_stock: $stock
        vehicle_vin: $vin
        vehicle_year: $year
        ${variables.pickup_started ? 'pickup_started: $pickup_started' : ''}
        ${variables.pickup_arrived ? 'pickup_arrived: $pickup_arrived' : ''}
        ${variables.pickup_successful ? 'pickup_successful: $pickup_successful' : ''}
        ${variables.delivery_started ? 'delivery_started: $delivery_started' : ''}
        ${variables.delivery_arrived ? 'delivery_arrived: $delivery_arrived' : ''}
        ${variables.delivery_successful ? 'delivery_successful: $delivery_successful' : ''}
      }
    ) {
      affected_rows
      returning {
        id
        active
        auto_assign
        cancel_status
        chargeable
        class
        consumer_name
        consumer_phone
        consumer_pickup
        dealer_contact
        delivery_started
        delivery_arrived
        delivery_successful
        lyft_flag
        manual_flag
        move_details
        move_type
        payable
        pickup_started
        pickup_arrived
        pickup_successful
        pickup_time
        priority
        rate_class_override
        reference_num
        ride_type
        status
        tags
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_stock
        vehicle_vin
        vehicle_year
      }
    }
  }
  `;

const CANCEL_MOVE = gql`
  mutation cancel_move($id: bigint!, $cancelStatus: String!, $chargeable: Boolean!, $payable: Boolean!) {
    update_moves(
      where: { id: { _eq: $id } }
      _set: { cancel_status: $cancelStatus, chargeable: $chargeable, payable: $payable }
    ) {
      affected_rows
      returning {
        id
        status
        cancel_status
      }
    }
  }
`;

////////// EXPORT //////////
export default withRouter(MoveDetails);
