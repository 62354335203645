import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import axios from 'axios';
import InvoiceLayout from './InvoiceLayout';
import { GlobalContext } from "../../../global-context";

const { REACT_APP_ENV } = process.env;
const REACT_APP_TKN_SD = (REACT_APP_ENV === 'test' ? 'dispatch-api-test' : 'dispatch-api-prod');
const REACT_APP_BOLBK = 'sat-image-overrides'

const GET_BOL_IMG_URL_FROM_TOOKAN = (APIKey, tookan_job_id) => axios.post(`https://${REACT_APP_TKN_SD}.socialautotransport.com/V2/get_task_details`, { api_key: APIKey, job_id: tookan_job_id })

const CHECK_BOL_OVERRIDE = image_name => axios.head(`https://s3.amazonaws.com/${REACT_APP_BOLBK}/${image_name}`);
const REACT_APP_BOLNM = '{moveid}_bol_carmax.jpg'
const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
  }
})

class InvoiceWrapper extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      invoiceMoves: [],
      priceSum: 0,
      distanceSum: 0,
    }
  }

  componentDidMount = () => {
    this.handleCustomerInvoice(this.props.moves);
  }

  componentWillReceiveProps = () => {
    this.handleCustomerInvoice(this.props.moves);
  }

  handleS3ImageCheck = (move, s3Url, s3Exists) => {
    if (s3Exists) {
      move.bolImageUrl = s3Url
      this.setState({
        [`bol_${move.id}`]: s3Url
      })
    } else {
      GET_BOL_IMG_URL_FROM_TOOKAN(this.context.userProfile["https://api_keys.io/jwt/claims"]["TookanKey"], move.delivery_stop_id)
        .then(res => {
          if (res.status == 200 && res.data.data[0].task_history.find(o => o.description.includes("BOL"))) {
            let bol_obj = res.data.data[0].task_history.find(o => o.description.includes("BOL"));
            if (bol_obj) {
              let foundDescription = JSON.parse(bol_obj.description)
              if (foundDescription.fleet_data) {
                let foundURL = foundDescription.fleet_data
                move.bolImageUrl = foundURL.replace('[\"', '').replace('\"]', '')
                this.setState({
                  [`bol_${move.id}`]: move.bolImageUrl
                })
              }
            }
          }
        })
        .catch(err => {
          console.log("   Failed to retrieve Tookan BOL image URL.", err)
        })
    }
  }

  bolOverrideExists = (moveId) => {
    let imageName = REACT_APP_BOLNM.replace('{MOVEID}', moveId)
    return CHECK_BOL_OVERRIDE(imageName)
  }

  handleCustomerInvoice = (arMoves) => {
    let priceSum = 0
    let distanceSum = 0
    let moves = []
    arMoves.map(armove => {
      armove.move.price = armove.due_amount - armove.discount_amount
      // This does not exist anywhere on the AR record and must be set from the lane:
      armove.move.rateType = armove.move.class === 'base' ? armove.move.lane.dealer_base_rate_type : armove.move.lane.dealer_stranded_rate_type
      armove.move.bolImageUrl = 'http://icons.iconarchive.com/icons/oxygen-icons.org/oxygen/256/Status-image-missing-icon.png'
      let overrideImageName = REACT_APP_BOLNM.replace('{moveid}', armove.move.id)
      CHECK_BOL_OVERRIDE(overrideImageName)
        .then(res => {
          this.handleS3ImageCheck(armove.move, `https://s3.amazonaws.com/${REACT_APP_BOLBK}/${overrideImageName}`, true)
        })
        .catch(err => {
          if (!err.response) {  //Localhost returns network error cuz CORS if the image exists
            this.handleS3ImageCheck(armove.move, `https://s3.amazonaws.com/${REACT_APP_BOLBK}/${overrideImageName}`, true)
          } else {  //Else it returns an actual 403 not found (the image doesnt exist in S3)
            this.handleS3ImageCheck(armove.move, '', false)
          }
        });
      moves.push(armove.move);
      this.setState({ [`bol_${armove.move.id}`]: armove.move.bolImageUrl })
      priceSum += armove.move.price
      distanceSum += Number((armove.move.lane.distance_miles != 'undefined') ? armove.move.lane.distance_miles : 0)
    })
    //Get customer address for the header
    let customerAddress = this.props.customer.address
    this.setState({
      invoiceMoves: moves,
      priceSum: priceSum,
      distanceSum: distanceSum,
      customerAddress: customerAddress,
      isLoading: false
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.props.customer.id && this.state.invoiceMoves.length > 0 ?
          <Paper className={classes.paper} elevation={5}>
            <div // Element to refer to when printing
              ref={el => this.props.assignPrintRef(el)}
            >
              {this.state.invoiceMoves.map((move, index) => {
                let totPrice = move.price,
                  totDistance = Number((move.lane.distance_miles != 'undefined') ? move.lane.distance_miles : 0);
                return (
                  <React.Fragment>
                    <InvoiceLayout
                      customerName={this.props.customer.name}
                      customerId={this.props.customer.id}
                      invoiceNumber={this.props.invoiceNumber}
                      startDate={this.props.startDate}
                      endDate={this.props.endDate}
                      moves={[move]}
                      priceSum={totPrice}
                      distanceSum={totDistance}
                      customerAddress={this.props.customer.address}
                      accountSuffix={this.props.accountSuffix}
                    />
                    <p style={{ pageBreakBefore: "always" }} />
                    <img
                      style={{ maxHeight: '800px', maxWidth: '800px', padding: 40 }}
                      src={this.state[`bol_${move.id}`]}
                      alt={`BOL ${move.id}`}>
                    </img>
                    {
                      // Preventing a blank page at the end of printing
                      (index + 1 != this.state.invoiceMoves.length) ? <p style={{ pageBreakBefore: "always" }} /> : null
                    }
                  </React.Fragment>
                )
              })}
            </div>
          </Paper>
          : null
        }
      </React.Fragment>
    )
  }
}

InvoiceWrapper.contextType = GlobalContext;

InvoiceWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(InvoiceWrapper)