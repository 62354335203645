import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import { GlobalContext } from '../../global-context';

import {
  makeStyles,
  Container,
  Typography,
  IconButton,
  Icon,
  Tooltip,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Divide from '../reusable/Divide';

import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';
import sdk from '@hopdrive/sdk';

import Loading from '../utils/Loading';

import fragments from '../utils/graphQL/fragments';
import CustomerDetailsWhitelabeling from './customerDetails/CustomerDetailsWhitelabeling';
import CustomerDetailsInfo from './customerDetails/CustomerDetailsInfo';
import CustomerUsers from './customerDetails/CustomerUsers';
import CustomerPricing from './CustomerPricing';

const log = false;

////////// COMPONENT //////////
function CustomerDetails(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const customerId = props.match.params.id;

  const [ inMemoryCustomer, setInMemoryCustomer ] = useState({});

  const [editMode, setEditMode] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(null);

  useEffect(() => {
    log && console.log("inMemoryCustomer", inMemoryCustomer)
  }, [inMemoryCustomer]);

  const handleInMemoryCustomer = event => {
    log && console.log("handleInMemoryCustomer event:", event)
    if (event.config) {
      setInMemoryCustomer(Object.assign({}, inMemoryCustomer, {
        config: { 
          ...inMemoryCustomer.config,
          [event.config.name]: event.config.value
        }
      }));
    } else {
      if (event && event.type === 'checkbox') {
          setInMemoryCustomer(
          Object.assign({ ...inMemoryCustomer }, { [event.name]: !inMemoryCustomer[event.name] })
        )
      } else setInMemoryCustomer(Object.assign({ ...inMemoryCustomer }, { [event.target.name]: event.target.value }));
    }
  }

  const goBack = () => {
    props.history.goBack();
  };

  const handleEditMode = customer => {
    if (editMode) {
      setEditMode(false);
      setInMemoryCustomer({});
    } else {
      setEditMode(true);
      setInMemoryCustomer({ ...customer });
    }
  };

  const handleActionsOpen = event => {
    setActionsOpen(event.currentTarget);
  };
  const handleActionsClose = event => {
    setActionsOpen(null);
  };

  const handleAction = action => {
    handleActionsClose();
    if (action.handler) action.handler();
  };

  let handleUpdateCustomer = async (customer) => {
    const oldCustomer = JSON.parse(JSON.stringify({ ...customer }));
    const newCustomer = JSON.parse(JSON.stringify({ ...inMemoryCustomer }));
    let updateObj = Object.keys(newCustomer).reduce((diff, key) => {
      if (oldCustomer[key] === newCustomer[key]) return diff;
      return {
        ...diff,
        [key]: newCustomer[key],
      };
    }, {});
    Object.assign(updateObj, { updatedat: 'now()' });
    if (updateObj.raterulegroups) delete updateObj.raterulegroups;
    if (updateObj.raterules) delete updateObj.raterules;

    try {
      let oldConfig = await sdk.customers.getConfig(customer.id); // fetch customer config defaults
      let newConfig = updateObj.config ? { ...oldConfig, ...updateObj.config } : oldConfig; // merge defaults with config updates
      Object.assign(updateObj, { config: newConfig });
    } catch (err) {
      console.error('Unable to update customer config:', err.message)
    }

    try {
      ctx.apolloClient
        .mutate({
          mutation: gql`
            mutation update_customer($customer: customers_set_input!) {
              update_customers(
                where: {id: {_eq: ${customer.id}}},
                _set: $customer
              ) {
                affected_rows
              }
            }
          `,
          variables: { customer: updateObj },
        })
        .then(res => {
          setEditMode(false);
          setInMemoryCustomer({});
        })
        .catch(err => {
          console.error(err);
          ctx.handleNotifications(true, `error`, `Failed to update customer`);
        });
    } catch (err) {
      console.error('Unexpected error while updating customer:', err);
      ctx.handleNotifications(true, `error`, `Failed to update customer`);
    }
  }

  function handleCustomerActive(customer, activeStatus) {
    try {
      ctx.apolloClient
        .mutate({
          mutation: gql`
          mutation update_customer($active: smallint!) {
            update_customers(
              where: {id: {_eq: ${customer.id}}},
              _set: {
                active: $active
                updatedat: "now()"
              }
              ) {
                affected_rows
              }
            }
          `,
          variables: { active: activeStatus },
        })
        .then(res => {
          setEditMode(false);
          setInMemoryCustomer({});
        })
        .catch(err => {
          console.error(err);
          ctx.handleNotifications(true, `error`, `Failed to update customer`);
        });
    } catch (err) {
      console.error('Unexpected error while updating customer:', err);
      ctx.handleNotifications(true, `error`, `Failed to update customer`);
    }
  }

  return (
    <>
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <IconButton style={{ marginRight: ctx.theme.spacing(1) }} className={cls.iconBtn} onClick={() => goBack()}>
            <Icon>arrow_back</Icon>
          </IconButton>
          <Typography className={cls.head}>Customer Details</Typography>
          {ctx && ctx.userIsAuthenticated() && (
            <Subscription
              subscription={gql`
                subscription getCustomer($id: bigint!) {
                  customers(where: { id: { _eq: $id } }) {
                    ...CustomerWithRating
                  }
                }
                ${fragments.customerWithRating}
              `}
              variables={{ id: customerId }}
              //Was causing infinited reload on error
              // onError={(err) => { console.error(err); ctx.handleNotifications(true, `error`, `Query failed to retrieve customer details`) }}
            >
              {({ loading, error, data }) => {
                if (loading) return <Loading fixed />;
                if (error) {
                  console.error(`Failed to retrieve customer:`, error);
                  return (
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>ERROR FINDING CUSTOMER RECORD</Typography>
                    </div>
                  );
                }
                if (data && data.customers && data.customers.length > 0) {
                  const customer = data.customers[0];
                  // Set actions
                  const globalActions = [
                    {
                      label: `Mark Customer ${customer.active ? 'Inactive' : 'Active'}`,
                      handler: () => {
                        if (
                          window.confirm(
                            `Are you sure you want to mark ${customer.name} as ${
                              customer.active ? 'inactive' : 'active'
                            }?`
                          )
                        )
                          handleCustomerActive(customer, customer.active ? 0 : 1);
                      },
                    },
                  ];
                  return (
                    <>
                      <div style={{ float: 'right' }}>
                        {editMode ? (
                          <>
                            <Tooltip placement='top' title={`Click to lock & save your changes`}>
                              <Button className={cls.saveBtn} onClick={() => handleUpdateCustomer(customer)}>
                                Save Changes
                              </Button>
                            </Tooltip>
                          </>
                        ) : null}
                        <Tooltip
                          placement='top'
                          title={
                            editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the customer`
                          }
                        >
                          <IconButton
                            style={{
                              marginLeft: ctx.theme.spacing(1),
                              color: editMode ? ctx.theme.palette.error.main : ctx.theme.palette.text.secondary,
                            }}
                            className={cls.iconBtn}
                            onClick={() => handleEditMode(customer)}
                          >
                            <Icon>{editMode ? `lock_open` : `lock`}</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip placement='top' title={`Actions`}>
                          <IconButton
                            style={{ color: ctx.theme.palette.text.secondary }}
                            className={cls.iconBtn}
                            onClick={handleActionsOpen}
                          >
                            <Icon>settings</Icon>
                          </IconButton>
                        </Tooltip>
                        <Menu
                          keepMounted
                          id={`customer-actions-menu`}
                          anchorEl={actionsOpen}
                          open={Boolean(actionsOpen)}
                          onClose={handleActionsClose}
                        >
                          {globalActions.map((action, i) =>
                            !action.hide ? (
                              <MenuItem key={`customer-action-${i}`} onClick={() => handleAction(action)}>
                                {action.label || `Action ${i + 1}`}
                              </MenuItem>
                            ) : null
                          )}
                        </Menu>
                      </div>

                      <div style={{ width: '100%', height: ctx.theme.spacing(3) }} />

                      <CustomerDetailsInfo
                        customer={customer}
                        editMode={editMode}
                        inMemoryCustomer={inMemoryCustomer}
                        handleInMemoryCustomer={handleInMemoryCustomer}
                      />

                      <CustomerDetailsWhitelabeling
                        customer={customer} 
                        editMode={editMode}
                        inMemoryCustomer={inMemoryCustomer}
                        handleInMemoryCustomer={handleInMemoryCustomer}
                      /> 

                      <Divide spacer tip='View and manage user accounts for this customer.'>
                        Users
                      </Divide>
                      <CustomerUsers customer={customer} />

                      <Divide spacer tip="View and manage pricing rates for customer's established mileage bands.">
                        Pricing
                      </Divide>
                      <CustomerPricing customer={customer} />

                    </>
                  );
                } else
                  return (
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>NO CUSTOMER RECORD FOUND</Typography>
                    </div>
                  );
              }}
            </Subscription>
          )}
        </Container>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  iconBtn: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginTop: '-12px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-14px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-16px',
    },
  },
  saveBtn: {
    verticalAlign: 'top',
    marginTop: '-6px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  head: {
    verticalAlign: 'top',
    display: 'inline-block',
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

////////// EXPORT //////////
export default withRouter(CustomerDetails);
