import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/ModalComponents';

////////// COMPONENT //////////
export default function AuthorizationWindowModal({ open, close, save, pendingAcc = {
  id: 0, authorization: { id: 0, valid_from: 0, valid_to: 0 }
}}) {
  const { id: accId, authorization: { id: authId, valid_from, valid_to } } = pendingAcc;

  const cls = useStyles();

  const [startTime, setStartTime] = useState(valid_from);
  const [endTime, setEndTime] = useState(valid_to);

  useEffect(() => {
    setStartTime(valid_from);
    setEndTime(valid_to);
  }, [open])

  const handleStartTimeChange = event => {
    setStartTime(moment(event).format());
  };

  const handleEndTimeChange = event => {
    setEndTime(moment(event).format());
  };

  const handleSubmit = async () => {
      save(startTime, endTime, accId, authId);
      close();
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={close}>Edit Authorization Window</ModalHeader>

        <ModalContent
          subtitle={`Edit the start and end times of the current pending accessorial's valid window. Card swipes will only be accepted within this window of time. Your changes will be made upon clicking 'Submit.'`}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              autoOk
              fullWidth
              minutesStep={5}
              variant='inline'
              orientation='portrait'
              label='Start Time'
              margin='none'
              inputVariant='outlined'
              value={startTime}
              onChange={handleStartTimeChange}
            />

            <div className={cls.break} />

            <TimePicker
              autoOk
              fullWidth
              minutesStep={5}
              variant='inline'
              orientation='portrait'
              label='End Time'
              margin='none'
              inputVariant='outlined'
              value={endTime}
              onChange={handleEndTimeChange}
            />
          </MuiPickersUtilsProvider>
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()}>Submit</ModalAction>
          <ModalAction onClick={() => close()} color='default'>
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
