import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { GlobalContext } from '../../../global-context';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { makeStyles, Grid, Typography, Button, Tooltip, Icon, IconButton, TextField } from '@material-ui/core';

import Carousel from '../../reusable/Carousel';

const { REACT_APP_ENV } = process.env;
const REACT_APP_TKN_SD = (REACT_APP_ENV === 'test' ? 'dispatch-api-test' : 'dispatch-api-prod');

////////// TOOLS //////////
const collect = props => {
  return props;
};

const getTimeBetween = (startTime, endTime) => {
  let start = moment.utc(startTime);
  let end = moment.utc(endTime);
  let dur = Math.round(Math.abs(moment.duration(end.diff(start)).asMinutes()));
  return dur;
};

const capFirst = str => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
};

const checkNeg = num => {
  if (num > 0) return num;
  else return 0;
};

////////// COMPONENT //////////
export default function ARMoveDetail(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const {
    row,
    armove,
    armove: { move, revisions },
    actions,
  } = props;

  const handleOptionsOpen = e => {
    if (row.contextTrigger) {
      row.contextTrigger.handleContextClick(e);
    }
  };

  const currentRevision = revisions.length > 0 ? revisions[0].revision + 1 : 1;
  const [revisionNum, setRevisionNum] = useState(currentRevision);
  const getSelectedRevision = () => {
    if (currentRevision === revisionNum) return armove;
    else {
      for (let i = 0; i < revisions.length; i++) {
        if (revisions[i].revision === revisionNum) return revisions[i];
      }
    }
  };
  const selectedRevision = getSelectedRevision();
  const selectedDetails = selectedRevision.details;

  useEffect(() => {
    if (armove) setRevisionNum(currentRevision);
  }, [armove]);

  const total = selectedRevision ? selectedRevision.due_amount - selectedRevision.discount_amount : 0;
  const paid = selectedRevision ? selectedRevision.paid_amount : 0;
  const due = total - paid;

  const [images, setImages] = useState([]);
  const [carousel, setCarousel] = useState(false);

  const GET_IMAGES_FROM_TOOKAN = (api_key, job_id) =>
    axios.post(`https://${REACT_APP_TKN_SD}.socialautotransport.com/V2/get_task_details`, {
      api_key: api_key,
      job_id: job_id,
    });
  const viewTookanImages = () => {
    GET_IMAGES_FROM_TOOKAN(ctx.userProfile['https://api_keys.io/jwt/claims']['TookanKey'], move.delivery_stop_id)
      .then(res => {
        if (res.status === 200) {
          let dataImages = res.data.data[0].task_history.filter(item => item.description.includes('task_images'));
          dataImages = dataImages.map(item => item.description);
          if (dataImages && dataImages.length > 0) {
            let foundURLs = dataImages.map(url => JSON.parse(url).fleet_data);
            setImages(foundURLs);
            setCarousel(true);
          } else {
            setImages([]);
            setCarousel(true);
          }
        }
      })
      .catch(err => {
        console.log('Failed to retrieve Tookan image URLs: ', err);
        setImages([]);
        setCarousel(true);
      });
  };

  const pickupStart = move.pickup_started ? moment(move.pickup_started).format('LT') : 'N/A';
  const pickupInspect = getTimeBetween(move.pickup_arrived, move.pickup_successful);
  const pickupDwell = getTimeBetween(move.pickup_started, move.pickup_successful);
  const deliveryDrive = getTimeBetween(move.delivery_started, move.delivery_arrived);
  const deliveryInspect = getTimeBetween(move.delivery_arrived, move.delivery_successful);
  const deliveryDwell = getTimeBetween(move.delivery_started, move.delivery_successful);

  const pickupTotal = pickupInspect + pickupDwell;
  const deliveryTotal = deliveryDrive + deliveryInspect + deliveryDwell;

  const useBarStyles = makeStyles(theme => ({
    p1: {
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(pickupTotal) ? (pickupInspect / pickupTotal) * 100 : 50}%`, // Assign value based on times
      height: '4px',
      background: '#0080ff',
    },
    p2: {
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(pickupTotal) ? (pickupDwell / pickupTotal) * 100 : 50}%`, // Assign value based on times
      height: '4px',
      background: '#00ff80',
    },
    p3: {
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(deliveryTotal) ? (deliveryDrive / deliveryTotal) * 100 : 33}%`, // Assign value based on times
      height: '4px',
      background: '#ff2000',
    },
    p4: {
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(deliveryTotal) ? (deliveryInspect / deliveryTotal) * 100 : 33}%`, // Assign value based on times
      height: '4px',
      background: '#ffdd00',
    },
    p5: {
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(deliveryTotal) ? (deliveryDwell / deliveryTotal) * 100 : 33}%`, // Assign value based on times
      height: '4px',
      background: '#ff9000',
    },
  }));
  const barClasses = useBarStyles();

  return (
    <div className={cls.root}>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Typography className={cls.main}>Pickup Details</Typography>
          <div className={cls.block}>
            <Typography className={cls.beeg + ' ' + cls.ellipse}>
              {move.lane && move.lane.pickup ? move.lane.pickup.name : ''}
            </Typography>
            <Typography className={cls.beeg + ' ' + cls.ellipse}>
              {move.lane && move.lane.pickup ? move.lane.pickup.address : ''}
            </Typography>
          </div>
          <div className={cls.block}>
            <div className={barClasses.p1} />
            <div className={barClasses.p2} />
          </div>
          <div className={cls.block}>
            <Grid container spacing={0} justify='space-between'>
              <Grid item xs={4} className={cls.typeLeft + ' ' + cls.ellipse}>
                <Typography className={cls.smol + ' ' + cls.inline}>Start:&nbsp;</Typography>
                <Typography className={cls.smol + ' ' + cls.inline}>{pickupStart}</Typography>
              </Grid>
              <Grid item xs={4} className={cls.typeCenter + ' ' + cls.ellipse}>
                <Typography className={cls.smol + ' ' + cls.inline}>Inspect:&nbsp;</Typography>
                <Typography className={cls.smol + ' ' + cls.inline}>
                  {pickupInspect ? `${pickupInspect} mins` : `N/A`}
                </Typography>
              </Grid>
              <Grid item xs={4} className={cls.typeRight + ' ' + cls.ellipse}>
                <Typography className={cls.smol + ' ' + cls.inline}>Dwell:&nbsp;</Typography>
                <Typography className={cls.smol + ' ' + cls.inline}>
                  {pickupDwell ? `${pickupDwell} mins` : `N/A`}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={cls.break} />
          <Typography className={cls.main}>Delivery Details</Typography>
          <div className={cls.block}>
            <Typography className={cls.beeg + ' ' + cls.ellipse}>
              {move.lane && move.lane.delivery ? move.lane.delivery.name : ''}
            </Typography>
            <Typography className={cls.beeg + ' ' + cls.ellipse}>
              {move.lane && move.lane.delivery ? move.lane.delivery.address : ''}
            </Typography>
          </div>
          <div className={cls.block}>
            <div className={barClasses.p3} />
            <div className={barClasses.p4} />
            <div className={barClasses.p5} />
          </div>
          <div className={cls.block}>
            <Grid container spacing={0} justify='space-between'>
              <Grid item xs={4} className={cls.typeLeft + ' ' + cls.ellipse}>
                <Typography className={cls.smol + ' ' + cls.inline}>Drive:&nbsp;</Typography>
                <Typography className={cls.smol + ' ' + cls.inline}>
                  {deliveryDrive ? `${deliveryDrive} mins` : `N/A`}
                </Typography>
              </Grid>
              <Grid item xs={4} className={cls.typeCenter + ' ' + cls.ellipse}>
                <Typography className={cls.smol + ' ' + cls.inline}>Inspect:&nbsp;</Typography>
                <Typography className={cls.smol + ' ' + cls.inline}>
                  {deliveryInspect ? `${deliveryInspect} mins` : `N/A`}
                </Typography>
              </Grid>
              <Grid item xs={4} className={cls.typeRight + ' ' + cls.ellipse}>
                <Typography className={cls.smol + ' ' + cls.inline}>Dwell:&nbsp;</Typography>
                <Typography className={cls.smol + ' ' + cls.inline}>
                  {deliveryDwell ? `${deliveryDwell} mins` : `N/A`}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={4}>
          <Typography className={cls.main}>Vehicle Details</Typography>
          <div className={cls.block}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <Typography className={cls.key}>Vehicle:&nbsp;</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography className={cls.val}>
                  {move.vehicle_year} {move.vehicle_make} {move.vehicle_model}
                  {move.vehicle_color ? `\xa0(${capFirst(move.vehicle_color)})` : null}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.block}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <Typography className={cls.key}>VIN:&nbsp;</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography className={cls.val}>{move.vehicle_vin && move.vehicle_vin.toUpperCase()}</Typography>
              </Grid>
            </Grid>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.block}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <Typography className={cls.key}>Odometer:&nbsp;</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography className={cls.val}>
                  {move.vehicle_odometer} (+{move.lane.distance_miles ? move.lane.distance_miles : 0} mi)
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={cls.break} />
          <Typography className={cls.main}>Drive Details</Typography>
          <div className={cls.block}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <Typography className={cls.key}>Class:&nbsp;</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography className={cls.val}>{capFirst(move.class)}</Typography>
              </Grid>
            </Grid>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.block}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <Typography className={cls.key}>Type:&nbsp;</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography className={cls.val}>
                  {move.raterule && move.raterule.type
                    ? `${move.raterule.type === 'per' ? 'Per Mile' : move.raterule.type}`
                    : `N/A`}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={cls.break} />
          <Button
            variant='outlined'
            size='small'
            style={{ width: '100%', color: ctx.theme.palette.text.primary }}
            onClick={() => viewTookanImages()}
          >
            View Vehicle Images
          </Button>
          {carousel && <Carousel images={images} setCarousel={setCarousel} />}
        </Grid>
        <Grid
          item
          xs={4}
          className={
            selectedRevision.disputed ? cls.revDispute : currentRevision === revisionNum ? cls.charge : cls.rev
          }
        >
          <Typography className={cls.main}>Charge Details</Typography>
          <Tooltip title={`Move\xa0Options`}>
            <ContextMenuTrigger
              id={`ar-move-${armove.id}-detail-option-cm`}
              source={`ar-move-${armove.id}-detail-option-cm`}
              ref={c => (row.contextTrigger = c)}
              holdToDisplay={1000}
              collect={collect}
              disableIfShiftIsPressed={true}
            >
              <Tooltip title={`Options`}>
                <IconButton className={cls.options} onClick={handleOptionsOpen}>
                  <Icon>settings</Icon>
                </IconButton>
              </Tooltip>
            </ContextMenuTrigger>
          </Tooltip>
          {actions && actions.length > 0 && (
            <ContextMenu id={`ar-move-${armove.id}-detail-option-cm`} style={{ boxShadow: '2px 4px 12px #00000050' }}>
              {actions.map(
                action =>
                  !action.hide && (
                    <MenuItem
                      data={{ action: action, ...action.data }}
                      onClick={action.handler}
                      key={`armd-${action.name}`}
                    >
                      {action.label}
                    </MenuItem>
                  )
              )}
            </ContextMenu>
          )}
          {revisions.length > 0 && (
            <>
              <div className={cls.smolBreak} />
              <TextField
                select
                name='revision'
                label='Revision'
                margin='dense'
                variant='outlined'
                style={{ width: '100%' }}
                value={revisionNum}
                onChange={event => setRevisionNum(event.target.value)}
              >
                <MenuItem value={currentRevision}>Current Revision #{currentRevision}</MenuItem>
                {revisions.map(item => (
                  <MenuItem key={item.revision} value={item.revision}>
                    Revision #{item.revision}
                  </MenuItem>
                ))}
              </TextField>
              <div className={cls.break} />
            </>
          )}
          {selectedDetails.map((detail, i) => {
            return (
              <React.Fragment key={i}>
                <div className={cls.block}>
                  <Typography className={cls.beeg}>{capFirst(detail.name)}</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={9}>
                      <Typography className={cls.smol + ' ' + cls.typeLeft}>{detail.notes}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={cls.smol + ' ' + cls.typeRight}>${detail.amount.toFixed(2)}</Typography>
                    </Grid>
                  </Grid>
                </div>
                <div className={cls.lineBreak} />
              </React.Fragment>
            );
          })}
          {selectedRevision.discount_amount > 0 && (
            <>
              <div className={cls.block}>
                <Typography className={cls.beeg}>Discount</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={9}>
                    <Typography className={cls.smol + ' ' + cls.typeLeft}>
                      {selectedRevision.discount_reason}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={cls.smol + ' ' + cls.typeRight}>
                      ${selectedRevision.discount_amount.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div className={cls.lineBreak} />
            </>
          )}
          <div className={cls.smolBreak} />
          <Grid container spacing={0}>
            <Grid item xs>
              <Typography className={cls.status}>Status:&nbsp;{capFirst(selectedRevision.status)}</Typography>
            </Grid>
            <Grid item>
              <Typography className={cls.totalKey}>Total:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.totalKey}>Amount&nbsp;Paid:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.totalKey}>Amount&nbsp;Due:&nbsp;&nbsp;&nbsp;</Typography>
            </Grid>
            <Grid item>
              <Typography className={cls.totalVal}>${checkNeg(total).toFixed(2)}</Typography>
              <Typography className={cls.totalVal}>${checkNeg(paid).toFixed(2)}</Typography>
              <Typography className={cls.totalVal}>${checkNeg(due).toFixed(2)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'fit-content',
    background: theme.palette.background.light,
    padding: theme.spacing(2),
  },
  charge: {
    position: 'relative',
    background: theme.palette.background.dark,
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  rev: {
    position: 'relative',
    background: theme.palette.warning.veryLight,
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  revDispute: {
    position: 'relative',
    background: theme.palette.error.veryLight,
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  options: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  main: {
    marginBottom: theme.spacing(0.75),
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  key: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  val: {
    color: theme.palette.text.primary,
    textAlign: 'right',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  status: {
    color: theme.palette.text.primary,
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 600,
  },
  totalKey: {
    color: theme.palette.text.primary,
    lineHeight: 1.25,
    textAlign: 'right',
    fontSize: '10px',
    fontWeight: 400,
  },
  totalVal: {
    color: theme.palette.text.primary,
    lineHeight: 1.25,
    textAlign: 'right',
    fontSize: '10px',
    fontWeight: 600,
  },
  beeg: {
    color: theme.palette.text.primary,
    fontSize: '12px',
    lineHeight: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  smol: {
    color: theme.palette.text.primary,
    fontSize: '9px',
    lineHeight: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px',
      lineHeight: '9px',
    },
  },
  typeLeft: {
    textAlign: 'left',
  },
  typeCenter: {
    textAlign: 'center',
  },
  typeRight: {
    textAlign: 'right',
  },
  ellipse: {
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  block: {
    display: 'block',
  },
  inline: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  smolBreak: {
    width: '100%',
    height: theme.spacing(0.75),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    margin: '4px 0',
    background: theme.palette.divider,
  },
}));
