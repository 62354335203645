import gql from 'graphql-tag';

// ------------------------------------------------- Move Object -------------------------------------------------
const moveFragment = () => gql`
  fragment Move on moves {
    id
    active
    auto_assign
    cancel_status
    class
    consumer_pickup
    consumer_name
    consumer_phone
    consumer_at_pickup
    consumer_type
    createdat
    customer_id
    dealer_contact
    delivery_arrived
    deliver_by
    delivery_started
    delivery_stop_id
    delivery_successful
    delivery_time
    driver_name
    driver_id
    lane_id
    lane {
      ...Lane
    }
    lyft_flag
    lyft_trigger_id
    lyft_trigger_move {
      id
      driver_name
    }
    movesByLyftTriggerId {
      ...NestedMove
    }
    parent_move {
      id
      cancel_status
      consumer_pickup
      consumer_name
      consumer_phone
      consumer_at_pickup
      consumer_type
      driver_name
      manual_flag
      reference_num
      sequence
      status
      vehicle_stock
      vehicle_vin
      vehicle_make
      vehicle_model
      vehicle_year
      vehicle_color
    }
    manual_flag
    move_details
    move_failed
    move_type
    pickup_arrived
    pickup_started
    pickup_stop_id
    pickup_successful
    pickup_time
    plan_id
    plan {
      plan_date
      driver_id
      driver_name
    }
    rate_class_override
    ready_by
    reference_num
    return_ride_id
    ride_type
    sequence
    status
    tookan_relationship_id
    tracking_link
    vehicle_color
    vehicle_image
    vehicle_make
    vehicle_model
    vehicle_odometer
    vehicle_stock
    vehicle_vin
    vehicle_year
    tags
    sequence
    synced_with_tookan
    priority
    pinnable
    lyftride {
      ...Lyftride
    }
    actual_pickup_mileage
    actual_delivery_mileage
    chargeable
    updatedat
    payable
    appayments {
      ...APPayment
      accessorial {
        ...Accessorials
      }
    }
    moveByReturnRideId {
      manual_flag
      reference_num
      vehicle_stock
      vehicle_vin
      vehicle_make
      vehicle_model
      vehicle_year
      vehicle_color
      move_type
      appayments {
        ...APPayment
        accessorial {
          ...Accessorials
        }
      }
    }
  }
  ${laneFragment()}
  ${lyftridesFragment()}
  ${nestedMoveFragment()}
  ${appaymentFragment()}
  ${accessorialsFragment()}
`;
// ------------------------------------------------- Favorite Location Object -------------------------------------------------
const favoriteLocationFragment = () => gql`
  fragment FavoriteLocation on favoritelocations {
    customer_id
    location_id
    location {
      ...Location
    }
  }
  ${locationFragment()}
`;
// ------------------------------------------------- Favorite Lane Object -------------------------------------------------
const favoriteLaneFragment = () => gql`
  fragment FavoriteLane on favoritelanes {
    lane_id
    customer_id
    lane {
      ...Lane
    }
  }
  ${laneFragment()}
`;
// ------------------------------------------------- Location Object -------------------------------------------------
const locationFragment = () => gql`
  fragment Location on locations {
    id
    active
    address
    createdat
    customer_id
    customer {
      ...Customer
    }
    email
    favorite
    latitude
    longitude
    name
    nickname
    phone
    notes
    place_id
    region_id
    region {
      ...Region
    }
    tookan_id
    type
    updatedat
  }
  ${customerFragment()}
  ${regionFragment()}
`;
// ------------------------------------------------- Lane Objects -------------------------------------------------
const laneFragment = () => gql`
  fragment Lane on lanes {
    id
    active
    description
    createdat
    customer_id
    customer {
      ...Customer
    }
    pickup {
      ...Location
    }
    delivery {
      ...Location
    }
    average_drive_speed_min_per_mile
    average_drive_speed_mph
    dealer_base_discount
    dealer_base_price
    dealer_base_rate
    dealer_base_rate_type
    dealer_stranded_discount
    dealer_stranded_price
    dealer_stranded_rate
    dealer_stranded_rate_type
    delivery_inspection_sec
    destination_location_id
    distance_miles
    driver_base_pay
    driver_base_pay_discount
    driver_drive_pay
    driver_pay_per_kilometer
    driver_pay_per_mile
    driver_pay_per_minute
    driver_rake
    driver_return_pay
    driver_return_pay_discount
    driver_time_pay
    duration_sec
    estimated_rideshare_return_cost
    insurance_cost
    insurance_cost_per_mile
    origin_location_id
    pickup_inspection_sec
    return_ride_wait_sec
    tolls
    updatedat
  }
  ${customerFragment()}
  ${locationFragment()}
`;
// -------------------------------------------- Lane w/o nested fields --------------------------------------------
const simpleLaneFragment = () => gql`
  fragment SimpleLane on lanes {
    id
    active
    description
    createdat
    customer_id
    average_drive_speed_min_per_mile
    average_drive_speed_mph
    dealer_base_discount
    dealer_base_price
    dealer_base_rate
    dealer_base_rate_type
    dealer_stranded_discount
    dealer_stranded_price
    dealer_stranded_rate
    dealer_stranded_rate_type
    delivery_inspection_sec
    destination_location_id
    distance_miles
    driver_base_pay
    driver_base_pay_discount
    driver_drive_pay
    driver_pay_per_kilometer
    driver_pay_per_mile
    driver_pay_per_minute
    driver_rake
    driver_return_pay
    driver_return_pay_discount
    driver_time_pay
    duration_sec
    estimated_rideshare_return_cost
    insurance_cost
    insurance_cost_per_mile
    origin_location_id
    pickup_inspection_sec
    return_ride_wait_sec
    tolls
    updatedat
  }
`;
// ------------------------------------------------- Customer Object -------------------------------------------------
const customerFragment = () => gql`
  fragment Customer on customers {
    active
    address
    branded
    config
    email
    id
    name
    phone
    createdat
    updatedat
    pickup_template_name
    delivery_template_name
    billing_frequency
    payment_terms
    auto_pay
    accounting_id
    notify_billing
  }
`;
// ------------------------------------------------- Customer Nested Object -------------------------------------------------
const customerWithRatingFragment = () => gql`
  fragment CustomerWithRating on customers {
    active
    address
    branded
    config
    email
    id
    name
    phone
    createdat
    updatedat
    pickup_template_name
    delivery_template_name
    billing_frequency
    payment_terms
    auto_pay
    accounting_id
    notify_billing
    raterulegroups(order_by: {end_date: desc}) {
      id
      customer_id
      name
      description
      begin_date
      end_date
      createdat
      updatedat
      raterules(where: {active: {_eq: "1"}}, order_by: {distance_start: asc}) {
        id
        customer_id
        rate_rule_group_id
        distance_start
        distance_end
        type
        rate
        class
      }
    }
    raterules(where: {active: {_eq: "1"}}, order_by: {distance_start: asc}) {
      id
      customer_id
      rate_rule_group_id
      distance_start
      distance_end
      type
      rate
      class
    }
  }
`;
// ------------------------------------------------- Rate Rules -------------------------------------------------
const raterulesFragment = () => gql`
  fragment Raterules on raterules {
    active
    class
    createdat
    customer_id
    distance_end
    distance_start
    id
    pay_rate_group_id
    rate
    rate_rule_group_id
    type
    updatedat
  }
`;
// ------------------------------------------ Plan Object With Active Moves ------------------------------------------
const planFragment = () => gql`
  fragment Plan on plans {
    id
    driver_id
    driver_name
    createdat
    updatedat
    plan_date
    region_id
    parked_location
    region {
      ...Region
    }
    moves (where: {active: {_eq: 1}}) {
      ...Move
    }
    moves_aggregate (where: {active: {_eq: 1}}) {
      aggregate {
        count
      }
    }
  }
${moveFragment()}
${regionFragment()}
`;
// ------------------------------------------ Lyftrides Object With Attempts ------------------------------------------
const lyftridesFragment = () => gql`
  fragment Lyftride on lyftrides {
    move_id
    passenger_phone
    pickup_location_type
    latitude
    longitude
    last_attempt
    createdat
    updatedat
    activeAttempt {
      ...Lyftrideattempts
    }
    attempts: attempts(order_by: {createdat: desc_nulls_last}) {
      ...Lyftrideattempts
    }
  }
  ${lyftrideattemptsFragment()}
`;
// ------------------------------------ Lyftrideattempts Object with current and all ----------------------------------
const lyftrideattemptsFragment = () => gql`
  fragment Lyftrideattempts on lyftrideattempts {
      id
      active
      move_id
      origin_location_id
      destination_location_id
      driver_first_name
      driver_id
      driver_phone
      driver_rating
      driver_vehicle_color
      driver_vehicle_license_plate
      driver_vehicle_license_plate_state
      driver_vehicle_make
      driver_vehicle_model
      driver_vehicle_year
      estimated_cost_max
      estimated_cost_min
      estimated_ride_cost
      passenger_phone
      ride_cost
      ride_distance
      ride_duration
      ride_id
      ride_type
      status
      stop_id
      surge_cost_percentage
      createdat
      updatedat
      requested_at
      requester
  }
`;
// ------------------------------------ Lyftrideattempts Object with current and all ----------------------------------
const nestedMoveFragment = () => gql`
  fragment NestedMove on moves {
    id
    driver_id
    driver_name
    parent_move {
      id
    }
  }
`;
// -------------------------------------------------- Regions Object  -------------------------------------------------
const regionFragment = () => gql`
  fragment Region on regions {
    id
    team_id
    name
    description
    geofence
    createdat
    updatedat
    last_synced
    region_id
    accounting_class_num
  }
`;
// -------------------------------------------------- Regions Object  -------------------------------------------------
const accessorialsFragment = () => gql`
  fragment Accessorials on accessorials {
    id
    move_id
    code
    status
    notes
    cost
    ap_amount
    ar_amount
  }
`;
// -------------------------------------------------- Regions Object  -------------------------------------------------
const appaymentFragment = () => gql`
  fragment APPayment on appayments {
    active
    id
    driver_id
    move_id
    status
    ach_transaction_id
    amount
    createdat
    updatedat
    notes
    author
    type
    accessorial_id
  }
`;

const fragments = {
  move: moveFragment(),
  favoriteLocation: favoriteLocationFragment(),
  favoriteLane: favoriteLaneFragment(),
  location: locationFragment(),
  lane: laneFragment(),
  simpleLane: simpleLaneFragment(),
  customer: customerFragment(),
  raterules: raterulesFragment(),
  plan: planFragment(),
  lyftrides: lyftridesFragment(),
  lyftrideattempts: lyftrideattemptsFragment(),
  nestedMove: nestedMoveFragment(),
  region: regionFragment(),
  accessorials: accessorialsFragment(),
  appayment: appaymentFragment(),
  customerWithRating: customerWithRatingFragment(),
};

export default fragments;