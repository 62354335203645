import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../global-context';
import { Typography, InputLabel, OutlinedInput, InputAdornment, Grid, DialogTitle, DialogContent, DialogContentText, FormControl, FormGroup, FormControlLabel, Dialog, DialogActions, Button, Switch } from '@material-ui/core';
import BigButtonGroup from './BigButtonGroup';
import BigButton from './BigButton';
// import ValueListItem from './ValueListItem';
import sdk from "@hopdrive/sdk";

let log = false;

////////// TOOLS //////////
// const capFirst = str => {
//   if (str) return str.charAt(0).toUpperCase() + str.slice(1);
// };

/**
  * Add or remove spend authorizations to a move
  * @param {BigInteger} moveId  - Requires a move ID passed in as a prop
  * @param {Boolean} open - Pass in a boolean value of true to render the dialog
  * @param {Function} close - Function to toggle the 'open' value from true to false
*/
const SpendAuthorization = ({ open, close, save, moveId, pickupTime, durationSec, driverPayPerMinute, customerId, declinedExpiredAccs }) => {
  // const cls = useStyles();
  const ctx = useContext(GlobalContext)

  const [type, setType] = useState(sdk.accessorials.types.FUEL.code)
  const [maxSpend, setMaxSpend] = useState(0)
  const [isUsingCompanyCard, setIsUsingCompanyCard] = useState(true)
  // const [selectedTab, setSelectedTab] = useState(0)
  const [accessorial, setAccessorial] = useState(null)
  const [fuelMultiplier, setFuelMultiplier] = useState(0)
  // const [retry, setRetry] = useState(false)
  // const [retryAcc, setRetryAcc] = useState('')

  useEffect(() => {
    log && console.log("MoveID changed", moveId)
  }, [moveId])

  useEffect(() => {
    log && console.log("CustomerID changed", customerId)
    const fetchConfig = async () => {
      const customerConfig = await sdk.customers.getConfig(customerId);
      setFuelMultiplier(customerConfig.fuel_multiplier);
    }
    fetchConfig()
  }, [customerId])

  const handleTypeChange = (id, title) => {
    setType(id)
    console.log(`${title} type selected`)
  }

  const buildObjectOnUpdate = (type, maxSpend, isUsingCompanyCard, notes) => {
    let accessorial
    switch (type) {
      case sdk.accessorials.types.FUEL.code:
        accessorial = sdk.accessorials.buildFuelAccessorial({
          moveId: moveId,
          cost: maxSpend,
          fuelMultiplier: fuelMultiplier,
          calcDriverTimePay: true,
          payPerMinute: driverPayPerMinute,
          durationSeconds: 300,
          costBearer: isUsingCompanyCard ? 'company' : 'driver',
          notes: `Spend authorization from modal` + notes
        })
        if (isUsingCompanyCard) {
          // Set valid window from pickup time to estimated dropoff time with default buffer
          const start_time = sdk.utilities.getLocalISOString(sdk.utilities.addMinutes(0 - sdk.authorizations.DEFAULT_VALID_CHARGE_BUFFER_MINUTES, new Date(pickupTime)));
          const durationMin = durationSec / 60
          const end_time = sdk.utilities.getLocalISOString(sdk.utilities.addMinutes(Number(durationMin.toFixed()) + sdk.authorizations.DEFAULT_VALID_CHARGE_BUFFER_MINUTES, new Date(pickupTime)));
          accessorial.authorization = {
            data: sdk.authorizations.buildAuthorization({
              maxCharge: maxSpend,
              validFrom: start_time,
              validTo: end_time
            })
          }
        }
        return accessorial;
      default:
        accessorial = null
        break;
    }
  }

  ////////// RETRY FEATURE //////////
  // useEffect(() => {
  //   //change notes depending on cost bearer and retry toggle
  //   let notes
  //   if (!retry) {
  //     notes = `${isUsingCompanyCard ? ' using company credit card' : ' using driver personal funds'}`
  //   } else {
  //     notes = ` using company credit card, retrying Accessorial #${retryAcc.id}`;
  //   }
  //   //Build the returning object using values in state whenever components of it are updated
  //   setAccessorial(buildObjectOnUpdate(type, maxSpend, isUsingCompanyCard, notes))
  // }, [type, maxSpend, isUsingCompanyCard, retryAcc])

  useEffect(() => {
    //Build the returning object using values in state whenever components of it are updated
    setAccessorial(buildObjectOnUpdate(type, maxSpend, isUsingCompanyCard, `${isUsingCompanyCard ? ' using company credit card' : ' using driver personal funds'}`))
  }, [type, maxSpend, isUsingCompanyCard])


  const handleSave = () => {
    console.log('Spend auth modal saved with the following: ', accessorial);
    sdk.configure({ apollo_client: ctx.apolloClient });
    sdk.accessorials.create(accessorial);
    save()
    close()
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="spend-authorization-modal"
    >
      <DialogTitle id="spend-authorization-modal">Spend Authorization</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Authorize the driver to spend some money on this move then record it as an accessorial.
        </DialogContentText>
        <BigButtonGroup onChange={handleTypeChange} preselect={type}>
          <BigButton id={sdk.accessorials.types.FUEL.code} title="Fuel" subtitle="Put gas in the vehicle being moved" />
          <BigButton disabled id={sdk.accessorials.types.TOW.code} title="Tow" subtitle="Prepay for a tow of the vehicle being moved" />
          <BigButton disabled id={'other'} title="Other" subtitle="Other misc spend type" />
        </BigButtonGroup>
        <FormControl>
          <FormGroup>
            <Grid container spacing={4}>

              {/* ////////// RETRY FEATURE //////////
              {!retry ? ( */}
                <>
                  <Grid item md={6} xs={12}>
                    <Typography variant="subtitle2" display="block" gutterBottom>Who is initially covering the cost of the fuel? </Typography>
                    <Typography variant="body2" display="block" gutterBottom>If the driver has a credit card provided by us and you
                      intend for them to use it, then leave this enabled. If instead the driver will need to use their
                      own personal funds and then be reimbursed on their driver pay, then disable this toggle.</Typography>
                    <FormControlLabel
                      control={<Switch checked={isUsingCompanyCard} onChange={e => { setIsUsingCompanyCard(e.target.checked) }} name="checkedA" />}
                      label={`Use Company Fuel Card${!isUsingCompanyCard ? ' (Driver will use their personal funds)' : ''}`}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="subtitle2" display="block" gutterBottom>What's the max dollar amount you want to authorize? </Typography>
                    <Typography variant="body2" display="block" gutterBottom style={{ marginBottom: '20px' }}>For swiping at the pump,
                      this should work like prepaying inside and automatically stop the pump when it reaches this amount.</Typography>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="max-spend">Max Authorized Spend</InputLabel>
                      <OutlinedInput
                        id="max-spend"
                        value={maxSpend}
                        onChange={(e) => setMaxSpend(e.target.value)}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={175}
                      />
                    </FormControl>
                  </Grid>
                </>
              {/* ////////// RETRY FEATURE //////////
              ) : (
                <>
                  <Grid item md={6} xs={12}>
                    <Typography variant="subtitle2" display="block" gutterBottom>Who is initially covering the cost of the fuel? </Typography>
                    <Typography variant="body2" display="block" gutterBottom>If the driver has a credit card provided by us and you
                      intend for them to use it, then leave this enabled. If instead the driver will need to use their
                      own personal funds and then be reimbursed on their driver pay, then disable this toggle.</Typography>
                    <FormControlLabel disabled
                      control={<Switch checked={isUsingCompanyCard} name="checkedA" />}
                      label={`Use Company Fuel Card${!isUsingCompanyCard ? ' (Driver will use their personal funds)' : ''}`}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="subtitle2" display="block" gutterBottom>What's the max dollar amount you want to authorize? </Typography>
                    <Typography variant="body2" display="block" gutterBottom style={{ marginBottom: '20px' }}>For swiping at the pump,
                      this should work like prepaying inside and automatically stop the pump when it reaches this amount.</Typography>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="max-spend">Max Authorized Spend</InputLabel>
                      <OutlinedInput
                        disabled
                        id="max-spend"
                        value={maxSpend}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={175}
                      />
                    </FormControl>
                  </Grid>
                </>
              )} 
              {declinedExpiredAccs.length > 0 && isUsingCompanyCard ? (
                <>
                  <Grid item md={12} xs={12}>
                    <Typography variant="subtitle2" display="block" gutterBottom>Try Again?</Typography>
                    <Typography variant="body2" display="block" gutterBottom style={{ marginBottom: '20px' }}>Select a previously declined or expired accessorial to retry the transaction by creating a copy.</Typography>
                    <FormControlLabel
                      control={<Switch checked={retry} onChange={e => {
                        if (retry) {
                          setRetryAcc('')
                        }
                        setRetry(e.target.checked)
                      }
                      } name="checkedB" />}
                      label={`Retry Accessorial`}
                    />

                    {retry ? (
                      <TextField
                        select
                        fullWidth
                        label='Declined and Expired Accessorials'
                        margin='dense'
                        variant='outlined'
                        value={retryAcc}
                        onChange={event => {
                          setRetryAcc(event.target.value)
                          const { code, authorization: { max_charge } } = event.target.value

                          setType(code)
                          setMaxSpend(max_charge)
                          setIsUsingCompanyCard(true)
                        }
                        }
                      >
                        {declinedExpiredAccs.map(item => (
                          <MenuItem key={`acc-${item.id}`} value={item}>
                            Accessorial #{item.id} - {capFirst(item.code)} ({capFirst(item.status)})
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : null}
                  </Grid>
                </>
              ) : null} */}
              {/* ////////// DEBUG ////////// 
               <Grid item md={12} xs={12}>
                <Tabs
                  value={selectedTab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(e, v) => setSelectedTab(v)}
                  aria-label="Accessorial preview"
                >
                  <Tab label="Preview" />
                  <Tab label="Source" />
                </Tabs>
                {selectedTab == 0 &&
                  <>
                    <ValueListItem label="Status" gray value={capFirst(accessorial && accessorial.status)} divider />
                    <ValueListItem label="Cost" value={accessorial && accessorial.cost && '$' + accessorial.cost.toFixed(2)} divider />
                    <ValueListItem label="Driver Pay Per Minute" gray value={accessorial && accessorial.ap_amount && '$' + driverPayPerMinute.toFixed(2)} divider />
                    <ValueListItem label="Payable (Driver)" value={accessorial && accessorial.ap_amount && '$' + accessorial.ap_amount.toFixed(2)} divider />
                    <ValueListItem label="Fuel Multiplier" gray value={accessorial && fuelMultiplier} divider />
                    <ValueListItem label="Chargeable (Customer)" value={accessorial && accessorial.ar_amount && '$' + accessorial.ar_amount.toFixed(2)} divider />
                    <ValueListItem label="Notes" gray value={accessorial && accessorial.notes} divider />
                  </>}
                {selectedTab == 1 &&
                  <>
                    <pre>{JSON.stringify(accessorial, null, 2)}</pre>
                  </>}
              </Grid> */}
            </Grid>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        {/* ////////// RETRY FEATURE //////////
        <Button disabled={maxSpend <= 0 || (retry === true && retryAcc === '')} onClick={handleSave} color="primary" autoFocus> */}
        <Button disabled={maxSpend <= 0} onClick={handleSave} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog >
  )
}

export default SpendAuthorization;

// const useStyles = makeStyles(theme => ({
//   margin: {
//     marginBottom: '15px'
//   },
// }));