import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/utils/PrivateRoute";
import LoginPage from "./components/pages/Login";
import PublicPage from "./components/pages/Public";
import ProfilePage from "./components/pages/Profile";
import Dashboard from "./components/pages/Dashboard";
import PlansPage from "./components/pages/Plans";
import MovesPage from "./components/pages/Moves";
import SearchResults from "./components/pages/SearchResults";
import MoveCalculator from "./components/pages/MoveCalculator";
import MoveDetails from "./components/pages/MoveDetails";
import MoveEntry from "./components/pages/MoveEntry";
import MoveCreateEdit from "./components/pages/MoveCreateEdit";
import MovePlanner from "./components/pages/MovePlanner";
import MapPage from "./components/pages/Map";
import LanesPage from "./components/pages/Lanes";
import LaneDetails from "./components/pages/LaneDetails";
import LaneEntry from "./components/pages/LaneEntry";
import LocationsPage from "./components/pages/Locations";
import LocationDetails from "./components/pages/LocationDetails";
import RegionsPage from "./components/pages/Regions";
import RegionDetails from "./components/pages/RegionDetails";
import RegionEntry from "./components/pages/RegionEntry";
import CustomersPage from "./components/pages/Customers";
import CustomerEntry from "./components/pages/CustomerEntry";
import CustomerDetails from "./components/pages/CustomerDetails";
import CustomerPricing from "./components/pages/CustomerPricing";
import ARInvoicesPage from "./components/pages/ARInvoices";
import Disputes from "./components/pages/Disputes";
import ZeroDollars from "./components/pages/ZeroDollars";
import ARReportMovesPage from "./components/pages/ARReportMoves";
import ARReportPaymentsPage from "./components/pages/ARReportPayments";
import DriverPayReview from "./components/pages/DriverPayReview";
import APReportPage from "./components/pages/APReport";
import DriverPay2 from "./components/pages/DriverPay2";
import DriverEmails from "./components/pages/DriverEmails";
import DriverSchedule from "./components/pages/DriverSchedule";
import RulesPage from "./components/pages/BusinessRules.js";
import RulesForm from "./components/pages/businessRules/RulesForm.js";
import FaqManagerPage from "./components/pages/FaqManager";
import AccessorialReport from "./components/pages/AccessorialReport";
import Promos from "./components/pages/Promos";
import PromoForm from "./components/pages/promos/PromoForm";

import InvoicesPage from "./components/pages/Invoices";
import DriverPayPage from "./components/pages/DriverPay";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        
        <Route path="/" exact component={PublicPage} />
        <Route path="/login" exact component={LoginPage} />
        <PrivateRoute path="/profile" exact component={ProfilePage} />

        <PrivateRoute path="/dashboard" exact component={Dashboard} />
        <PrivateRoute path="/plans" exact component={PlansPage} />
        <PrivateRoute path="/moves" exact component={MovesPage} />
        <PrivateRoute path="/moves/searchresults/" exact component={(props) => <SearchResults {...props} />} />
        <PrivateRoute path="/moves/calculator/" exact component={MoveCalculator}/>
        <PrivateRoute path="/moves/add/" exact component={MovePlanner} />
        <PrivateRoute path="/moves/add-multiple" exact component={MoveEntry} />
        <PrivateRoute path="/moves/:id/" exact component={MoveDetails} />
        <PrivateRoute path="/moves/:id/edit/" exact component={MoveCreateEdit} />
        <PrivateRoute path="/map" exact component={MapPage} />
        <PrivateRoute path="/map/lanes/:id" exact component={MapPage} />
        <PrivateRoute path="/map/locations/:id" exact component={MapPage} />
        <PrivateRoute path="/lanes" exact component={LanesPage} />
        <PrivateRoute path="/lanes/add" exact component={LaneEntry} />
        <PrivateRoute path="/lanes/:id/edit/" exact component={LaneEntry} />
        <PrivateRoute path="/lanes/:id/" exact component={LaneDetails} />
        <PrivateRoute path="/locations" exact component={LocationsPage} />
        <PrivateRoute path="/locations/:id/" exact component={LocationDetails} />
        <PrivateRoute path="/regions" exact component={RegionsPage} />
        <PrivateRoute path="/regions/add" exact component={RegionEntry} />
        <PrivateRoute path="/regions/:id/edit/" exact component={RegionEntry} />
        <PrivateRoute path="/regions/:id/" exact component={RegionDetails} />
        <PrivateRoute path="/customers" exact component={CustomersPage} />
        <PrivateRoute path="/customers/add" exact component={CustomerEntry} />
        {/* <PrivateRoute path="/customers/:id/edit/" exact component={CustomerEntry} /> */}
        <PrivateRoute path="/customers/:id/" exact component={CustomerDetails} />
        {/* <PrivateRoute path="/customers/:id/pricing" exact component={CustomerPricing} /> */}
        <PrivateRoute path="/invoices" exact component={ARInvoicesPage} />
        <PrivateRoute path="/disputes" exact component={Disputes} />
        <PrivateRoute path="/zero-dollars" exact component={ZeroDollars} />
        <PrivateRoute path="/ar-report/moves" exact component={ARReportMovesPage} />
        <PrivateRoute path="/ar-report/payments" exact component={ARReportPaymentsPage} />
        <PrivateRoute path="/accessorial-report/" exact component={AccessorialReport} />
        <PrivateRoute path="/driver-pay" exact component={DriverPayReview} />
        <PrivateRoute path="/ap-report" exact component={APReportPage} />
        <PrivateRoute path="/driverpay2" exact component={DriverPay2} />
        <PrivateRoute path="/driver-emails" exact component={DriverEmails} />
        <PrivateRoute path="/driver-schedule" exact component={DriverSchedule} />
        <PrivateRoute path="/rules" exact component={RulesPage} />
        <PrivateRoute path="/rules/add" exact component={RulesForm} />
        <PrivateRoute path="/rules/:id/" exact component={RulesForm} />
        <PrivateRoute path="/promos" exact component={Promos} />
        <PrivateRoute path="/promos/add" exact component={PromoForm} />
        <PrivateRoute path="/promos/:id/" exact component={PromoForm} />
        <PrivateRoute path="/faq-manager" exact component={FaqManagerPage} />

        <PrivateRoute path="/invoices-legacy" exact component={InvoicesPage} />
        <PrivateRoute path="/driver-pay-legacy" exact component={DriverPayPage} />

      </Switch>
    );
  }
}

export default Routes;
