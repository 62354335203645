import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from '../../global-context';
import { makeStyles, Container, Typography, Grid, Chip, CircularProgress, TextField, MenuItem, Tooltip, Button, Icon, Box } from '@material-ui/core';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Loading from '../utils/Loading';
import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';
import { ExportToCsv } from 'export-to-csv';
import DriverSelect from '../reusable/DriverSelect';
import RegionSelect from '../reusable/RegionSelect';
import PayPeriodDetailTable from './driverPay2/PayPeriodDetailTable';
import SendEmailButton from './driverPay2/SendEmailButton';
import MarkAsPaidButton from './driverPay2/MarkAsPaidButton';

const log = false;

const cap = (str) => {
  if (str) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map((s) => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
}

const checkNeg = (num) => {
  if (num > 0) return num;
  else return 0;
}

const round = (num, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  const output = Math.round(num * multiplier) / multiplier;
  return output;
}

const defaultOrder = `desc`;
const defaultOrderBy = `ID`;

const getPayPeriodId = (date) => {
  // Rob 20201231 - The weekyear produced here by moment does not always
  //  match the weekyear produced by the Postgres database
  //
  //  In our driver pay sql view we use the following SQL to determine the 
  //  weekyear then aggregate driver pay around that weekyear id.
  //
  //    to_char(testDate + INTERVAL '1 day', 'IYYY-IW')
  //
  //  For Sunday 2020/12/27, the above SQL returns 2020-53 while if left
  //  to it's default settings compare that to what moment() calcs from
  //  the following Javascript using moment's week() and weekYear().
  //
  //    `${moment().weekYear()}-${String(moment().week()).padStart(2, '0')}`
  //
  //  For the same Sunday 2020/12/27, the above moment() returns 2021-01.
  //  The reason for this is because the day weeks start with and the day
  //  years start with in the default moment local configuration.
  //
  // From the postgres docs:
  //   The number of the ISO 8601 week-numbering week of the year. By
  //   definition, ISO weeks start on Mondays and the first week of a year
  //   contains January 4 of that year. In other words, the first Thursday of
  //   a year is in week 1 of that year.
  //
  // From the moment docs:
  //   For example, in the United States, Sunday is the first day of the week.
  //   The week with January 1st in it is the first week of the year. In France,
  //   Monday is the first day of the week, and the week with January 4th is
  //   the first week of the year.
  //
  // The solution ended up being that the locale needs to be set globally for moment
  //  so we move the moment.locale call out to the global scope of the module and
  //  then everything works. What it's doing in this call is to set the starting
  //  day of the week to Sunday and the starting day of the year to Jan 4th to align
  //  with how Postgres views the date world.
  //
  // Here are some resources that helped me uncover the solution:
  //  https://stackoverflow.com/questions/18875649/starting-the-week-on-monday-with-isoweekday
  //  https://momentjs.com/docs/#/i18n/instance-locale/
  //  https://momentjs.com/docs/#/customization/dow-doy/
  return `${date.weekYear()}-${String(date.week()).padStart(2, '0')}`
}

moment.locale('en', {week: {dow: 0, doy: 4}})

function DriverPay2(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [sunday, setSunday] = useState(moment().startOf('week').subtract(7, 'days'))
  const [payPeriod, setPayPeriod] = useState(getPayPeriodId(moment()))

  const [status, setStatus] = useState(null);
  const localDriverId = parseInt(localStorage.getItem(`driverId`));
  const [driverId, setDriverId] = useState(localDriverId || null);
  const localRegionId = parseInt(localStorage.getItem(`regionId`));
  const [regionId, setRegionId] = useState(localRegionId || null);

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  useEffect(() => {
    localStorage.setItem(`driverId`, driverId)
  }, [driverId])

  useEffect(() => {
    localStorage.setItem(`regionId`, regionId)
  }, [regionId])

  useEffect(() => {
    setPayPeriod(getPayPeriodId(sunday.clone()))
  }, [sunday])

  const applyFilters = (data) => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(o => {
        if (
          (o.driver_name && o.driver_name.toLocaleLowerCase().includes(search)) ||
          (o.driver_id && (o.driver_id + ``).toLocaleLowerCase().includes(search)) ||
          (o.total_pay_amount && (o.total_pay_amount + ``).toLocaleLowerCase().includes(search)) ||
          (o.status && (o.status + ``).toLocaleLowerCase().includes(search))
        ) {
          return true;
        }
        else return false;
      })
    }
  }

  const generateCSV = (pay_periods_by_driver) => {
    const createCsvRow = (pay_period) => {
      return {
        DRIVER_ID: pay_period.driver_id,
        DRIVER_NAME: pay_period.driver_name || `HopDriver`,
        REGION: pay_period.region.name,
        MOVES: pay_period.move_count,
        STATUS: pay_period.status ? cap(pay_period.status) : `-`,
        TOTAL: pay_period.total_pay_amount ? `$${pay_period.total_pay_amount.toFixed(2)}` : (pay_period.total_pay_amount === 0 ? `$0.00` : `-`),
      }
    }
    const csvRows = pay_periods_by_driver.map(pay_period => createCsvRow(pay_period));
    const csvOptions = {
      filename: `${driverId ? pay_periods_by_driver[0].driver_name.replace(/ /g, "_") : `All`}_Driver_Pay_from_${sunday.format()}_to_${sunday.clone().endOf('week').format()}`,
      showTitle: true,
      title: `${driverId ? pay_periods_by_driver[0].driver_name : `All`} Driver Pay from ${sunday.format()} to ${sunday.clone().endOf('week').format()}`,
      useKeysAsHeaders: true,
    }

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  }

  const goToMoveDetails = (moveId) => {
    props.history.push(`/moves/${moveId}`);
  }

  const getTableActions = (payPeriods) => {
    return [
      { label: `Generate\xa0CSV`, handler: () => generateCSV(payPeriods) },
    ];
  }
  const getRowActions = (payPeriod) => {
    return [
      //{ name: `send-email`, label: `Send\xa0Email`, handler: () => { } },
      //{ name: `mark-as-paid`, label: `Mark\xa0as\xa0Paid`, handler: () => { } },
    ];
  }

  const handleStatusChange = event => {
    if (event.target.value !== `any`) setStatus(event.target.value);
    else setStatus(null);
  }

  const handleRegionChange = event => {
    if (event.target.value > 0) setRegionId(event.target.value);
    else setRegionId(null);
  }

  const handleDriverChange = event => {
    if (event.target.value > 0) setDriverId(event.target.value);
    else setDriverId(null);
  }

  const handlePayPeriodChange = (event) => {
    if (event.target.value === 'this-week') {
      setSunday(moment().startOf('week'))
    }
    if (event.target.value === 'last-week') {
      setSunday(moment().startOf('week').subtract(1, 'week'))
    }
    if (event.target.value === 'next-week') {
      setSunday(moment().startOf('week').add(1, 'week'))
    }
    if (event.target.value === 'two-weeks-ago') {
      setSunday(moment().startOf('week').subtract(2, 'weeks'))
    }
    if (event.target.value === 'this-week-last-year') {
      setSunday(moment().startOf('week').subtract(52, 'weeks'))
    }
    if (event.target.value === 'two-weeks-out') {
      setSunday(moment().startOf('week').add(2, 'weeks'))
    }
  }

  const getPayPeriodLabel = (date) => {
    if (date.format() === moment().startOf('week').format()) {
      return 'this-week'
    }
    if (date.format() === moment().startOf('week').subtract(1, 'week').format()) {
      return 'last-week'
    }
    if (date.format() === moment().startOf('week').add(1, 'week').format()) {
      return 'next-week'
    }
    if (date.format() === moment().startOf('week').subtract(2, 'weeks').format()) {
      return 'two-weeks-ago'
    }
    if (date.format() === moment().startOf('week').subtract(52, 'weeks').format()) {
      return 'this-week-last-year'
    }
    if (date.format() === moment().startOf('week').add(2, 'weeks').format()) {
      return 'two-weeks-out'
    }
    return null
  }

  const getDateRangeString = (start, end = null) => {
    if (end) {
      return <Typography fontWeight={800} variant="body1">{start.format('dddd')} {start.format('MMM D')} through {end.format('dddd')} {end.format('MMM D')} <em>({getPayPeriodId(start)})</em> </Typography>
    } else {
      return <em>&nbsp;{`(starting on ${start.format('dddd MMM D')})`}</em>
    }
  }
  
  const GET_PAY_PERIODS = gql`
    query pay_periods_by_driver($driverId: bigint, $regionId: bigint, $payPeriod: String, $status: String) {
      pay_periods_by_driver(where: {
        ${driverId ? 'driver_id: {_eq: $driverId},' : ''}
        ${payPeriod ? 'pay_period: {_eq: $payPeriod},' : ''}
        ${status ? 'status: {_eq: $status},' : ''}
        ${regionId ? 'region_id: {_eq: $regionId},' : ''}
      }, order_by: {driver_name: asc, pay_period: asc}) {
        driver_id
        driver_name
        region {
          name
        }
        pay_period
        move_count
        total_pay_amount
        status
        pickup_time_start
        pickup_time_end
      }
    }
  `;

  const getPayPeriodVariables = () => {
    //{ regionId: regionId, driverId: driverId, payPeriod: payPeriod, status: status }
    let variables = {};
    if (regionId) variables.regionId = regionId;
    if (driverId) variables.driverId = driverId;
    if (payPeriod) variables.payPeriod = payPeriod;
    if (status) variables.status = status;
    return variables;
  }

  const GET_PAYABLE_DRIVERS = gql`
    query payable_drivers($payPeriod: String, $status: String, $regionId: bigint, $start: timestamp, $end: timestamp) {
      pay_periods_by_driver(where: {
        ${payPeriod ? 'pay_period: {_eq: $payPeriod},' : ''}
        ${status ? 'status: {_eq: $status},' : ''}
        ${regionId ? 'region_id: {_eq: $regionId},' : ''}
      },
      distinct_on: [driver_id, driver_name]
      order_by: {driver_id: desc, driver_name: desc}) {
        driver_id
        driver_name
      }
    }
  `

  const getPayableDriversVariables = () => {
    //{ regionId: regionId, payPeriod: payPeriod, status: status }
    let variables = {};
    if (regionId) variables.regionId = regionId;
    if (payPeriod) variables.payPeriod = payPeriod;
    if (status) variables.status = status;
    return variables;
  }

  return (<>
    <div className={cls.root}>
      { ctx && ctx.userIsAuthenticated() && (
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item md={6} sm={6} xs={6}>
              <Typography className={cls.headTxt}>
                Driver Pay
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Typography variant="h6" style={{textAlign: 'right'}} gutterBottom>{getDateRangeString(sunday.clone(), sunday.clone().endOf('week'))}</Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Tooltip title={`${sunday.clone().subtract(1, 'week').format('MMM D')} - ${sunday.clone().subtract(1, 'week').endOf('week').format('MMM D')}`}>
                    <Button fullWidth style={{ height: '100%' }} variant="outlined" color="secondary" startIcon={<Icon>keyboard_arrow_left</Icon>} onClick={() => { setSunday(sunday.clone().subtract(1, 'week')) }}>
                      Prev
                      </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={8}>
                  <Tooltip title={`Select a predefined pay period`}>
                    <TextField
                      select
                      fullWidth
                      color="primary"
                      label="Pay Period"
                      placeholder="Select a pay period..."
                      variant="outlined"
                      margin="dense"
                      value={getPayPeriodLabel(sunday.clone()) || `custom`}
                      onChange={handlePayPeriodChange}
                      className={cls.input}
                    >
                      <MenuItem value={`this-week-last-year`}>This Week Last Year {getDateRangeString(moment().startOf('week').subtract(52, 'weeks'))}</MenuItem>
                      <MenuItem value={`two-weeks-ago`}>Two Weeks Ago {getDateRangeString(moment().startOf('week').subtract(2, 'weeks'))}</MenuItem>
                      <MenuItem value={`last-week`}>Last Week {getDateRangeString(moment().startOf('week').subtract(1, 'week'))}</MenuItem>
                      <MenuItem value={`this-week`}>This Week {getDateRangeString(moment().startOf('week').subtract(0, 'weeks'))}</MenuItem>
                      <MenuItem value={`next-week`}>Next Week {getDateRangeString(moment().startOf('week').add(1, 'weeks'))}</MenuItem>
                      <MenuItem value={`two-weeks-out`}>Two Weeks Out {getDateRangeString(moment().startOf('week').add(2, 'weeks'))}</MenuItem>
                      {/* <MenuItem value={`custom`}>Custom</MenuItem> */}
                    </TextField>
                  </Tooltip>
                </Grid>
                <Grid item xs={2}>
                  <Tooltip title={`${sunday.clone().add(1, 'week').format('MMM D')} - ${sunday.clone().add(1, 'week').endOf('week').format('MMM D')}`}>
                    <Button fullWidth style={{ height: '100%' }} variant="outlined" color="primary" endIcon={<Icon>keyboard_arrow_right</Icon>} onClick={() => { setSunday(sunday.clone().add(1, 'week')) }}>
                      Next
                      </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <RegionSelect
                allowAny
                label="Region Filter"
                value={regionId || 0}
                onChange={handleRegionChange}
              />
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <TextField
                select
                fullWidth
                label="Status Filter"
                placeholder="Select a status filter..."
                variant="outlined"
                margin="dense"
                value={status || `any`}
                className={cls.input}
                onChange={handleStatusChange}
              >
                <MenuItem value={`any`}>Any Status</MenuItem>
                <MenuItem value={`paid`}>Paid</MenuItem>
                <MenuItem value={`unpaid`}>Unpaid</MenuItem>
                <MenuItem value={`partial`}>Partial</MenuItem>
              </TextField>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <Query query={GET_PAYABLE_DRIVERS} variables={getPayableDriversVariables()}>
                {({ loading, error, data }) => {
                  if (error) console.error(`Failed to retrieve payable drivers:`, error)
                  if (loading) {
                    return (
                      <DriverSelect
                        selectAllItem
                        label="Driver Filter"
                        value={driverId || 0}
                        onChange={handleDriverChange}
                        drivers={[{ driver_id: null, driver_name: 'Loading...' }]}
                      />
                    )
                  } else if (data && data.pay_periods_by_driver && data.pay_periods_by_driver.length > 0) {
                    return (
                      <DriverSelect
                        selectAllItem
                        label="Driver Filter"
                        value={driverId || 0}
                        onChange={handleDriverChange}
                        drivers={data.pay_periods_by_driver}
                      />
                    )
                  } else return (
                    <DriverSelect
                      selectAllItem
                      label="Driver Filter"
                      value={driverId || 0}
                      onChange={handleDriverChange}
                    />
                  )
                }}
              </Query>
            </Grid>

          </Grid>

          <div className={cls.break} />

          <Query query={GET_PAY_PERIODS} variables={getPayPeriodVariables()} 
          //Was causing infinited reload on error
          // onError={(err) => ctx.handleNotifications(true, `error`, `Failed to retrieve pay_periods_by_driver: ${err.toString()}`)}
          >
            {({ loading, error, data, refetch }) => {
              if (loading) return <Loading fixed />
              if (error) {
                console.log(`Failed to retrieve pay_periods_by_driver:`, error);
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>ERROR FINDING AP RECORDS</Typography>
                  </div>
                )
              }
              if (data && data.pay_periods_by_driver && data.pay_periods_by_driver.length > 0) {
                const filteredPayPeriods = applyFilters(data.pay_periods_by_driver);

                // Set a consistent amount object that holds the totals
                var amount = {};

                // Valid moves to calculate base totals
                const subtotalPayments = filteredPayPeriods.filter(item => item.total_pay_amount > 0);
                const paidPayments = subtotalPayments.filter(item => item.status === `paid`);

                // Base totals from valid moves
                amount.subtotal = round(subtotalPayments.length > 0 ? subtotalPayments.map(item => item.total_pay_amount).reduce((total, current) => total + current) : 0, 2);
                amount.paid = round(paidPayments.length > 0 ? paidPayments.map(item => item.total_pay_amount).reduce((total, current) => total + current) : 0, 2);

                // Set calculations from base totals
                amount.total = checkNeg(amount.subtotal);
                amount.unpaid = checkNeg(amount.total - amount.paid);

                log && console.log(`AP Amount:`, amount);

                const headers = [
                  { id: `EARNINGS_NUM`, alignLeft: true, numeric: true, label: `Earnings\xa0Summary` },
                  { id: `DRIVER_NAME`, alignLeft: true, numeric: false, label: `Driver` },
                  { id: `REGION`, alignLeft: true, numeric: true, label: `Region` },
                  { id: `MOVES`, alignLeft: true, numeric: true, label: `Moves` },
                  { id: `STATUS`, alignLeft: true, numeric: false, label: `Status` },
                  { id: `TOTAL`, alignLeft: false, numeric: true, label: `Total` },
                  { id: `BUTTONS`, alignLeft: false, numeric: false, label: `Actions` },
                ]
                const rows = filteredPayPeriods.map(payPeriod => {
                  var {
                    driver_id = 0,
                    driver_name = '',
                    move_count = 0,
                    pay_period = '',
                    pickup_time_end = '',
                    pickup_time_start = '',
                    region = {},
                    status = '',
                    total_pay_amount = 0,
                  } = payPeriod || {};
                  payPeriod = { driver_id: driver_id ? driver_id : 0, driver_name: driver_name ? driver_name : '', move_count: move_count ? move_count : 0, pay_period: pay_period ? pay_period : '', pickup_time_end: pickup_time_end ? pickup_time_end : '', pickup_time_start: pickup_time_start ? pickup_time_start : '', region: region ? region : {}, status: status ? status : '', total_pay_amount: total_pay_amount ? total_pay_amount : 0 }
                  var {
                    name: regionName = ''
                  } = region || {};
                  region = { regionName: regionName ? regionName : '' };

                  return {
                    EARNINGS_NUM: `${driver_id}-${pay_period}`,
                    DRIVER_NAME: driver_name,
                    REGION: region && (regionName || ''),
                    MOVES: move_count,
                    STATUS: <Chip variant={status === 'paid' ? 'default' : 'outlined'} icon={<Icon fontSize='small'>{status === 'paid' ? 'done' : 'monetization_on'}</Icon>} size="small" color={status === 'unpaid' ? 'primary' : 'secondary'} label={status} />,
                    TOTAL: total_pay_amount ? `$${total_pay_amount.toFixed(2)}` : (total_pay_amount === 0 ? `$0.00` : `-`),
                    BUTTONS: <><SendEmailButton payPeriod={payPeriod} />&nbsp;&nbsp;<MarkAsPaidButton payPeriod={payPeriod} refetch={refetch} /></>,
                    payPeriod: payPeriod
                  }
                })

                return (<>
                  <AccordianTable
                    title={
                      <>
                        Found {data.pay_periods_by_driver.length} matching pay periods
                        <Typography variant="subtitle1" gutterBottom>
                          <Box position="relative" display="inline-flex" marginRight={2}>
                            <CircularProgress variant="determinate" value={Math.round(
                                (data.pay_periods_by_driver.filter(o => o.status === 'paid').length / data.pay_periods_by_driver.length)*100,
                              )} />
                            <Box
                              top={0}
                              left={0}
                              bottom={0}
                              right={0}
                              position="absolute"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                                (data.pay_periods_by_driver.filter(o => o.status === 'paid').length / data.pay_periods_by_driver.length)*100,
                              )}%`}</Typography>
                            </Box>
                          </Box>
                          <strong>Paid:</strong> {data.pay_periods_by_driver.filter(o => o.status === 'paid').length}&nbsp;|&nbsp;&nbsp;
                          <strong>Unpaid:</strong> {data.pay_periods_by_driver.filter(o => o.status === 'unpaid').length}&nbsp;|&nbsp;&nbsp;
                          <strong>Partial:</strong> {data.pay_periods_by_driver.filter(o => o.status === 'partial').length}
                        </Typography>
                      </>
                    }
                    headers={headers}
                    rows={rows}
                    actions={getTableActions(data.pay_periods_by_driver)}
                    search={search}
                    defaultOrder={defaultOrder}
                    defaultOrderBy={defaultOrderBy}
                    order={order}
                    orderBy={orderBy}
                    tablePage={tablePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    setSearch={setSearch}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    setTablePage={setTablePage}
                    setRowsPerPage={setRowsPerPage}
                    setExpandedRowId={setExpandedRowId}
                    className={cls.table}
                    refetch={refetch}
                    refreshPersistAs="ap_driver_pay"
                  >
                    {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                      .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                      .map(row => (
                        <AccordianRow
                          key={`ap-pay-period-${row.EARNINGS_NUM}`}
                          rowId={row.EARNINGS_NUM}
                          expandedRowId={expandedRowId}
                          setExpandedRowId={setExpandedRowId}
                          columns={[
                            { align: 'left', value: row.EARNINGS_NUM },
                            { align: 'left', value: row.DRIVER_NAME },
                            { align: 'left', value: row.REGION },
                            { align: 'left', value: row.MOVES },
                            { align: 'left', value: row.STATUS },
                            { align: 'right', value: row.TOTAL },
                            { align: 'right', value: row.BUTTONS, preventClick:true },
                          ]}
                          actions={getRowActions(row.payPeriod)}
                          className={cls.row}
                        >
                          <PayPeriodDetailTable
                            payPeriod={row.payPeriod}
                          />
                        </AccordianRow>
                    ))}
                  </AccordianTable>
                </>)
              }
              else return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>NO DRIVER PAY FOUND</Typography>
                </div>
              )
            }}
          </Query>

        </Container>)
      }  
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  input: {
    margin: 0,
  },
  date: {
    width: '100%',
    margin: 0,
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    "&:hover": {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

////////// EXPORT //////////
export default withRouter(DriverPay2);