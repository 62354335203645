import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router';
import moment from "moment";
import { makeStyles, Grid, Divider, Typography } from '@material-ui/core';

const log = false

function PayPeriodDetailTableHeader({
  hideLogoLine = false,
  appayments = [],
  payPeriod = {}
}) {
  const cls = useStyles();
  var [summary, setSummary] = useState(null)

  useEffect(() => {
    log && console.log(`Init load to prep summary...`)
    setSummary(prepSummaryValues(appayments))
  }, [appayments])

  const prepSummaryValues = appayments => {
    // initialize payable line items
    let paySum = 0
    let accessorials = 0
    let tollsSum = 0
    let durationSum = 0
    let distanceSum = 0

    appayments.map(appayment => {
      var { move = {}, amount = 0 } = appayment || {};
      appayment = { move: move ? move : {}, amount: amount ? amount : 0 };
      var { 
        lane = {}
      } = move || {};
      move = { lane: lane ? lane : {} };
      var {
        tolls = 0,
        duration_sec = 0,
        distance_miles = 0
      } = lane || {};
      lane = { tolls: tolls ? tolls : 0, duration_sec: duration_sec ? duration_sec : 0, distance_miles: distance_miles ? distance_miles : 0};
            
      paySum += Number(amount)
      tollsSum += Number(tolls)
      durationSum += Number(duration_sec)
      distanceSum += Number(distance_miles)
    });

    if (log) console.log('Total moves: ', appayments.length)
    if (log) console.log('Total pay: ', paySum)
    if (log) console.log('Total duration: ', durationSum)
    if (log) console.log('Total distance: ', distanceSum)
    let payPerMove = ((appayments.length > 0) ? paySum / appayments.length : 0)
    let payPerHour = ((durationSum > 0) ? paySum / (durationSum / 60 / 60) : 0)
    let payPerMile = ((distanceSum > 0) ? paySum / distanceSum : 0)
    if (log) console.log('Total payPerMove: ', payPerMove)
    if (log) console.log('Total payPerHour: ', payPerHour)
    if (log) console.log('Total payPerMile: ', payPerMile)

    return {
      paySum: paySum,
      tollsSum: tollsSum,
      durationSum: durationSum,
      distanceSum: distanceSum,
      payPerMove: payPerMove,
      payPerHour: payPerHour,
      payPerMile: payPerMile,
      accessorials: accessorials,
    }
  }

  if (!(summary && appayments && appayments.length > 0)) return <></>

  var { 
    accessorials = 0,
    distanceSum = 0,
    durationSum = 0,
    payPerHour = 0,
    payPerMile = 0,
    payPerMove = 0,
    paySum = 0,
    tollSum = 0
  } = summary || {};
  summary = { accessorials: accessorials ? accessorials : 0, distanceSum: distanceSum ? distanceSum : 0, durationSum: durationSum ? durationSum : 0, payPerHour: payPerHour ? payPerHour : 0, payPerMile: payPerMile ? payPerMile : 0, payPerMove: payPerMove ? payPerMove : 0, paySum: paySum ? paySum : 0, tollSum: tollSum ? tollSum : 0 };
  var {
    driver_id = 0,
    driver_name = '',
    move_count = 0,
    pay_period = '',
    pickup_time_end = '',
    pickup_time_start = '',
    region = {},
    status = '',
    total_pay_amount = 0,
  } = payPeriod || {};
  payPeriod = { driver_id: driver_id ? driver_id : 0, driver_name: driver_name ? driver_name : '', move_count: move_count ? move_count : 0, pay_period: pay_period ? pay_period : '', pickup_time_end: pickup_time_end ? pickup_time_end : '', pickup_time_start: pickup_time_start ? pickup_time_start : '', region: region ? region : {}, status: status ? status : '', total_pay_amount: total_pay_amount ? total_pay_amount : 0 }
  var {
    name: regionName = ''
  } = region || {};
  region = { regionName: regionName ? regionName : '' };

  return (
    <div className={cls.root}>
      <Grid container direction="row" justifyContent="space-between" spacing={4}>
        <Grid item xs={6} className={hideLogoLine ? cls.logoHeaderPrintOnly : null}>
          <img
            src="https://socialautotransport.com/logo-264px.png"
            alt="logo"
          />
        </Grid>
        <Grid item xs={6} className={hideLogoLine ? cls.logoHeaderPrintOnly : null}>
          <Typography className={cls.earningsHeader}>EARNINGS</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={cls.reducedFont} style={{ fontWeight: "bold" }} variant="h6">FROM</Typography>
          <Typography className={cls.reducedFont}>1717 East Cary St, Suite 2120</Typography>
          <Typography className={cls.reducedFont}>Richmond, VA 23223</Typography>
          <Typography className={cls.reducedFont}>1-800-913-7674</Typography>
          <Typography className={cls.reducedFont}><a href="https://www.hopdrive.com">hopdrive.com</a></Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography display="inline" className={cls.detailItem}>Total Moves</Typography>
          <Typography display="inline" className={cls.detailValue}> {appayments.filter(o => o.type === 'move pay' && o.move.move_type === 'drive').length} </Typography>
          <br />
          <Typography display="inline" className={cls.detailItem}>Total Rides</Typography>
          <Typography display="inline" className={cls.detailValue}> {appayments.filter(o => o.type === 'move pay' && o.move.move_type === 'ride').length} </Typography>
          <br />
          <Typography display="inline" className={cls.detailItem}>Total Adjustments</Typography>
          <Typography display="inline" className={cls.detailValue}> {appayments.filter(o => o.type === 'accessorial').length} </Typography>
          <br />
          <Typography display="inline" className={cls.detailItem}>Total Miles</Typography>
          <Typography display="inline" className={cls.detailValue}> {distanceSum.toFixed(2)} </Typography>
          <br />
          <Typography display="inline" className={cls.detailItem}>Total Time</Typography>
          <Typography display="inline" className={cls.detailValue}> {moment.utc(durationSum * 1000).format('H:mm:ss')} <em>(about {moment.duration(durationSum, "seconds").humanize()})</em></Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography display="inline" className={cls.detailItem}>Driver Name</Typography>
          <Typography display="inline" className={cls.detailValue}> {driver_name} </Typography>
          <br />
          <Typography display="inline" className={cls.detailItem}>Earnings Summary #</Typography>
          <Typography display="inline" className={cls.detailValue}> {driver_id}-{pay_period} </Typography>
          <br />
          <Typography display="inline" className={cls.detailItem}>Earnings Date Range</Typography>
          <Typography display="inline" className={cls.detailValue}> {moment(pickup_time_start).format("MM/DD/YYYY")}{" "}-{" "}{moment(pickup_time_end).format("MM/DD/YYYY")} </Typography>
          <br />
          <Typography display="inline" className={cls.detailItem}>Average Hourly Rate</Typography>
          <Typography display="inline" className={cls.detailValue}> ${payPerHour.toFixed(2)} per hour</Typography>
          <br />
          <Typography display="inline" className={cls.detailItem}>Average Per Mile Rate</Typography>
          <Typography display="inline" className={cls.detailValue}> ${payPerMile.toFixed(2)} per mile</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider className={cls.horLine} />
          <Typography display="inline" className={cls.earningsTotal}>EARNINGS TOTAL</Typography>
          <Typography display="inline" className={cls.earningsSum}> ${paySum.toFixed(2)} </Typography>
          <Divider className={cls.horLine} />
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  logoHeaderPrintOnly: {
    display: 'none',
    '@media print': {
      display: 'block'
    },
  },
  grid: {
    width: '100%',
    padding: theme.spacing(2),
  },
  earningsHeader: {
    textAlign: 'left',
    fontWeight: "bold",
    fontSize: "180%"
  },
  reducedFont: {
    fontSize: "100%",
    '@media print': {
      fontSize: "90%"
    },
  },
  detailItem: {
    fontSize: "100%",
    '@media print': {
      fontSize: "90%"
    },
    fontWeight: "bold",
    textAlign: 'left',
  },
  detailValue: {
    fontSize: "100%",
    '@media print': {
      fontSize: "90%"
    },
    textAlign: 'left',
    float: 'right',
  },
  horLine: {
    margin: theme.spacing(0.3),
    backgroundColor: "black"
  },
  earningsTotal: {
    marginLeft: theme.spacing(1),
    fontSize: "225%",
    textAlign: 'left',
    fontWeight: "bold",
  },
  earningsSum: {
    marginRight: theme.spacing(1),
    fontSize: "225%",
    textAlign: 'left',
    float: 'right',
    fontWeight: "bold",
  }
}))

export default withRouter(PayPeriodDetailTableHeader)