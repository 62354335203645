import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { DateTimePicker } from '@material-ui/pickers';
import { makeStyles, TextField, MenuItem } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/ModalComponents';

const driveStatuses = [
  {
    name: `Dispatched`,
    value: `dispatched`,
  },
  {
    name: `Pickup Started`,
    value: `pickup started`,
  },
  {
    name: `Pickup Arrived`,
    value: `pickup arrived`,
  },
  {
    name: `Pickup Successful`,
    value: `pickup successful`,
  },
  {
    name: `Delivery Started`,
    value: `delivery started`,
  },
  {
    name: `Delivery Arrived`,
    value: `delivery arrived`,
  },
  {
    name: `Delivery Successful`,
    value: `delivery successful`,
  }
];

const rideStatuses = [
  {
    name: `Picked Up`,
    value: `pickedUp`,
  },
  {
    name: `Dropped Off`,
    value: `droppedOff`,
  },
  {
    name: `Canceled`,
    value: `canceled`,
  },
  {
    name: `Failed`,
    value: `failed`,
  }
];

const cancelStatuses = [
  {
    name: `Pending`,
    value: `pending`,
  },
  {
    name: `Seen`,
    value: `seen`,
  },
  {
    name: `Started`,
    value: `started`,
  },
  {
    name: `Delivered`,
    value: `delivered`,
  },
  {
    name: `Canceled`,
    value: `canceled`,
  },
];

////////// COMPONENT //////////
export default function StatusEditModal(props) {
  const cls = useStyles();

  const { open, onClose, statusInput, moveType } = props;

  const [statuses, setStatuses ] = useState([]);
  const [status, setStatus] = useState(``);
  const [statusTime, setStatusTime] = useState(moment().format())
  const [cancelStatus, setCancelStatus] = useState(``);

  useEffect(() => {
    if (moveType === 'ride') setStatuses(rideStatuses)
    else setStatuses(driveStatuses)
  }, [])

  useEffect(() => {
    if (statusInput) {
      setStatus(statusInput.status || ``);
      setCancelStatus(statusInput.cancelStatus || ``);
    }
  }, [statusInput]);

  const handleInputChange = setHandler => async event => {
    let warning = setHandler === setStatus ? await cadenceWarning(event.target.value) : null;
    if (warning) {
      if (window.confirm(`WARNING: you are attempting to set the move status from ${statusInput.status ? statusInput.status : 'unplanned'} to ${event.target.value} which is out of workflow order. Confirm this is your intention.`)) {
        if (setHandler) setHandler(event.target.value);
      } else return
    } else if (setHandler) setHandler(event.target.value);
  };

  const cadenceWarning = event => {
    let warning = false;
    switch (statusInput.status) {
      case '': // check drive type status cadence
        if (event !== 'dispatched') warning = true;
        break;
      case 'dispatched': 
        if (event !== 'pickup started') warning = true;
        break;
      case 'pickup started':
        if (event !== 'pickup arrived') warning = true;
        break;
      case 'pickup arrived':
        if (event !== 'pickup successful') warning = true;
        break;  
      case 'pickup successful':
        if (event !== 'delivery started') warning = true;
        break;  
      case 'delivery started':
        if (event !== 'delivery arrived') warning = true;
        break;  
      case 'delivery arrived':
          if (event !== 'delivery successful') warning = true;
          break;  
      case 'droppedOff': // check ride type status cadence
        if (event === 'pickedUp') warning = true;
        break;         
      default:
        break;  
    }
    
    return warning;
  };

  const statusEventTimeChange = time => {
    setStatusTime(moment(time).format())
  };

  const handleSubmit = async () => {
    handleClose({
      status: status,
      cancelStatus: cancelStatus,
      statusTime: statusTime
    });
  };
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Edit Status</ModalHeader>

        <ModalContent
          subtitle={`Edit the current move's statuses stored in our database. Your changes will be made upon clicking 'Submit', then 'Save Changes'.`}
        >
          {/* STATUS UPDATE ------------------------------------------------ */}
          <TextField
            select
            fullWidth
            label='Status'
            placeholder='Select a status...'
            variant='outlined'
            margin='none'
            value={status}
            onChange={handleInputChange(setStatus)}
          >
            <MenuItem value=''>
              <em>Unplanned</em>
            </MenuItem>
            {statuses.map((s, i) => (
              <MenuItem key={`modal-status-${i}`} value={s.value}>
                {s.name}
              </MenuItem>
            ))}
          </TextField>
          <div className={cls.break} />

          {/* CANCEL STATUS UPDATE ------------------------------------------------ */}
          <TextField
            select
            fullWidth
            label='Cancel Status'
            placeholder='Select a cancel status...'
            variant='outlined'
            margin='none'
            value={cancelStatus}
            onChange={handleInputChange(setCancelStatus)}
          >
            <MenuItem value=''>
              <em>Not Canceled</em>
            </MenuItem>
            {cancelStatuses.map((cs, i) => (
              <MenuItem key={`modal-cancel-status-${i}`} value={cs.value}>
                {cs.name}
              </MenuItem>
            ))}
          </TextField>
          <div className={cls.break} />

          {/* TIMESTAMP UPDATE ------------------------------------------------ */}
         
          <DateTimePicker 
            fullWidth
            orientation="portrait"
            label="Status Event Time"
            inputVariant="outlined"
            value={statusTime}
            onChange={statusEventTimeChange}
          />
          <div className={cls.break} />
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()}>Submit</ModalAction>
          <ModalAction onClick={() => handleClose()} color='default'>
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
