import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles, Grid, Typography, Tooltip, Chip, Icon } from '@material-ui/core';
import sdk from "@hopdrive/sdk";

const log = false;

////////// TOOLS //////////
const capFirst = str => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
};

const checkNeg = num => {
  if (num > 0) return num;
  else return 0;
};

////////// COMPONENT //////////
export default function MoveDetailsAccessorials({ move }) {
  const cls = useStyles();
  const [fuelMultiplier, setFuelMultiplier] = useState(0)

  useEffect(() => {
    log && console.log('Spend authorizations rendering for move: ', move);
    const fetchConfig = async () => {
      const customerConfig = await sdk.customers.getConfig(move.customer.id);
      setFuelMultiplier(customerConfig.fuel_multiplier);
    }
    fetchConfig()
  }, [move]);

  return (
    <>
      {move.accessorials.map(accessorial => {
        let authorization = (accessorial && accessorial.authorization) || {};
        return (
          <div key={`accessorial-${accessorial.id}`}>
            <div className={cls.paper}>
              <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                <Grid item xs>
                  <Tooltip placement='top-start' title='Accessorial ID'>
                    <Typography className={cls.headTxtL}>#{accessorial.id}&nbsp;-&nbsp;{capFirst(accessorial.code)}</Typography>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip placement='top-end' title='Accessorial Status'>
                    <Chip
                      variant={accessorial.status === 'approved' ? 'default' : 'outlined'}
                      icon={
                        <Icon fontSize='small'>
                          {accessorial.status === 'approved' ? 'done' : 'monetization_on'}
                        </Icon>
                      }
                      size='small'
                      color={accessorial.status === 'pending' ? 'primary' : 'secondary'}
                      label={capFirst(accessorial.status)}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <div className={cls.break} />
              <Grid container spacing={2}>
                <Grid item sm={4} xs={12}>
                  <Typography className={cls.subheadTxt}>Accessorial Info</Typography>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Cost:&nbsp;&nbsp;</Typography>
                    <Typography className={cls.valTxt}>{'$' + (accessorial.cost || 0).toFixed(2)}</Typography>
                  </div>
                  {authorization.id ? (
                    <>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Max&nbsp;Charge:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{'$' + (authorization.max_charge || 0).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Authorization&nbsp;ID:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{authorization.id}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Swipe&nbsp;Attempts:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{authorization.auth_attempts}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Valid&nbsp;Window:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{authorization.valid_from
                          ? `${moment(authorization.valid_from).format('LT')} – ${moment(authorization.valid_to).format('LT')}`
                          : ''}</Typography>
                      </div>
                    </>
                  ) : null}
                </Grid>
                <div className={cls.hiddenLineBreak} />
                <Grid item sm={4} xs={12} >
                  {authorization.id ? (
                    <>
                      <Typography className={cls.subheadTxt}>Automated Pay Calculations</Typography>
                      <div className={cls.block}>
                        <Typography className={cls.detailNameTxt}>Customer&nbsp;Price</Typography>
                        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                          <Grid item xs>
                            <Typography className={cls.detailNotesTxt}>Accessorial&nbsp;Cost:</Typography>
                            <Typography className={cls.detailNotesTxt}>Customer&nbsp;Fuel&nbsp;Multiplier:</Typography>
                            <Typography className={cls.detailNotesTxt}>Total:</Typography>

                          </Grid>
                          <Grid item>
                            <Typography className={cls.detailAmountTxt}>${checkNeg(accessorial.cost || 0).toFixed(2)}</Typography>
                            <Typography className={cls.detailAmountTxt}>x{fuelMultiplier}</Typography>
                            <Typography className={cls.detailAmountTxt}>${checkNeg(accessorial.ar_amount || 0).toFixed(2)}</Typography>

                          </Grid>
                        </Grid>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.block}>
                        <Typography className={cls.detailNameTxt}>Driver&nbsp;Pay</Typography>
                        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                          <Grid item xs>
                            <Typography className={cls.detailNotesTxt}>Driver&nbsp;Pay&nbsp;Per&nbsp;Minute:</Typography>
                            <Typography className={cls.detailNotesTxt}>Minutes&nbsp;at&nbsp;Fuel&nbsp;Pump:</Typography>
                            <Typography className={cls.detailNotesTxt}>Total:</Typography>

                          </Grid>
                          <Grid item>
                            <Typography className={cls.detailAmountTxt}>${checkNeg(move.lane.driver_pay_per_minute || 0).toFixed(2)}</Typography>
                            <Typography className={cls.detailAmountTxt}>x5</Typography>
                            <Typography className={cls.detailAmountTxt}>${checkNeg(accessorial.ap_amount || 0).toFixed(2)}</Typography>

                          </Grid>
                        </Grid>
                      </div>
                    </>
                  ) : (
                    <>
                      <Typography className={cls.subheadTxt}>Pay Info</Typography>
                      <div className={cls.block}>
                        <Typography className={cls.detailNameTxt}>Customer&nbsp;Price</Typography>
                        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                          <Grid item xs>
                            <Typography className={cls.detailNotesTxt}>Total:</Typography>

                          </Grid>
                          <Grid item>
                            <Typography className={cls.detailAmountTxt}>${checkNeg(accessorial.ar_amount || 0).toFixed(2)}</Typography>

                          </Grid>
                        </Grid>
                      </div>
                      <div className={cls.lineBreak} />

                      <div className={cls.block}>
                        <Typography className={cls.detailNameTxt}>Driver&nbsp;Pay</Typography>
                        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                          <Grid item xs>
                            <Typography className={cls.detailNotesTxt}>Total:</Typography>

                          </Grid>
                          <Grid item>
                            <Typography className={cls.detailAmountTxt}>${checkNeg(accessorial.ap_amount || 0).toFixed(2)}</Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  )}

                </Grid>


                <div className={cls.hiddenLineBreak} />

                <Grid item sm={4} xs={12}>
                  <Typography className={cls.subheadTxt}>Notes</Typography>
                  <div className={cls.block}>
                    <Typography className={cls.keyTxt}>Accessorial&nbsp;Notes:&nbsp;&nbsp;</Typography>
                    <Typography className={cls.valTxt}>{accessorial.notes}</Typography>
                  </div>
                  {authorization.id ? (
                    <>
                      <div className={cls.lineBreak} />
                      <div className={cls.block}>
                        <Typography className={cls.keyTxt}>Last&nbsp;Decline&nbsp;Reason:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{authorization.last_declined_reason ? (
                          `${moment(authorization.last_declined_at).format('llll')}: ${authorization.last_declined_reason}`) : ''}
                        </Typography>
                      </div>
                    </>
                  ) : null}
                </Grid>
                <div className={cls.hiddenBreak} />
              </Grid>
            </div>
            <div className={cls.break} />
          </div>
        );
      })}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    cursor: 'default',
  },
  subheadTxt: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  key: {
    lineHeight: 1,
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  val: {
    lineHeight: 1,
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },

  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: '#eee',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },

  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  block: {
    display: 'block',
  },

  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  midBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  detailNameTxt: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  detailNotesTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  detailAmountTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },

}));
